import { Input, Modal, notification, Spin } from 'antd'
import { InputOTP } from 'antd-input-otp'
import React, { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { UAParser } from 'ua-parser-js'
import Settings from '../../shared/config/Settings'
import getUserId from '../../shared/core/GetToken'
import { v4 as uuidv4 } from 'uuid';
import md5 from 'md5'

const TrustedDeviceLoginUI = ({ isShowTrustedDeviceOption, isTrustedProcessDone, setIsTrustedProcessDone, setIsShowTrustedDeviceOption }) => {
    const [verifyingTrustedDevice, setVerifyingTrustedDevice] = useState(false)
    const [isTrustedOtpSent, setIsTrustedOtpSent] = useState(false)
    const [inputAlphaCode, setInputAlphaCode] = useState("")
    const [isResendEnabled, setIsResendEnabled] = useState(false)
    const [inputAlphaCodeErrMsg, setInputAlphaCodeErrMsg] = useState("")
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: 'Notification',
            description:
                message,
        });
    };

    const getTrustedDeviceCode = () => {
        setIsResendEnabled(false)
        setVerifyingTrustedDevice(true)
        const parser = new UAParser();
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientID: item.userid,
                DeviceID: localStorage.getItem("DeviceID"),
                // DeviceName: parser.getResult()?.browser?.name,
                // DeviceType: isMobileOnly ? "Mobile" : 'Web',
                IsFromApp: false,
                IsGetRequest: true

            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "MFA/GetTrustedDevice", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result?.Trustedresponse === 1) {
                        openNotificationWithIcon("success", result?.response)
                        setIsTrustedOtpSent(true)
                        setInputAlphaCode("")
                    }
                    setVerifyingTrustedDevice(false)
                    setTimeout(() => { setIsResendEnabled(true) }, 4000)
                })
                .catch(err => {
                    setVerifyingTrustedDevice(false)

                })
        })
    }
    const addTrustedDevice = () => {
        if (inputAlphaCode.length !== 10) {
            openNotificationWithIcon("error", "Code should be of 10 digits.")
            return
        }
        setVerifyingTrustedDevice(true)
        const parser = new UAParser();
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientID: item.userid,
                DeviceID: localStorage.getItem("DeviceID"),
                IsFromApp: false,
                IsGetRequest: false,
                DeviceVerificationCode: inputAlphaCode

            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "MFA/VerifyTrustedDevice", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log("result from verify trusted device", result)
                    setVerifyingTrustedDevice(false)
                    if (result?.Trustedresponse === 1) {
                        openNotificationWithIcon("success", "Trusted device added successfully.")
                        setIsShowTrustedDeviceOption(false)
                        setIsTrustedProcessDone(true)
                    } else if (result?.Trustedresponse == -1) {
                        openNotificationWithIcon("error", "Verification code is invalid")
                        // setInputAlphaCodeErrMsg("Invalid Code")
                    } else if (result?.Trustedresponse == -2) {
                        openNotificationWithIcon("error", "Verification code has been expired")
                        // setInputAlphaCodeErrMsg("Code Expired")
                        setInputAlphaCode("")
                    }
                })
                .catch(err => {
                    setVerifyingTrustedDevice(false)

                })
        })
    }
    return (
        <Modal
            open={isShowTrustedDeviceOption}
            title="Trusted Device Setup"
            maskClosable={false}
            closable={false}
            onOk={() => {
                if (isTrustedOtpSent === false && isTrustedProcessDone === false) {

                    getTrustedDeviceCode()
                } else {
                    addTrustedDevice()
                }
            }}
            confirmLoading={verifyingTrustedDevice}
            okText={"Yes"}
            cancelText="No"
            okButtonProps={isTrustedOtpSent ? { style: { display: 'none' } } : {}}
            cancelButtonProps={isTrustedOtpSent ? { style: { display: 'none' } } : {}}
            onCancel={() => {
                setIsShowTrustedDeviceOption(false)
                setIsTrustedProcessDone(true)
            }}
        >
            <div className='trusted-device-box'>

                {isTrustedOtpSent === false && isTrustedProcessDone === false ? <p className='trusted-device-box-text'>Do you want to add this device as trusted for future login?</p> : null}
                {
                    isTrustedOtpSent ?
                        <>
                            <p className='trusted-device-box-text'>Please enter 10 digit alphanumeric code recieved on email/sms.</p>
                            <div>
                                <Input className='trusted-device-box-input' value={inputAlphaCode} onChange={(e) => {
                                    if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                                        setInputAlphaCode(e.target.value)
                                    }
                                    else {
                                        setInputAlphaCodeErrMsg("Only alphanumberic code is allowed")
                                    }

                                }
                                }
                                    maxLength={10} />
                                <p className='text-danger'>{inputAlphaCodeErrMsg}</p>

                            </div>
                            <p className={'mfa-box-resend-code mt-1'}>Didn't Recieve code? <span className={'mfa-box-resend-code-link' + (isResendEnabled ? " cursor-pointer " : " cursor-not-allowed disabled ")} onClick={getTrustedDeviceCode}>Resend Code</span></p>

                            <button className='verify-trusted-btn mt-3' onClick={addTrustedDevice}>Verify Code</button>
                            <button className='do-not-verify-trusted-btn mt-2' onClick={() => {
                                setIsShowTrustedDeviceOption(false)
                                setIsTrustedProcessDone(true)
                            }}>Continue without verification</button>
                        </>
                        : null
                }
            </div>
        </Modal>
    )
}

export default TrustedDeviceLoginUI
