import { Input, Modal, notification, Spin } from 'antd'
import { InputOTP } from 'antd-input-otp'
import React, { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { UAParser } from 'ua-parser-js'
import Settings from '../../shared/config/Settings'
import getUserId from '../../shared/core/GetToken'
import { v4 as uuidv4 } from 'uuid';


const MfaLoginUI = ({ timer, setTimer, setIsMFAVerified, setIsMFAActive, setIsShowTrustedDeviceOption, setIsTrustedProcessDone }) => {
    const [otpPass, setOtpPass] = useState("")
    const [authenticatorQrSecret, setAuthenticatorQrSecret] = useState("")
    const [authenticatorQrData, setAuthenticatorQrData] = useState("")
    const [authPinCode, setAuthPinCode] = useState("")
    const [authenticatorActive, setAuthenticatorActive] = useState(false)
    const [validatingOTP, setValidatingOTP] = useState(false)
    const [resendOtpLoading, setResendOtpLoading] = useState(false)
    const [isMobileStepsModalOpen, setIsMobileStepsModalOpen] = useState(false)
    const [isResendClickedOnce, setIsResendClickedOnce] = useState(false)
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: 'Notification',
            description:
                message,
            // placement: "top"
        });
    };
    const verifyOTP = () => {
        let otp = ""
        otpPass.length > 0 && otpPass?.map(o => otp = otp + o)
        if (otp.length < 6 || !parseInt(otp)) {
            openNotificationWithIcon("error", "Please enter a valid OTP")
            return
        }

        setValidatingOTP(true)
        const parser = new UAParser();
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const deviceId = localStorage.getItem("DeviceID");
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientID: item.userid,
                Otpcode: (otp),
                Version: parser.getResult()?.browser?.version,
                Platform: "Web",
                DeviceVersion: parser.getResult()?.browser?.name,
                "IsFromWeb": true,
                DeviceID: deviceId,
                DeviceModel: "",

                DeviceID: deviceId,
                DeviceName: parser.getResult()?.browser?.name,
                DeviceType: isMobileOnly ? "Mobile" : 'Web',
                IsFromApp: false,

            })
            debugger
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "MFA/ValidateOtp?", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setValidatingOTP(false)
                    if (result?.IsOtpValidated === 1) {
                        setIsMFAVerified(true)
                        if (result?.IsTrustedDevice === 1) {
                            setIsTrustedProcessDone(true)
                            setIsShowTrustedDeviceOption(false)
                        } else {
                            setIsTrustedProcessDone(false)
                            setIsShowTrustedDeviceOption(true)

                        }
                    }
                    else if (result?.IsOtpValidated === -2) {
                        setIsMFAVerified(false)
                        openNotificationWithIcon("error", "OTP has expired")
                        setOtpPass("")

                    }
                    else if (result?.IsOtpValidated === -1) {
                        setIsMFAVerified(false)
                        openNotificationWithIcon("error", "Provided OTP is invalid")
                    }
                    else {

                        openNotificationWithIcon("error", "Some error occured, Please try again")
                        setIsMFAVerified(false)
                    }
                })
                .catch(err => {
                    setValidatingOTP(false)

                })
        })
    }
    const authenticatePin = () => {
        let otp = ""
        authPinCode.length > 0 && authPinCode?.map(o => otp = otp + o)
        if (otp.length < 6 || !parseInt(otp)) {
            openNotificationWithIcon("error", "Please enter a valid pin code")
            return
        }
        setValidatingOTP(true)
        const parser = new UAParser();
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const deviceId = localStorage.getItem("DeviceID");
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientUUID: item.userid,
                Code: otp,
                UserName: item.parentname,
                DeviceVersion: parser.getResult()?.browser?.name,
                "IsFromWeb": true,
                DeviceID: deviceId,
                DeviceName: parser.getResult()?.browser?.name,
                DeviceModel: ""

            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "MFA/VerifyGoogleAuthOtp?", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    setValidatingOTP(false)
                    if (result.IsOtpValidated === 1) {
                        setIsMFAVerified(true)
                        if (result?.IsTrustedDevice === 1) {
                            setIsTrustedProcessDone(true)
                            setIsShowTrustedDeviceOption(false)
                        } else {
                            setIsTrustedProcessDone(false)
                            setIsShowTrustedDeviceOption(true)

                        }

                    } else {
                        openNotificationWithIcon("error", "Invalid pin code, please make sure the pin code is correct")
                        setIsMFAVerified(false)
                    }
                })
                .catch(err => {
                    setValidatingOTP(false)

                })
        })
    }
    const resendOTPCall = () => {
        if (timer > 0 || resendOtpLoading) {
            return
        }
        setResendOtpLoading(true)
        setOtpPass("")
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientID: item.userid,
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "MFA/ResendOtp", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result === "1") {
                        setIsResendClickedOnce(true)
                        setResendOtpLoading(false)
                        openNotificationWithIcon("success", "A new OTP has been sent to you. Please verify to login.")
                        setTimer(180)
                    }

                })
                .catch(err => {
                    setValidatingOTP(false)

                })
        })
    }
    const generateOtpQRSecret = () => {
        setIsResendClickedOnce(true)
        setAuthenticatorActive(true)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientUUID: item.userid,
                UserName: item.username
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "MFA/GenerateQRAuthenticator", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result?.QrCodeUrl) {
                        setAuthenticatorQrData(result.QrCodeUrl)
                        setAuthenticatorQrSecret(result.secret)
                    }
                    // if (result === "1") {
                    // openNotificationWithIcon("success", "A new OTP has been sent to you. Please verify to login.")
                    // setTimer(30)
                    // }

                })
                .catch(err => {
                    setValidatingOTP(false)

                })
        })
    }
    return (
        <div className='mfa-box'>
            <img src="assets/images/back-arrow.svg" onClick={() => {
                setIsMFAActive(false)
                setIsMFAVerified(false)
                localStorage.clear()
            }} className="mfa-login-back-button" alt="Back" />

            <div className="mfa-box-title mb-2"><span>{authenticatorActive ? "Google Authenticator" : "Multi-Factor Authentication"}</span></div>
            <div className='mfa-box-text mb-4'>
                {authenticatorActive ?
                    <span>Scan the QR code on your authenticator app and enter the pin code below to proceed</span>
                    :
                    <span>A message with a verification code has been sent to your Email</span>}
            </div>
            {!authenticatorActive && <div className='mfa-box-time-count mb-4'><span className='time-span'>{timer > 59 ? `0${parseInt(timer / 60)}` : "00"} : {(timer % 60) < 10 ? `0${timer % 60}` : timer % 60}</span></div>}
            {!authenticatorActive && <div className='mfa-box-input mb-4'> <InputOTP inputClassName="otp-input" value={otpPass} onChange={(e) => setOtpPass(e)} /></div>}
            {authenticatorActive ?
                <div className='authenticator-qr-area'>
                    {authenticatorQrData ? <img className='w-100' src={authenticatorQrData} /> : <Spin />}
                    {authenticatorQrData && isMobileOnly ? <span className="mfa-auth-secret-key-box"><span className='heading'>Secret Key : <span className='steps-icon-mfa' onClick={() => setIsMobileStepsModalOpen(true)}>?</span></span> <br />{authenticatorQrSecret}</span> : null}

                    <div className='mfa-box-input mt-4 mb-4'> <InputOTP inputClassName="otp-input" value={authPinCode} onChange={(e) => setAuthPinCode(e)} />
                    </div>
                </div>
                : null}

            {validatingOTP ? <Spin /> : null}
            {!isResendClickedOnce && <div className={'mfa-box-resend-code-link mb-4' + (timer <= 0 && !resendOtpLoading ? " cursor-pointer " : " cursor-not-allowed disabled ")} onClick={resendOTPCall}>Resend Code</div>}
            {/* {!authenticatorActive && <div className={'mfa-box-resend-code-link mb-4 cursor-pointer'} onClick={generateOtpQRSecret}>Try another way</div>} */}
            <div className='mfa-box-submit'><button disabled={validatingOTP} onClick={() => authenticatorActive ? authenticatePin() : verifyOTP()} className='btn btn-primary btn-lg'>Verify OTP</button></div>
            {!authenticatorActive && <div className='mfa-box-another-way mt-3'><button disabled={validatingOTP} onClick={generateOtpQRSecret} className='btn btn-primary btn-lg'>Another way to Authenticate</button></div>}
            {/* {!authenticatorActive && <div className={'mfa-box-resend-code-link mb-4 cursor-pointer '} onClick={generateOtpQRSecret}>Try another way</div>} */}
            <Modal
                open={isMobileStepsModalOpen}
                title="Setting Up an Authenticator App"
                maskClosable={false}
                onOk={() => setIsMobileStepsModalOpen(false)}
                onCancel={() => setIsMobileStepsModalOpen(false)}
                cancelButtonProps={{ style: { "display": "none" } }}
            >
                <div className='padding-20px'>

                    <ol>
                        <li><b>Copy Secret Key</b> - Obtain and securely save the secret key from the website.</li>
                        <li><b>Open Authenticator App</b> - Launch Google Authenticator or your chosen app.</li>
                        <li><b>Add New Account</b> - Tap '+' or 'Add Account' and Select 'Enter a Setup Key'.</li>
                        <li><b>Enter Details</b> - Input account name and paste the secret key.</li>
                        <li><b>Return to Website</b> - Go back to the website.</li>
                        <li><b>Enter Code</b> - Input the generated code in the provided field.</li>
                        <li><b>Confirm Setup</b> - Verify the code.</li>
                    </ol>
                </div>

            </Modal>
        </div>
    )
}

export default MfaLoginUI
