import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, ActivityIndicator, TouchableOpacity, View, Image, Dimensions } from 'react-native';


import { Spin, Switch, Alert, Tooltip, Popover, notification } from 'antd';
import { Table, Tag, Space } from 'antd';
import { Modal, Button } from 'antd';
import getUserId from "../../shared/core/GetToken";
import { LoadingOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';

import 'antd/dist/antd.css';
import { capitalize } from '../../shared/core/datehelper';
import Settings from '../../shared/config/Settings';
import renderHTML from "react-render-html";

import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import PrintOrganizationHeader from './PrintOrganizationHeader';
import Chart from 'react-google-charts';
import { isMobile } from 'react-device-detect';



const ReportsHandler = props => {


    const [tabledata, setTableData] = useState([]);
    const { Column, ColumnGroup } = Table;
    const antIcon = <LoadingOutlined className='font-24' spin />;
    const [visible, setVisible] = useState(false);  //for report pdf view 
    const [tableModalVisible, setTableModalVisible] = useState(false); //for
    const [htmlModalVisible, setHtmlModalVisible] = useState(false);
    const [pdfViewerLoading, setPdfViewerLoading] = useState(false);
    const [isReady, setIsReady] = useState(true);
    const [sloading, setSLoading] = useState(false);
    const [selectedResult, setSelectedResult] = useState(null)
    const [isPrintActive, setIsPrintActive] = useState(false)
    const [reportDataType, setReportDataType] = useState("")
    const [chartLoading, setChartLoading] = useState(false)
    const [labChartModalOpen, setLabChartModalOpen] = useState(false)

    const getRandomTag = () => {
        var textArray = [
            'card-header text-white bg-danger',
            'card-header text-white bg-success',
            'card-header text-white bg-primary',
            'card-header text-white bg-info',
            'card-header text-white bg-warning',
        ];
        var randomNumber = Math.floor(Math.random() * textArray?.length);
        return textArray[randomNumber]
        // audioElement.setAttribute('src', textArray[randomNumber]);
    }


    const loadReport = () => {
        setVisible(true)
        setSLoading(true);

        getUserId().then((userInfo) => {
            //   setPdfViewerLoading(true)
            //    

            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientID: item.userid,
                ResultID: props.labid
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "Patient/LabResultByID", requestOptions)
                .then(data => data.json())
                .then(json => {
                    // localStorage.setItem("selectedResult", JSON.stringify(json))
                    setSelectedResult(json)
                    setSLoading(false)
                })
                .catch(error => {
                    // setIsLoadingPdf(false)
                    setSLoading(false)
                    setPdfViewerLoading(false)
                })
                .finally(() => {
                    // setCheckFile(false)
                    // setIsLoadingPdf(false)
                    setSLoading(false)
                    setPdfViewerLoading(false)


                });
        });
    }
    useEffect(() => {
        localStorage.removeItem("selectedResult")
    }, [])
    useEffect(() => {
        if (localStorage.getItem("selectedResult")) {
            setSelectedResult(JSON.parse(localStorage.getItem("selectedResult")))
        }
    }, [localStorage.getItem("selectedResult")])
    useEffect(() => {

        if (selectedResult !== null) {
            if (selectedResult?.length > 0) {
                if (selectedResult[0].entry[0].resource.content[0].attachment.contentType == "PDF" || selectedResult[0].entry[0].resource.content[0].attachment.contentType == "pdf") {
                    setReportDataType("pdf")
                }
                else if (selectedResult[0].entry[0].resource.content[0].attachment.contentType == "HTML" || selectedResult[0].entry[0].resource.content[0].attachment.contentType == "html") {
                    setReportDataType("html")


                }
                else {
                    //setTableData([]);
                    let tableArray = [];
                    let ObservationText = '';
                    let ObservationValue = "";
                    let AbnormalFlagDesc = "";
                    let ObservationSummary = "";
                    selectedResult[0].entry.map((item, index) => {
                        item.resource.extension.map((value, key) => {
                            if (item.resource.extension[key].url == "ObservationText") {
                                ObservationText = item.resource.extension[key].valueString ? capitalize(item.resource.extension[key].valueString) : "N/A"
                            } else if (item.resource.extension[key].url == "ObservationValue") {
                                ObservationValue = item.resource.extension[key].valueString ? capitalize(item.resource.extension[key].valueString) : "N/A"
                            }
                            else if (item.resource.extension[key].url == "AbnormalFlagDesc") {
                                AbnormalFlagDesc = item.resource.extension[key].valueString ? capitalize(item.resource.extension[key].valueString) : "N/A"
                            }
                            ObservationSummary = item.resource.extension.find(o => o.url === 'ObservationSummary')?.valueString;

                        })
                        let ob = {
                            "ObservationText": ObservationText,
                            "ObservationValue": ObservationValue,
                            "AbnormalFlagDesc": AbnormalFlagDesc,
                            "ObservationSummary": ObservationSummary
                        }
                        tableArray.push(ob);
                    })
                    setTableData([])
                    setTableData(tableArray)
                    setReportDataType("table")
                    // setIsReady(!isReady)
                    setTimeout(() => {

                    }, 3000);
                }
            } else {
                setReportDataType("empty")
            }
        }
    }, [selectedResult])

    const pageStyleCss = `@page {
        size: A4;
      }
      *{
          font-family: sans-serif;
        }
        .print-header{
            color: lightgrey;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
      }
        .print-header-left{
            display: flex;
        }
        table.repot-details-table{
            border-collapse:collapse;
            cellspacing:0
        }
        table.repot-details-table tr.repot-details-table-row{
            font-size:13px;
            font-weight:500;
        }
        table.repot-details-table tr.repot-details-table-row th{
            border:1px solid #f2f4f9;
            padding:10px;
            background-color: #f2f4f9 !important;
            -webkit-print-color-adjust:exact !important;
            print-color-adjust:exact !important; 
        }

        table.repot-details-table tr.repot-details-table-row td{
            border:1px solid #f2f4f9;
            padding:10px;
        }

        .table-bottom-comment{
            border:1px solid #f2f4f9;
            border-top:0;
            padding:10px;
            background-color: #f2f4f9 !important;
            -webkit-print-color-adjust:exact !important;
            print-color-adjust:exact !important; 
        font-size:13px;

        }

        h3,h4,h5,p{
            margin:0px;
        }
        p span{
            font-weight: 600;
        }
        .repot-details-table{
            width:100%;
        }
        .repot-details-table-heading{
            text-align: left;
        }
        .HTML *{
            font-weight: 300;
        }

       
        .lab-test-print-header-container{
            display: flex;
            flex-direction: row;
            flex: 1 0 0;
        }
        .lab-test-column{
            display: flex;
            flex-direction: column;
            flex: 1 0 0;
        }

        .padding-left-right-ten{
            padding-left: 10px;
            padding-right: 10px;
        }
      `;
   
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: false,
        pageStyle: pageStyleCss
    })

    useEffect(() => {
        if (isPrintActive) {
            handlePrint()
            setIsPrintActive(false)
        }
    }, [isPrintActive])
    const formatObservationValue = (value) => {
        return value.replace("Detected   detected", "Detected ")
    }

    const options = {
        vAxis: {
            minValue: 0,
            maxValue: 20,
            format: 0,
            gridlines: { count: 10 }, minorGridlines: { count: 0 }
        },
        chartArea: { width: "70%", height: "70%" },
        pointSize: 5,
        legend: { 'position': 'top', 'alignment': 'center', 'maxLines': 3 },
        hAxis: { format: "MMM d", slantedText: false, maxAlternation: 1, direction: -1 }

    };
    const data = [
        ["Test", "29", "30", '1'],
        ["ALT", 52, 50, 10],
        ["Globulin", 29, 0, 10],
        ["Albumin", 36, 0, 10],
        ["Bilirubin total", 37, 30, 10],
        ["CGT", 6, 0, 10],
        ["Total Protien", 64, 60, 10],
        ["Alk Phosphatase", 73, 0, 10],
        ["Alk Phosphatase", 78, 80, 10],
    ];
    let rawData = []
    const [readings, setReadings] = useState([])
    const [api, contextHolder] = notification.useNotification();

    const openChartErrorNotification = (placement) => {
        api.info({
            message: `No chart available`,
            placement,
        });
    };
    const transpose = (data) => {
        const headers = Object.keys(data[0]);
        return headers.map((header) => [header, ...data.map((row) => row[header])]);
    }

    const getLabChartByCode = (labcode) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientID: item.userid,
                labcode: props.labid
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "Patient/LabResultChartById?", requestOptions)
                .then(data => data.json())
                .then(json => {
                    rawData = JSON.parse(json[0].observationtext);

                    if (json && json?.length > 0) {
                        //calculateReadings()

                        const result = transpose(rawData);


                        setReadings(result)
                        setChartLoading(false)
                        setLabChartModalOpen(true)
                    } else {
                        setChartLoading(false)
                        openChartErrorNotification("topRight")
                    }
                })
                .catch(err => {
                    setChartLoading(false)
                    openChartErrorNotification("topRight")
                })


        })
    }
    const calculateReadings = () => {
        let datesArray = []
        let testsArray = []
        let readingsVar = []
        let readingsArray = []

    
        rawData?.map((item, index) => {
            if (!testsArray.includes(item.observationtext)) {
                if (item.observationtext == "PCV") {
                    testsArray.push("HCT")
                } else {
                    testsArray.push(item.observationtext)
                }
            }
            if (!datesArray.includes(item.resultdate?.split(" ")[0])) {
                datesArray.push(item.resultdate?.split(" ")[0])
            }
        })
        datesArray.map((date, index) => {
            readingsVar.push([date].concat(rawData.filter(item => {
                if (item.resultdate?.split(" ")[0] == date) {
                    return item
                }
            }).map(item => {
                if (testsArray.indexOf(item.observationtext) != -1) {
                    return parseFloat(item.observationvalue)
                }
            })))
        })

        readingsVar.unshift(["Date"].concat(testsArray))
        setReadings(readingsVar)
      
        setChartLoading(false)

    }
    const chartPopupContent = (
        <div className='w-400px'>
            {chartLoading ? <Spin /> :
                readings && readings?.length > 0 ?
                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="100%"
                        data={readings}
                        options={options}
                        legendToggle={true}
                    /> : <span>No Data Available</span>
            }

        </div>)
    useEffect(() => {
        if (chartLoading) {
            getLabChartByCode(props.testName)
        }
    }, [chartLoading])
    const base64ToBlob = (base64Data = "") => {
        const byteString = atob(base64Data);
        const arrayBuffer = new ArrayBuffer(byteString?.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString?.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([uint8Array], { type: 'application/pdf' });
    }

    // Function to handle the download event
    const handleReportDownload = () => {
        const blob = base64ToBlob(selectedResult[0]?.entry[0]?.resource?.content[0]?.attachment?.data);
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor element to trigger the download
        const fileName = selectedResult[0]?.entry[0]?.resource?.content[0]?.attachment?.title
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName ? fileName : "My Report.pdf"}`; // Replace 'filename.pdf' with your desired filename for the downloaded PDF

        // Append the anchor element to the DOM, click it, and then remove it
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    const pdfBlobUrlGenerate = (base64PDF) => {

        // Decode the Base64 string to binary data
        const binaryData = atob(base64PDF);

        // Create a Uint8Array from the binary data
        const uint8Array = new Uint8Array(binaryData?.length);
        for (let i = 0; i < binaryData?.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
        }

        // Create a Blob object from the Uint8Array
        const blob = new Blob([uint8Array], { type: 'application/pdf' });

        // Generate a URL for the Blob
        const blobUrl = URL.createObjectURL(blob);
        console.log(blobUrl)
        return blobUrl
    }
    return (

        // <Spin spinning={sloading}>
        <>
           

            <div className="col-lg-12 ">

                <div className="card shadow-lg rounded margin-top-fifteen margin-bottom-ten">
                    {/* <Spin indicator={antIcon} tip="Loading..." spinning={sloading}> */}
                    <div className={'card-header text-white ' + (props.isRead ? "bg-warning" : "bg-success")}>
                        <div className='d-flex flex-row justify-content-space-between'>
                            <div className='font-weight-500 primary-black-color' ><b>{props.testName}</b></div>
                            <div className='font-weight-500 primary-black-color'><b>{props.rptDate}</b></div>


                        </div>
                    </div>
                    <div className="card-body mb-0">
                        <h4 className="card-title reports-text mb-2">
                            <img src='assets/images/providername.svg' className='mr-2 w-20px' />
                            <span > {props.author} </span></h4>

                        <p className="card-text reports-text mb-2" >
                            <img src='assets/images/test-folder.svg' className="mr-2 w-20px" />
                            {'Type: ' + props.folderName}</p>
                        <div className='row'>
                            <div className='col-md-6 col-sm-6'>
                                <p className="card-text reports-text text-comments-content" >
                                    <img src='assets/images/testcomments.svg' className="mr-2 w-20px" />
                                    <span> {'Comments: ' + props.comments}</span>

                                </p>
                            </div>

                            <div className='col-md-6 col-sm-6'>
                                <button className='btn btn-primary btn-sm pull-right cursor-pointer' onClick={() => sloading ? null : loadReport()}>View</button>
                            </div>
                        </div>

                    </div>

                </div>



            </div>

            {/* PDF File modal */}
            <Modal
                title={<div className='d-flex justify-content-between mr-4'>
                    <span className='text-light'><b>{props.testName + " (" + props.rptDate + ")"}</b></span>
                    {selectedResult && selectedResult?.length > 0 && <>
                        {contextHolder}

                        <div
                            // background: "#ffffff4d",
                            className={`pdf-modal-iner-div ${chartLoading ? 'pointer-event-none' : 'pointer-event-all'}`}
                            onClick={() => setChartLoading(true)}>
                            <img src='assets/images/graph-icon.svg' className='w-24' />
                            {/* <svg
                                width={20}
                                height={20}
                                fill={chartLoading ? "lightgrey" : 'white'}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512">
                                <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
                            </svg> */}
                        </div>
                    </>}
                </div>
                }

                destroyOnClose={true}
                centered
                closable={true}
                pageno
                open={visible}
                maskClosable={false}
                onCancel={() => setVisible(false)}
                afterClose={() => { localStorage.removeItem('selectedResult'); props.refreshReports(); setSelectedResult(null); setReportDataType("") }}
                className='modal-position'
                width={1500}
                closeIcon={<svg xmlns="http://www.w3.org/2000/svg" className='report-modal-close-icon' fill='white' viewBox="0 0 320 512"><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" /></svg>}

                footer={reportDataType == "pdf" ? [
                    <button className="btn btn-outline" key="back" onClick={() => setVisible(false)}>
                        Close
                    </button>,

                ] : [
                        <button className="btn btn-outline mx-2" key="back" onClick={() => setVisible(false)}>
                            Close
                    </button>,
                        <button className="btn btn-primary" key="print" onClick={() => setIsPrintActive(true)}>
                            Print
                    </button>
                    ]}
            >
                {reportDataType == "" && <div className="d-flex justify-content-center my-3"><Spin /> </div>}
                {reportDataType == "empty" && <div>No Data is Available</div>}
                {reportDataType == "pdf" && <div className="pdf-data">
                    <div className="PDF" id="showPDF">
                        {selectedResult ? isMobile ? <p>Report has been generated. Please click <span className="text-primary fw-bolder" onClick={handleReportDownload}> here </span> to download </p> :
                            <>
                                <iframe id="pdfObject" type="application/pdf" src={pdfBlobUrlGenerate(selectedResult[0].entry[0].resource.content[0].attachment.data)}></iframe>
                                {/* {selectedResult[0].entry[0].resource.id === "f4b1f839-cdc7-4f86-a58f-302978bf9018" || selectedResult[0].entry[0].resource.id === "73300fe8-84c2-437a-a004-245b76fcbf86" ||
                                    selectedResult[0].entry[0].resource.id === "2e9b087e-5775-4e3c-a2c8-18550494875e" ||
                                    selectedResult[0].entry[0].resource.id === "0706bce8-2e10-43aa-9575-d82b228c9561" ||
                                    selectedResult[0].entry[0].resource.id === "ae68c915-abee-48ba-84e0-ea9f4df063f2"
                                    ? <iframe id="pdfObject" type="application/pdf" src={pdfBlobUrlGenerate(selectedResult[0].entry[0].resource.content[0].attachment.data)}></iframe> :
                                    <>
                                        <iframe id="pdfObject" src={"data:application/pdf;base64," + selectedResult[0].entry[0].resource.content[0].attachment.data} type="application/pdf"></iframe>
                                    </>} */}
                            </>
                            : ""}

                    </div>
                    <p id="hee">
                        {pdfViewerLoading && <div className='loading-container-reports' ><ActivityIndicator size="small" color="#00A1DE" /><Text>Loading. Please wait...</Text></div>}
                    </p>

                    <div className='card-body p-2 bg-white-smoke'> <b>Comments:</b> {selectedResult ? selectedResult[0]?.entry[0]?.resource?.extension?.find(o => o.url === "Comments")?.valueString?.replace(/<br\/>/g, '\n') : props.comments}</div>

                </div>}

                {
                    reportDataType == "html" &&
                        selectedResult ?

                        <div className='overflow-auto' ref={componentRef}>
                            < PrintOrganizationHeader patientProfile={props.patientProfile} organizationData={props.organizationData} isPrintActive={isPrintActive} rptDate={props.rptDate} />
                            <div className="HTML" id="showHTML" dangerouslySetInnerHTML={{ __html: selectedResult[0].entry[0].resource.extension[1].valueString }} />
                            <div className='card-body p-2 bg-white-smoke'> <b>Comments:</b> {selectedResult ? selectedResult[0].entry[0]?.resource?.extension?.find(o => o.url === "Comments")?.valueString?.replace(/<br\/>/g, '\n') : props.comments}</div>

                        </div>
                        : null
                }

                {reportDataType == "table" && <div id="printModal" className="print-container" ref={componentRef}>
                    <PrintOrganizationHeader patientProfile={props.patientProfile} organizationData={props.organizationData} isPrintActive={isPrintActive} rptDate={props.rptDate} />
                    {tabledata && tabledata?.length ?
                        <>

                            <div className="table-responsive">
                                <table id="labresulttable" className="table table-bordered  repot-details-table w-100">
                                    <tr className="repot-details-table-row">
                                        <th className="repot-details-table-heading">Observation Text</th>
                                        <th className="repot-details-table-heading min-width-200" >Observation Value</th>
                                        <th className="repot-details-table-heading">Abnormal Flag desc</th>
                                    </tr>
                                    {tabledata.map(item => {
                                        let setclassDanger = (item.AbnormalFlagDesc?.toLowerCase() == "abnormal" || item.AbnormalFlagDesc?.toLowerCase() == "low" || item.AbnormalFlagDesc?.toLowerCase() == "high" ? "text-danger" : "");
                                        return (<>
                                            <tr className={`${setclassDanger} repot-details-table-row`}>
                                                <td className="repot-details-table-value">{item.ObservationText}</td>
                                                <td className="repot-details-table-value" dangerouslySetInnerHTML={{ __html: formatObservationValue(item.ObservationValue) }}></td>
                                                <td className="repot-details-table-value">{item.AbnormalFlagDesc}</td>
                                            </tr>
                                            {item.ObservationSummary &&
                                                <tr>
                                                    <td colSpan={3}>

                                                        <div className={`padding-left-zero ${setclassDanger}`} dangerouslySetInnerHTML={{ __html: item.ObservationSummary }} />
                                                    </td>
                                                </tr>
                                            }

                                        </>)

                                    })}
                                </table>
                            </div>

                        </>
                        : <div className='alert alert-danger'> <span>no data is available</span> </div>
                    }
                    <div className='card-body mt-1 p-2 bg-white-smoke table-bottom-comment'> <b>Comments:</b>
                        {selectedResult && selectedResult[0]?.entry[0]?.resource?.extension?.find(o => o.url === "Comments") ? renderHTML(selectedResult[0]?.entry[0]?.resource?.extension?.find(o => o.url === "Comments")?.valueString) : props.comments}</div>

                </div>}
            </Modal>

            {/* Result Chart Modal */}
            <Modal
                title="Result Chart"
                open={labChartModalOpen}
                onOk={() => setLabChartModalOpen(false)}
                onCancel={() => setLabChartModalOpen(false)}
                afterClose={() => setReadings(null)}
                width={700}
                height={700}
                cancelButtonProps={{ style: { display: 'none' } }}
                zIndex={2000000}
                maskClosable={false}

            >
                {labChartModalOpen ? <div>
                    {chartLoading ? <Spin /> :
                        readings && readings?.length > 0 ?
                            <Chart
                                chartType="LineChart"
                                width="100%"
                                height="300px"
                                data={readings}
                                options={options}
                                legendToggle={true}
                            /> : <div className='text-center' >No Data Available</div>
                    }

                </div> : null}

            </Modal>
        </>

    );
}

export default ReportsHandler
