import { Button } from 'antd'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Settings from '../../shared/config/Settings'

const PaymentCallback = () => {
    const navigate = useNavigate()
    const writeToFileLog = (payload11) => {
        try {

            fetch(Settings.apiUrl + '/Patient/WriteToFile?data=' + decodeURI(payload11 + `${new Date()}`) + '', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    // Do something with the retrieved data
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
        }
        catch (e) {
            console.log(e)
        }
    }
    const navigateBack = () => {
        function parseQuery(queryString) {
            const params = {};
            const queryStringWithoutQuestionMark = queryString.substring(1);
            const queryParams = queryStringWithoutQuestionMark.split("&");
            queryParams.forEach((param) => {
                const [key, value] = param.split("=");
                params[key] = decodeURIComponent(value);
            });
            return params;
        }
        writeToFileLog(`Step 1: Payment Callback page `)


        const queryString = window.location.search;
        let { IsRxRequest } = parseQuery(queryString);

        if (IsRxRequest) {
            navigate("/medications")
        } else {
            navigate("/accounts")

        }


    }
    // useEffect(() => {
    //     setTimeout(() => {
    //         navigateBack()
    //     }, 2000)
    // }, [])
    return (
        <div className='w-100 d-flex flex-column align-items-center payment-returnpage-main-div'>
            <img className='mb-5' src="assets/images/failed.svg" />
            <h2 className=''>Callback</h2>
            <button className="payment-returnpage-btn mt-5" onClick={() => { navigateBack() }}>Continue</button>
        </div>
    )
}

export default PaymentCallback
