import React, { useEffect, useRef, useState } from "react";
import UserInvoice from "../../components/UserInvoice";
import StatusMessages, { useMessages } from "../../components/StatusMessages";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchBalanceFromApi } from "../../../shared/actions/GetBalanceAction";
import { Input, Radio, Select, Spin, notification, Tooltip, Modal, Button } from 'antd'

import {
  View,
  ActivityIndicator,
} from "react-native-web";

import getUserId from "../../../shared/core/GetToken";
import GetAppSettings from "../../../shared/core/GetAppSettings";
import { ProgressBar } from "react-bootstrap";
import Settings from "../../../shared/config/Settings";
import { flushSync } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { actionAPI } from "../../../shared/reduxToolkit/actionAPI";


const PendingInvoiceUpdated = (props) => {
  const { PendingInvc, isGetting } = useSelector(state => state.PendingInvoices);
  const { Practise } = useSelector(state => state.PracticeOrganization);

  const { Profile, isFetching } = useSelector(state => state.UserProfile);
  const [messages, addMessage] = useMessages();
  const [pageSize, setPageSize] = useState(100); //state for the page number
  const [isReady, setIsReady] = React.useState(false); //state for check loading time
  const { Balance, isLoadoing } = useSelector(state => state.UserAccountBalance);
  const [selectedOption, setSelectedOption] = useState(2);
  const [isPaying, setIsPaying] = useState(false);
  const [amount, setAmount] = useState("")
  const [lookupData, setLookupData] = useState(null)
  const [invoiceNote, setInvoiceNote] = useState("");
  const [cardtypename, setCardTypeName] = useState("");
  const [disablePayClick, setDisablePayClick] = useState(false);
  const [progressbarNumber, setProgressbarNumber] = useState(0);
  const [paymentMethodType, setPaymentMethodType] = useState("2");
  const [cardTypeErrMsg, setCardTypeErrMsg] = useState("");
  const [appSettings, setAppSettings] = useState({});
  const [cardkindName, setCardkindName] = useState("");
  const [deductedAmount, setDeductedAmount] = useState(0);
  const [paymentConfigurationError, setPaymentConfigurationError] = useState(false);
  const [practiceCode, setPracticeCode] = useState(0)
  const [nhiNumber, setNhiNumber] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [cardTypeNotMatchErrMsg, setCardTypeNotMatchErrMsg] = useState("")
  const [poliPaymentModalVisible, setPoliPaymentModalVisible] = useState(false)
  const [navigateUrlForPoli, setNavigateUrlForPoli] = useState("")
  const [windcaveCardDetails, setWindcaveCardDetails] = useState({
    cardNumber: "",
    cardCVC: "",
    cardExpiry: "",
    holderName: ""
  })
  const [windcaveCardDetailsErrState, setWindcaveCardDetailsErrState] = useState({
    cardNumber: "",
    cardCVC: "",
    cardExpiry: "",
    holderName: ""
  })


  const [validationErrorMsgs, setValidationErrorMsgs] = useState({
    amountErr: "Minimum amount of $1 is required",
    cardNumberCompleteError: "Please enter correct card number",
    cardTypeErrMsg: "Please select a Card Type",
    onceValidated: false
  })
  const [checkedInvoiceIDrows, setCheckedInvoiceIDrows] = useState([])

  const [totalRecords, setTotalRecords] = useState(10)
  const [invoicesList, setInvoicesList] = useState([])
  const [patientEmail, setPatientEmail] = useState("")
  const [windcavePaymentURL, setWindcavePaymentURL] = useState("")
  const [isWindcavePaymentModalOpen, setIsWindcavePaymentModalOpen] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  let limit = 25
  let offset = 1

  const apiDispatcher = useDispatch()
  const getPendingInvoicesAPI = (fromStart = false) => {
    limit = 15;
    offset = fromStart ? 1 : Math.ceil(invoicesList.length / limit) + 1;
    setPageNumber(offset)
    console.log("mergeData offset =>", offset)
    if (offset == 1) {
      flushSync(() => {
        setInvoicesList([])
        setTotalRecords(0)
      })
    }
    apiDispatcher(actionAPI.GetPendingInvoicesFromAPI({ pageSize: limit, pageNumber: offset }))

  };
  useEffect(() => {
    getPendingInvoicesAPI()
  }, [])
  useEffect(() => {
    if (typeof (Practise) !== 'undefined' && Practise.length) {
      // item.resource.extension.find(o => o.url === 'ObservationSummary')?.valueString
      setPracticeCode(Practise[0]?.resource?.extension?.find(item => item?.url === "Practice Code")?.valueString)

    }
  }, [Practise])
  useEffect(() => {
    if (typeof (Profile) !== 'undefined' && Profile.length) {
      setNhiNumber(Profile[0]?.resource?.extension[3]?.valueString ? Profile[0]?.resource?.extension[3]?.valueString : "")
      setUserEmail(Profile[0]?.resource?.telecom.find(x => x.system == "email")?.value)
    }
  }, [Profile])
  useEffect(() => {
    if (PendingInvc && PendingInvc.length > 0) {
      setTotalRecords(PendingInvc[0].total)
      // let tempPageNumber = Math.ceil(invoicesList.length / limit) + 1
      if (invoicesList != PendingInvc[0]?.entry) {
        if (pageNumber !== 1) {

          const mergeData = [...invoicesList, ...PendingInvc[0]?.entry];

          setInvoicesList(mergeData);
        } else {

          setInvoicesList(PendingInvc[0].entry);
        }


      }

    }

  }, [PendingInvc])
  const fetchMoreInvoicesFromAPI = (ignore = false) => {

    if (ignore || invoicesList.length < totalRecords) {
      getPendingInvoicesAPI();
    }
  }

  const openNotificationWithIcon = (type, value, msg = '') => {
    notification[type]({
      message: msg,
      description: value,
    });
  };
  const handleCheckedInvoices = (invoiceIDsList) => {
    let arr = invoiceIDsList.filter(item => {
      if (item.isChecked) {
        return item;
      }
    })//.map(item=> item.InvoiceID)
    setCheckedInvoiceIDrows(arr)
  }
  const handleInvoiceReset = (e) => {
    e.preventDefault()
    setAmount("")
    setSelectedOption(2)
    setInvoiceNote("")

  }
  const handlePoliInvoiceReset = () => {

    setAmount("")
    setSelectedOption(2)
    setInvoiceNote("")

  }

  useEffect(() => {
    let emailArr = localStorage.getItem("@userToken").email

    apiDispatcher(actionAPI.GetAccountBalanceAPI())
  }, [])
  const radioChanged = (e) => {
    if (e.target.value === 1) {
      setAmount(Balance?.split(" ")[0]?.replace("$", ""))
    } else {
      setAmount("")
      setDeductedAmount(0)
    }
    setSelectedOption(e.target.value)
  }


  const handlePayNowValidations = () => {
    let errCaught = false
    // if (paymentMethodType === '1' && windcaveCardDetails.holderName.trim() === "") {
    //   setWindcaveCardDetailsErrState({
    //     ...windcaveCardDetailsErrState,
    //     holderName: "Card holder name is mandatory"
    //   })
    //   errCaught = true
    // }
    debugger
    if (amount == "" || amount.length == 0) {
      openNotificationWithIcon("error", "Please enter the amount of atleast $1 in order to pay.")
      errCaught = true
    }
    else if (parseFloat(amount) < 1) {
      openNotificationWithIcon("error", "Please enter the amount of atleast $1 in order to pay.")
      errCaught = true
    }

    return errCaught
  }

  const handleCardNumberValidations = () => {
    let errCaught = false
    if (validationErrorMsgs.cardNumberCompleteError == "" ||
      validationErrorMsgs.cardNumberCompleteError == null ||
      validationErrorMsgs.cardNumberCompleteError == "Your card number is invalid." ||
      validationErrorMsgs.cardNumberCompleteError == "Your card number is incomplete." ||
      validationErrorMsgs.cardNumberCompleteError == "CVC is required." ||
      validationErrorMsgs.cardNumberCompleteError == 'Please enter correct card number') {
      openNotificationWithIcon("error", "Card Number is invalid")
      errCaught = true
    }
    if (validationErrorMsgs.cardNumberCompleteError == "accomplished") {
      errCaught = false;
    }
    return errCaught

  }
  const handleCardTypeValidations = () => {
    let errCaught = false

    if (cardtypename == "") {
      setCardTypeErrMsg("Please select a Card Type")
      errCaught = true
    }
    else {
      console.log("cardName", cardtypename)
    }
    return errCaught
  }

  const isCardTypeMatch = () => {
    let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');

    if (cardType && cardkindName !== cardType?.split(" ")?.join("")?.toLowerCase()) {
      openNotificationWithIcon("error", "Payment cannot be processed as selected card type does not match with card number. ")
      return false
    } else {
      return true
    }
  }
  const isWindcaveCardTypeMatch = () => {
    let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');

    if (cardType && cardkindName !== cardType?.split(" ")?.join("")?.toLowerCase()) {

      return false
    } else {
      return true
    }
  }
  const handleStripePayment = async () => {
    try {
      getUserId().then((userInfo) => {
        const item = JSON.parse(userInfo);
        setDisablePayClick(true)

        var myHeaders = new Headers();
        myHeaders.append("Token", item.token)
        myHeaders.append("Content-Type", "application/json")
        let invoiceGridDetail = checkedInvoiceIDrows;
        let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');
        let notesTemp = JSON.parse(localStorage.getItem("@portalSettings"))?.Face2FaceConfirmationMessage?.split(",")[1];

        // var raw = {
          //   // Token: item.token,
          //   Amount: parseFloat(deductedAmount * 100),
          //   Description: 'Repeat Request',
          //   UserId: item.userid,
          //   Currency: "NZD",
        //   PatientID: item.parentid,
        //   invoiceDetailGrids: invoiceGridDetail,
        //   CardType: cardType,
        //   cardCharges: cardCharges,
        //   notes: invoiceNote,
        //   lognotes: notesTemp
        // }
        let caughtEmail = false
        console.log(deductedAmount)
        var raw = {
          "Amount": Math.ceil(parseFloat(deductedAmount).toFixed(2) * 100),
          "AmountNew": parseFloat(amount).toFixed(2) * 100,
          "PatientID": item.userid,
          "CardType": cardType,
          "cardCharges": cardCharges,
          "notes": "Invoice payment",
          "invoiceDetailGrids": invoiceGridDetail,
          "lognotes": notesTemp,
          "paymenttype": "stripe",
          "callfrom": "web",
          "IsRxRequest": false,
          "abctick": Settings.apiUrl,
          "MerchantReference": "dsf",
          "paymentMethodType": "card",
          "MerchantData": "patientId=" + item.userid + "&patientToken=" + item.token,
          "UserEmail": item.email?.filter((email, index) => {
            if (email && !caughtEmail) {
              caughtEmail = true
              return email
            }
          })[0]
        }
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify(raw)
        };
        fetch(Settings.apiUrl + `Patient/CreateCheckoutSessionAndGetUrl`, requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result)
          setDisablePayClick(false)
          if (result.IsSuccess) {
            window.open(result.successurl, "_self");
            // setNavigateUrlForPoli(result.successurl)
            // setPoliPaymentModalVisible(true)
            }
            if (result.IsSuccess === false && result.error) {
              openNotificationWithIcon("error", result.error ? result.error : "Payment was unsuccessful")
            }
            if (typeof result === "string") {
              openNotificationWithIcon("error", result)
            }
          })
          .catch(error => {
            openNotificationWithIcon("error", error)

          });

      })
    } catch (error) {
      setDisablePayClick(false)

    }
  }
  const handlePoliSubmit = async () => {

    if (amount == "" || amount.length == 0) {
      openNotificationWithIcon('error', 'Minimum amount of $1 is required')
      return;
    }
    else if (parseFloat(amount) < 1) {
      openNotificationWithIcon('error', 'Amount cannot be less than 1')
      return;
    }

    setProgressbarNumber(20)
    setDisablePayClick(true)

    //saving in indici temp payments



    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo);

      var myHeaders = new Headers();
      myHeaders.append("Token", item.token);
      myHeaders.append("Content-Type", "application/json");
      let invoiceGridDetail = checkedInvoiceIDrows;
      let notesTemp = JSON.parse(localStorage.getItem("@portalSettings"))?.Face2FaceConfirmationMessage?.split(",")[1];
      var raw = JSON.stringify({
        "Amount": (parseFloat(amount).toFixed(2)),
        "PatientID": item.userid,
        "notes": invoiceNote,
        "invoiceDetailGrids": invoiceGridDetail,
        "lognotes": notesTemp,
        "callfrom": "web",
        "IsRxRequest": false,
        "abctick": Settings?.apiUrl?.replace("/api/", ""),
        "MerchantReference": "string",
        "MerchantData": "patientId=" + item.userid + "&patientToken=" + item.token,

      })
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${Settings.apiUrl}Patient/ProcessPoliRequest`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setProgressbarNumber(100)
          setDisablePayClick(false)

          if (result.Success) {
            window.open(result.NavigateURL, "_self");
            // setNavigateUrlForPoli(result.NavigateURL)
            // setPoliPaymentModalVisible(true)
          }
          if (result.IsSuccess === false && result.error) {
            openNotificationWithIcon("error", result.error.message ? result.error.message : "Payment was unsuccessful")
          }
          if (typeof result === "string") {
            openNotificationWithIcon("error", result)

          }
        })
        .catch(error => openNotificationWithIcon("error", error));

    })

  }
  const handleWindcaveCardNumberValidations = () => {
    // if (validCardDiv?.toString()?.indexOf("is-invalid")) {
    //   console.log("windcaveCreditRef.current,", windcaveCreditRef.current?.toString()?.indexOf("is-invalid"))

    // }
    if (document.getElementsByClassName("card-input-windcave")[0]?.classList?.value.indexOf("is-invalid") >= 0) {
      return true
    } else {

      return false
    }
  }
  const xmlToJson = (element) => {
    const data = {};

    if (element.nodeType === Node.ELEMENT_NODE) {
      if (element.attributes.length > 0) {
        data['attributes'] = {};
        for (let i = 0; i < element.attributes.length; i++) {
          const attribute = element.attributes[i];
          data['attributes'][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (element.nodeType === Node.TEXT_NODE) {
      data['text'] = element.nodeValue.trim();
    }

    if (element.hasChildNodes()) {
      for (let i = 0; i < element.childNodes.length; i++) {
        const child = element.childNodes[i];
        const childData = xmlToJson(child);
        if (childData !== null) {
          if (data[child.nodeName]) {
            if (!Array.isArray(data[child.nodeName])) {
              data[child.nodeName] = [data[child.nodeName]];
            }
            data[child.nodeName].push(childData);
          } else {
            data[child.nodeName] = childData;
          }
        }
      }
    }

    return data;
  }
  const handleWindcaveSubmit = async () => {

    // e.preventDefault();

    let isAmountValidated = handlePayNowValidations();
    let isCardValidated = handleWindcaveCardNumberValidations()
    let isCardTypeValidated = appSettings?.IsCardTypeConfifiguration && handleCardTypeValidations()
    setValidationErrorMsgs({ ...validationErrorMsgs, onceValidated: true });


    if (isAmountValidated || isCardValidated) {
      return
    }


    let isCardTypeNumMatch = appSettings?.IsCardTypeConfifiguration && isWindcaveCardTypeMatch()

    setProgressbarNumber(25)
    // setIsPaying(true);
    setDisablePayClick(true)

    const itemUserInfo = await getUserId().then((userInfo) => {
      return JSON.parse(userInfo);
    });
    let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');

    let notesTemp = JSON.parse(localStorage.getItem("@portalSettings"))?.Face2FaceConfirmationMessage?.split(",")[1];

    let calculatedAmount = amount

    if (amount.toString().indexOf(".") >= 0) {
      let decimalLength = amount.toString().split(".")[1].length
      if (decimalLength === 1) {
        calculatedAmount = calculatedAmount + "0"
      }
    }
    if (true) {
      let invoiceGridDetail = checkedInvoiceIDrows;

      let caughtEmail = false
      var raw = {
        "Amount": (parseFloat(deductedAmount).toFixed(2)),
        "PatientID": itemUserInfo.userid,
        "CardType": cardType,
        "cardCharges": cardCharges,
        "notes": "Invoice payment",
        "invoiceDetailGrids": invoiceGridDetail,
        "lognotes": notesTemp,
        "paymenttype": "windcave",
        "callfrom": "web",
        "IsRxRequest": false,
        "abctick": Settings.apiUrl,
        "MerchantReference": "dsf",
        "paymentMethodType": "card",

        "MerchantData2": {
          "key1": "patientId=" + itemUserInfo.userid + "&patientToken=" + itemUserInfo.token,
        },
        "UserEmail": itemUserInfo.email?.filter((email, index) => {
          if (email && !caughtEmail) {
            caughtEmail = true
            return email
          }
        })[0]
      }

      var requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Token": itemUserInfo.token
        },
        body: JSON.stringify(raw),
        redirect: 'follow'
      };
      fetch(`${Settings.apiUrl}Patient/CreateWindCaveSession`, requestOptions)
        .then(res => res.json())
        .then(json => {

          setDisablePayClick(false);

          setProgressbarNumber(100);
          if (json.IsSuccess) {
            window.open(json?.responsedata?.links[1]?.href, "_self")
          } else if (typeof json === "string") {
            openNotificationWithIcon("error", json)
          } else if (json.IsSuccess === false && json.error) {
            openNotificationWithIcon("error", json.error)

          }

        })
        .catch(err => {
          openNotificationWithIcon("error", err)
          setDisablePayClick(false)

          setProgressbarNumber(100)
        })
    }
  }
  useEffect(() => {

    GetAppSettings().then((info) => {

      const set = JSON.parse(info)
      setPaymentMethodType(set.PaymentMethodType ? set.PaymentMethodType : "2")
      setLookupData(set.PaymentCardTypes && JSON.parse(set.PaymentCardTypes))
      setAppSettings(set)
    })
  }, [])
  useEffect(() => {
    getUserId().then((userId) => setPatientEmail(JSON.parse(userId).email))
  }, [])
  useEffect(() => {
    if (!isNaN(parseFloat(amount))) {
      let [cardType, cardCharges] = cardtypename?.replace('%', '').split('-');

      console.log("cardkindName", cardkindName, ",  cardType => ", cardType, " after trim => ", cardType.replace(" ", "").toLowerCase().trim())
      var stripeElementCardType = cardkindName
      var dropDownCardType = cardType?.split(" ")?.join("")?.toLowerCase()

      if (amount && parseFloat(amount) > 0 && cardtypename != "" && (dropDownCardType)) {
        setDeductedAmount((((parseFloat(amount) * parseFloat(cardCharges)) / 100) + parseFloat(amount)).toFixed(2))
      } else {
        setDeductedAmount((parseFloat(amount) === NaN ? 0 : parseFloat(amount)).toFixed(2))
      }
    }

  }, [amount, cardkindName, cardtypename])
  return (
    <div className="accounts-info-container">
      <div className="payment-details-container mb-4">

      </div>
      {!paymentConfigurationError ? appSettings?.IsShowPayNow ? <div className={"payment-input-controls-container grid mb-4 " + (disablePayClick ? "opacitypoint5 pteventsnone" : "opacityone pteventsall")}>
        {<div className="grid-body">
          <form autoComplete="off" className={`payment-details-form ${"opacityone pteventsall"}`} >
            <div className="form-group d-inline d-lg-flex">
              <div className="form-control-container mb-2 mb-lg-0">
                <Radio.Group onChange={(e) => radioChanged(e)} value={selectedOption}>
                  <Radio value={1}>Pay total outstanding balance</Radio>
                  <Radio value={2}>Pay another amount</Radio>
                </Radio.Group>
              </div>
              <div className="form-control-container mb-2 mb-lg-0 ml-lg-auto">

                <div className="account-balance payment-card py-2 d-flex align-items-center">
                  <p className="payment-label m-0 mr-2">Account Balance:</p>
                  <h4 className="payment-amount m-0">{Balance}</h4>
                </div>
              </div>

            </div>
            <div className="form-group row">
              <div className="form-item col-md-6 col-sm-6">

                <label className="mb-0">Amount</label>
                <div className="form-control-container d-flex flex-column align-items-start">

                  <Input
                    autoComplete="off"
                    placeholder="Amount"
                    className='h-34px'
                    disabled={selectedOption != 2}
                    value={amount}
                    type="number"
                    onChange={(e) => {

                      let currentAmount = e.target.value

                      if (currentAmount.toString().indexOf(".") < 0 || currentAmount.toString().split(".")[1].length <= 2) {

                        setAmount(e.target.value);
                        if (e.target.value.length == 0)
                          setValidationErrorMsgs({ ...validationErrorMsgs, amountErr: "Please enter amount" })
                        else if (parseFloat(e.target.value) < 1) {

                          setValidationErrorMsgs({ ...validationErrorMsgs, amountErr: "Amount cannot be less than 1" })
                        }
                        else
                          setValidationErrorMsgs({ ...validationErrorMsgs, amountErr: "" })
                      }
                    }}
                    name="amountErr"
                  />
                  <p className="text-danger">{validationErrorMsgs.onceValidated && selectedOption != 1 && validationErrorMsgs.amountErr}</p>
                </div>
              </div>
              {appSettings?.IsCardTypeConfifiguration && paymentMethodType === "2" && <div className="form-item col-md-6 col-sm-6">

                <label className="mb-0">Card Type</label>
                <div className="form-control-container">

                  {lookupData ?

                    <Select
                      onChange={(e) => {
                        setCardTypeName(e);
                        setCardTypeErrMsg("");
                        setCardTypeNotMatchErrMsg("");
                      }}
                      value={cardtypename}
                      className='w-100'
                      // disabled
                      options={
                        [{
                          value: "",
                          label: "Select"
                        }].concat(

                          lookupData.map(item => {
                            return {
                              value: item.CardFee,
                              label: item.CardFee,
                            }
                          })
                        )
                      }
                    /> : <Spin />
                  }

                </div>
                <p className="text-danger">{cardTypeErrMsg}</p>

              </div>}




              <div className="form-item col-md-6 col-sm-6">

                <label className="mb-0">Notes</label>
                <div className="form-control-container">
                  <textarea
                    autoComplete="off"
                    placeholder="Notes"
                    className="form-control"
                    rows={1}

                    value={invoiceNote}
                    onChange={(e) => setInvoiceNote(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
            </div>

            <div className="form-group">

              <div className="form-submit-btn d-flex justify-content-start">
                <span className="fw-bolder mr-2">Deducted Payment:</span> ${deductedAmount}
              </div>
              <div className="form-submit-btn d-flex justify-content-end">
                <button onClick={handleInvoiceReset} className="btn btn-lg amount-reset-btn ml-2 ml-lg-4">
                  Reset
                </button>

                <Tooltip title={typeof PendingInvc !== "undefined" && PendingInvc[0]?.entry?.length > 0 ? "Pay Now" : "No invoices to pay"}>

                  {
                    <button onClick={(e) => {
                      e.preventDefault()
                      let isAmountValidated = handlePayNowValidations();

                      if (isAmountValidated) {
                        return
                      }

                      if (paymentMethodType === "1") {
                        handleWindcaveSubmit()
                      } else if (paymentMethodType === "2") {
                        handleStripePayment()
                      } else {

                        handlePoliSubmit()
                      }
                    }} className={`btn btn-sm pay-with-stripe-btn btn-margin-left paynow-btn ${disablePayClick || typeof PendingInvc == "undefined" || PendingInvc[0]?.entry?.length == 0 ? " opacitypoint3" : "opacityone"}`} disabled={disablePayClick || typeof PendingInvc == "undefined" || PendingInvc[0]?.entry?.length == 0} >
                      {isPaying ? <ActivityIndicator size="small" color="#fff" /> :
                        <div className="paynow-btn-div">
                          <span className="upper">PAY WITH</span>
                          <span className="lower">{paymentMethodType == "1" ? "Windcave" : paymentMethodType == "2" ? "Stripe" : "Poli"}</span>
                        </div>
                      }
                    </button>
                  }
                </Tooltip>
                <Modal
                  title="Poli Payment"
                  open={poliPaymentModalVisible}
                  onCancel={() => setPoliPaymentModalVisible(false)}
                  maskClosable={false}
                  okButtonProps={{ style: { display: 'none' } }}
                  cancelText="Close"
                  afterClose={() => {
                    getPendingInvoicesAPI(true);
                    apiDispatcher(actionAPI.GetAccountBalanceAPI())
                    handlePoliInvoiceReset()
                    setDeductedAmount(0)

                  }}
                  width={700}>
                  <iframe title="Poli Payment" src={navigateUrlForPoli} width={"100%"} height="700px"></iframe>
                </Modal>
                <Modal
                  title="Windcave Payment"
                  open={isWindcavePaymentModalOpen}
                  onCancel={() => setIsWindcavePaymentModalOpen(false)}
                  maskClosable={false}
                  okButtonProps={{ style: { display: 'none' } }}
                  cancelText="Close"
                  afterClose={() => {
                    getPendingInvoicesAPI(true);
                    apiDispatcher(actionAPI.GetAccountBalanceAPI())
                    handlePoliInvoiceReset()
                    setDeductedAmount(0)

                  }}
                  width={700}>
                  <iframe title="Windcave Payment" src={windcavePaymentURL} width={"100%"} height="700px"></iframe>
                </Modal>
              </div>
            </div>
          </form>

          {/* {paymentMethodType == "1" ? <p className="text-danger text-center">{"Payment Express"} is not set up for this app</p> : null} */}
          {progressbarNumber != 100 && progressbarNumber != 0 ? <ProgressBar className='heigjt-helf-px' now={progressbarNumber} animated /> : null}
        </div>}
        <strong>
          <strong></strong>
        </strong>
      </div> : <div className="d-flex justify-content-end">
        <div className="form-control-container my-3">
          <div className="account-balance payment-card py-2 d-flex align-items-center">
            <p className="payment-label m-0 mr-2">Account Balance:</p>
            <h4 className="payment-amount m-0">{Balance}</h4>
          </div>
        </div>
      </div> : <>
        <div className="d-flex justify-content-end">
          <div className="form-control-container my-3 ">

            <div className="account-balance payment-card py-2 d-flex align-items-center">
              <p className="payment-label m-0 mr-2">Account Balance:</p>
              <h4 className="payment-amount m-0">{Balance}</h4>
            </div>
          </div>
        </div>
        {/* <p className="text-danger text-center">Payment method is set up but keys are not configured at practice level.</p> */}
      </>
      }
      {isGetting && (
        <div className='d-flex text-align-center margin-bottom-five' >
          <ActivityIndicator size="small" color="#00A1DE" />
        </div>
      )}
      <div id="pendingInvlicesScrollableDiv">
        {typeof PendingInvc !== "undefined" && PendingInvc[0]?.entry?.length && invoicesList.length ? (
          <InfiniteScroll
            dataLength={invoicesList.length}
            next={fetchMoreInvoicesFromAPI}
            hasMore={invoicesList.length < totalRecords}
            loader={<div className='d-flex justify-content-center my-2'><Spin /></div>}
            onScroll={() => { }}
            scrollableTarget="pendingInvlicesScrollableDiv"
          >
            <UserInvoice AllInvc={invoicesList} enteredAmount={amount} invoiceType="pending" handleCheckedInvoices={handleCheckedInvoices} />
          </InfiniteScroll>
        ) : !isGetting && <div className='overflow-x-auto' >
          <table className="invoice-table table table-striped table-hover w-100">
            <thead  >
              <tr className="invoice-table-row">
                {props.invoiceType == "pending" && <th className='w-50px' ></th>}
                <th className='w-100px'>Date</th>
                <th className='w-100px' >Patient</th>
                <th className='w-100px' >Payee</th>
                <th className="notes-th w-250px" >Notes</th>
                <th className='w-150px'>Type</th>
                <th className='w-150px'>Ref No.</th>
                <th className='w-150px'>Amount</th>
                <th className='w-150px'>Balance</th>
                <th className='w-100px'>Pay Now</th>
                <th className='w-37px' >Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>

                <td colSpan={11} className="alert alert-warning text-align-center" >
                  No Record Found
                </td>
              </tr>
            </tbody>
          </table>
        </div>}

      </div>

    </div>
  );
}

export default PendingInvoiceUpdated
