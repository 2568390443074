import { GETTING_IMMUNE, GETTING_IMMUNE_SUCCESS, GETTING_IMMUNE_MORE_SUCCESS, GETTING_IMMUNE_FAILURE, GETTING_IMMUNE_FINISHED } from "../constants/Constant";
import getAllImmune from "../services/ImmuneService";
import getUserId from "../core/GetToken";
import Settings from '../config/Settings'

//get all immune
export const fetchImmunefromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getImmune())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.userid,
                PageSize: pageSize,
                PageNumber: pageNumber,
                TypeID: 0,
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };

            fetch(Settings.apiUrl + "Patient/Immunization", requestOptions)
                .then(data => data.json())
                .then(json => {
                  
                    json[0].hasOwnProperty('entry') ? dispatch(getImmuneSuccess(json[0].entry)) : dispatch(getImmuneFinshed())

                })
                .catch(err => dispatch(getImmuneFail(err)))

            // getAllImmune(queryString)
            //     .then(data => data.json())
            //     .then(json => {
            //         json[0].hasOwnProperty('entry') ? dispatch(getImmuneSuccess(json[0].entry)) : dispatch(getImmuneFinshed())
            //     })
            //     .catch(err => dispatch(getImmuneFail(err)))
        })
    }
}

//get all immune
export const fetchMoreImmunefromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getImmune())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&TypeID=0'
            getAllImmune(queryString)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getImmuneMoreSuccess(json[0].entry)) : dispatch(getImmuneFinshed())
                })
                .catch(err => dispatch(getImmuneFail(err)))
        })
    }
}



const getImmune = () => {
    return {
        type: GETTING_IMMUNE
    }
}

//data success
const getImmuneSuccess = (data) => {
    return {
        type: GETTING_IMMUNE_SUCCESS,
        data,
    }
}

//data success
const getImmuneMoreSuccess = (data) => {
    return {
        type: GETTING_IMMUNE_MORE_SUCCESS,
        data,
    }
}

//data failed
const getImmuneFail = () => {
    return {
        type: GETTING_IMMUNE_FAILURE
    }
}

const getImmuneFinshed = () => {
    return {
        type: GETTING_IMMUNE_FINISHED
    };
}

