import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { clearUserState, fetchUserInformation, LogoutUser } from "../../shared/actions/AuthActions";
import { AuthContext } from "../../shared/core/context";
import getUserId from "../../shared/core/GetToken";
//import FlatList from 'flatlist-react';

import { View, TouchableOpacity, FlatList, ActivityIndicator, Text } from "react-native-web";
import { emailValidator, getInitials, passwordValidator } from '../../shared/core/utils';
import ProxyAccountWebHandler from "../components/ProxyAccountWebHandler";
import { Redirect, useNavigate } from "react-router-dom";

import AsyncStorage from "@react-native-async-storage/async-storage";

import { Modal, Button } from "react-bootstrap";
import ForgetPassword from './ForgetPassword';
import changePasswrodService from '../../shared/services/ChangePasswrod';
import Settings from '../../shared/config/Settings';
import indicilogo from '../assets/indicilogo.png'
import covidlogo from '../assets/covidlogo.png'
import LoginHelpModal from './LoginHelpModal';

import ReCAPTCHA from "react-google-recaptcha";
import { fetchApplicationSettingFromApi, RefreshApplicationSettings } from '../../shared/actions/GetApplicationSettingAction';
import { fetchIdentityServerApiUrl, resetIdentityServerData } from '../../shared/actions/IdentityServerActions';
import { refreshTokenValidatorData, validatePatientTokenFromApi } from '../../shared/actions/TokenValidActions';
import { refreshProxyListData } from '../../shared/actions/ProxyActions';
import { notification } from 'antd';
//export default function Login() {
const Login = props => {
  const linkHistory = useNavigate();

  useEffect(() => {


    //$("#email2").bind("paste", function () { return false; });
    // $("#email2").bind("copy", function () { return false; });

    //document.getElementById("mPassword").bind("paste", function () { return false; });
    //document.getElementById("mPassword").bind("copy", function () { return false; });

    //const myInput = document.getElementById('mPassword');
    //myInput.onpaste = e => e.preventDefault();
    //myInput.oncopy = e => e.preventDefault();

    // props.getProxy();
  }, []);

  const people = [
    { firstName: 'Elson', lastName: 'Correia', info: { age: 24 } },
    { firstName: 'John', lastName: 'Doe', info: { age: 18 } },
    { firstName: 'Jane', lastName: 'Doe', info: { age: 34 } },
    { firstName: 'Maria', lastName: 'Carvalho', info: { age: 22 } },
    { firstName: 'Kelly', lastName: 'Correia', info: { age: 23 } },
    { firstName: 'Don', lastName: 'Quichote', info: { age: 39 } },
    { firstName: 'Marcus', lastName: 'Correia', info: { age: 0 } },
    { firstName: 'Bruno', lastName: 'Gonzales', info: { age: 25 } },
    { firstName: 'Alonzo', lastName: 'Correia', info: { age: 44 } }
  ]


  const renderPerson = (person, idx) => {
    return (

      <li onClick={() => listOnClick(person.resource.id)} key={idx}>

        <b>{person.resource.id} </b> (<span>Testing</span>)

      </li >

    );
  }


  const listOnClick = (id) => {
    alert(id);
  }

  const [isRedirected, setIsRedirect] = useState(false)
  const { isLoggedIn, isLoggin, AuthUser, error, passworderror, loginFailed, userPasswordStatus } = props.AuthUser;
  const { isGettlistPrpxy } = props.Proxy
  const { identityApiData, identityApiDataLoading, identityApiDataSuccess } = props.IdentityServerApiData
  const { isAvailable, isLoadingAppsetting } = props.ApplicationSettings
  const { signIn, signOut } = React.useContext(AuthContext);
  const [Proxy, setProxy] = useState('');
  const [userAgrement, setUserAgrement] = useState(false)
  const [privacyStatementCheck, setPrivacyStatemCheck] = useState(false)
  const [firstState, setFirstState] = useState(false);
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false)
  const [helpModalOpen, setHelpModalOpen] = useState(false)
  const [wrongAttemptCount, setWrongAttemptCount] = useState(0)
  const [passwordChangeErrMsg, setPasswordChangeErrMsg] = useState("")

  const [username, setUsername] = useState("")
  const [userPassword, setUserPassword] = useState("")
  const processLogin = async (e) => {

    let useName = document.getElementById("mUserName").value;
    let password = document.getElementById("mPassword").value;


    const emailError = emailValidator(useName);
    const pawrdEror = passwordValidator(password);

    if (emailError || pawrdEror) {
      document.getElementById("errorMessage").innerHTML = "You have entered an invalid email address.";
      return;
    }
    document.getElementById("errorMessage").innerHTML = "";
    setUsername(useName)
    setUserPassword(password)
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Access-Control-Allow-Origin", "*");

    // var raw = JSON.stringify({
    //   "UserName": useName,
    //   "Password": password
    // });

    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow',
    //   mode: 'cors',

    // };

    // const IdentityCall = await fetch("https://stagingidentityserver.vitonta.com/api/Authentication", requestOptions);
    // const IdentityCallResult = await IdentityCall.json();
    // if (IdentityCallResult && IdentityCallResult.ACCAPIURL) {

    //   sessionStorage.setItem("ACCAPIURL", IdentityCallResult.ACCAPIURL);
    //     Settings.apiUrl = IdentityCallResult.ACCAPIURL;
    //     props.getUserToken(useName, password)
    // }
    // else
    // {
    //   // set




    // fetch("https://stagingidentityserver.vitonta.com/api/Authentication", requestOptions
    // )
    //   .then(response => response.json())
    //   .then(result => {
    //     // setShow(true)  
    //     if (result && result.APIURL) {
    //       Settings.apiUrl = result.APIURL
    //       props.getUserToken(useName, password)
    //     }

    //   })
    //   .catch(error => props.getUserToken(useName, password));

    // .catch(error => {});


    //for normal login without IS
    // props.getUserToken(useName, password)
    props.getIdentityUrl(useName, password)
  }

  useEffect(() => {
    if (identityApiDataSuccess && identityApiData) {
      props.getUserToken(username, userPassword)

    }
  }, [identityApiDataSuccess])

  const [showReset, setShowReset] = useState(false);
  const [show, setShow] = useState(false);

  const [isResetPassword, setIsResetPassword] = useState(false)
  const [termnConditonVisible, setTermnConditonVisible] = useState(false)


  const handleClose = () => setShow(false);
  // this method is used for to show the handleclose terma and conditon modal 
  // this is not used now because we add the check box in the reset passwrod modal.
  const handleCloseTemnCond = () => {
    props.clearResetState()
    setShowReset(false)
    setTermnConditonVisible(false)
    getProxyUsersApiCall(AuthUser[0].extension[0].valueString, AuthUser[0].id)

  };
  const handleCloseReset = () => {
    props.clearResetState()
    setShowReset(false)
    // use for to show the term and condition modal
    // setTermnConditonVisible(true)
    getProxyUsersApiCall(AuthUser[0].extension[0].valueString, AuthUser[0].id)

  };

  const handleShow = () => setShow(true);




  useEffect(() => {

  }, [showReset])

  const getProxyUsersApiCall = (userToken, userId) => {
    var myHeaders = new Headers();
    myHeaders.append("Token", userToken)
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      PatientID: userId,
    })
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: raw
    };

    fetch(Settings.apiUrl + "Patient/ProxyUsers", requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result[0].total === 1) {
          getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            //if the account self is patient
            if (item.userid === result[0].entry[0].resource.id) {
              //save single patient information
              // _saveData(item.token, item.userid, item.email, "No", item.username, item.parentid, result[0].entry[0].resource.extension[0].valueString, item.parentname)
              _saveData(item.token, item.userid, item.email, "No", item.username, item.parentid, result[0].entry[0].resource.extension[0].valueString, item.parentname, item.username)

            }
            else {
              _saveDataSingleProxy(item.token, result[0].entry[0].resource.id, item.email, "Yes", result[0].entry[0].resource.name[0].text, item.parentid, result[0].entry[0].resource.extension[0].valueString, item.parentname, item.username)


            }
          })
        }
        else {
          setProxy(result[0].entry)
          setShow(true)
          setFirstState(true);
        }
      })
      .catch(error => {});
  }
  const { q } = useParams()

  useEffect(() => {
    localStorage.clear()
    props.getUserToken("", "", q)
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify(
    //   {
    //     "UserName": "",
    //     "Password": "",
    //     "q": q
    //   }
    // );

    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow'
    // };
    // fetch(Settings.apiUrl + "Patient/Authentication", requestOptions).then(res => res.json())
    //   .then(json => {
    //     if (json?.Status == "token verified.") {
    //       setUsername(json?.language)
    //       setUserPassword(json?.version)
    //       props.getUserToken(json?.language, json?.version, -99)
    //     }
    //   })

   
  }, [])
  const _setDataState = async (proxyUserId, proxyUserName, proxyid) => {
    let jsonValue = '';
    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo);
     
      _saveData(item.token, proxyUserId, item.email, "Yes", proxyUserName, item.parentid, proxyid, item.parentname, item.username)

    })
  };


  const _saveDataSingleProxy = async (token, proxyUserId, pemail, isProxy, proxyUserName, parentid, proxyid, parentName, username) => {

    // alert(isProxy)
    await AsyncStorage.removeItem('@userToken');
    try {
      const jsonValue = JSON.stringify({ "token": token, "userid": proxyUserId, "email": pemail, "proxy": "Yes", "username": proxyUserName, "parentid": parentid, "proxyid": proxyid, "parentname": parentName, "username": username });
      await AsyncStorage.setItem('@userToken', jsonValue)
      loadNext();
    } catch (error) {
      // Error saving data
    }
  }

  const _saveData = async (token, proxyUserId, pemail, isProxy, proxyUserName, parentid, proxyid, parentName, username) => {

    // alert(isProxy)
    await AsyncStorage.removeItem('@userToken');
    try {
      const jsonValue = JSON.stringify({ "token": token, "userid": proxyUserId, "email": pemail, "proxy": isProxy, "username": proxyUserName, "parentid": parentid, "proxyid": proxyid, "parentname": parentName, "username": username });
      await AsyncStorage.setItem('@userToken', jsonValue)
      loadNext();
    } catch (error) {
    }
  }
  //************************************** End Proxey account **************************************/

  const loadReset = () => {
    // return (<Redirect to={"/resetpassword"} />)
    setShowReset(!showReset)
  }
  const closeResetModal = () => {
    // setShowReset(() => {
    //     return !showReset
    // })
    props.clearResetState()
    props.endUserSession();
    setShowReset(false)
    setShowReset((state) => {
      return state
    })

    signOut();
    // window.location.reload();




  }
  // let history = useNavigate()
  const loadNext = () => {

    setIsRedirect(true);
    setIsRedirect((state) => {
      return state;
    });
    signIn('baseuser');
    // linkHisto  linkHistory.push("/")
    // window.location.reload()
    props.validateToken(linkHistory);

    // return (<Redirect to={"/"} />)
  }



  const resetPasswordFunc = async () => {
    //    alert("ok")

    let password = document.getElementById("password").value;
    let confirmPassword = document.getElementById("confirmPassword").value;

    const passwordError = passwordValidator(password);
    const cnfrmPaswrd = passwordValidator(confirmPassword);
    if (passwordError) {
      setPasswordChangeErrMsg("Password is Required ");
      return;
    }
    if (cnfrmPaswrd) {
      setPasswordChangeErrMsg("Confirm Password is Required ");
      return;
    }
    if (password !== confirmPassword) {
      setPasswordChangeErrMsg("Password and Confirm Password does not match");
      return;
    }

    //check for lower case
    if (!password.match(/[a-z]/)) {
      setPasswordChangeErrMsg("Password must contain at least one lower case letter.");
      // passForm.passInput.focus();
      return;
    }

    //Validating length
    if (password.length < 8) {
      setPasswordChangeErrMsg("Your password has less than 8 characters.");
      return;
    }

    //check for upper ase
    if (!password.match(/[A-Z]/)) {
      setPasswordChangeErrMsg("Password must contain at least one upper case letter.");
      // passForm.passInput.focus();
      return;
    }
    var patt = new RegExp("^(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){2,}).{8,}$");
    //check for number
    if (!patt.test(password)) {
      setPasswordChangeErrMsg("Password must contain at least two number.");
      // passForm.passInput.focus();
      return;
    }
    
    if (!userAgrement) {
      setPasswordChangeErrMsg("You need to mark that you agree before you can proceed.");
      return;
    }
    document.getElementById("errorMessageReset").innerHTML = "";
    setIsResetPassword(true);
    var userInfo = (await AsyncStorage.getItem("@userPassordReset")) || "none";
    // check remining confirm and passweordk
    const item = JSON.parse(userInfo);
    var myHeaders = new Headers();
    myHeaders.append("Token", item.token);
    myHeaders.append("Content-Type", "application/json");
    const queryString = "?Token=" + item.token;
  


    var jsonPa = JSON.stringify({ PatientID: item.userid, Password: password });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: jsonPa,
      redirect: 'follow'
    };

    fetch(Settings.apiUrl + "Patient/ChangePassword", requestOptions)
      .then((data) => data.json())
      .then((json) => {
        // {"StatusCode":1,"StatusMessage":"Password updated successfully."}
        if (json.StatusCode == "1") {
          props.clearResetState();
          setShowReset(false);
          // for activity indicatorr
          setIsResetPassword(false);
          document.getElementById("errorMessageReset").innerHTML = "";
          handleCloseReset();
          // handleCloseReset()
        }
        // {"StatusCode":3,"StatusMessage":"three time password error try to some differect passsword"}
        if (json.StatusCode == "3") {
          setIsResetPassword(false);
          document.getElementById("errorMessageReset").innerHTML =
            json.StatusMessage;
        }
      })
      .catch((err) => {
        setIsResetPassword(false);
        handleCloseReset();
        document.getElementById("errorMessageReset").innerHTML =
          "Some thing went wrong please try agian.";
      });


  };



  const clearCache = async () => {
    try {
      await AsyncStorage.clear()
    } catch (e) {
      // clear error
    }

  }



  const [showincon, setshowicon] = useState('mdi mdi-eye')
  const [showReseticon, setshowReseticon] = useState('mdi mdi-eye')
  const [showconfirmResticcon, setshowconfirmResticcon] = useState('mdi mdi-eye')
  const showPassword = () => {
    var x = document.getElementById("mPassword");
    if (x.type === "password") {
      setshowicon('mdi mdi-eye-off')
      x.type = "text";
    } else {
      setshowicon('mdi mdi-eye')
      x.type = "password";
    }
  }
  const resetPasswordShow = (elementId) => {
    var x = document.getElementById(elementId);
    if (x.type === "password") {

      setshowReseticon('mdi mdi-eye-off')
      x.type = "text";
    } else {
      setshowReseticon('mdi mdi-eye')
      x.type = "password";
    }
  }
  const resetConfirmPasswordShow = (elementId) => {
    var x = document.getElementById(elementId);
    if (x.type === "password") {

      setshowconfirmResticcon('mdi mdi-eye-off')
      x.type = "text";
    } else {
      setshowconfirmResticcon('mdi mdi-eye')
      x.type = "password";
    }
  }
  // if (isRedirected === true) {
  //     return <Redirect to={'/'} />
  // }
  const handleCheckTermandCondition = () => {
    setUserAgrement(!userAgrement)
  }
  const handlePrivacyStatement = () => {
    setPrivacyStatemCheck(!privacyStatementCheck)
  }
  const loginOnEnter = e => {
    if (e.keyCode === 13) {

      e.preventDefault();
      processLogin(e)
      //alert("Enter Key is done")
      // handleSubmit();
    }
  };
  const handleHelpOk = () => {
    setHelpModalOpen(false)
  }
  const handleHelpCancel = () => {
    setHelpModalOpen(false)
  }
  const openNotification = (placement, type, msg,) => {

    notification[type]({
      message: msg,
      placement,
    });
  };
  useEffect(() => {
    if (loginFailed) {
      setWrongAttemptCount(wrongAttemptCount + 1)
      openNotification("topRight", "error", passworderror)
      setTimeout(() => {
        window.open("https://patientportal.myindici.co.nz/login", "_self")
      }, 5000)
    }
    if (isLoggedIn) {
      setWrongAttemptCount(0)
    }
  }, [loginFailed])
  // useEffect(() => {
  //   if (isLoggedIn && AuthUser?.Status == "token verified."
  //   ) {
  //     setUsername(AuthUser?.language)
  //     setUserPassword(AuthUser?.version)
  //     props.getUserToken(AuthUser?.language, AuthUser?.version, -99)
  //   }
  // }, [isLoggin])

  useEffect(() => {
    if (isLoggedIn) {
      if (AuthUser?.Status == "token verified.") {
        setUsername(AuthUser?.language)
        setUserPassword(AuthUser?.version)
        if (AuthUser?.Url) {
          Settings.apiUrl = AuthUser?.Url;
          localStorage.setItem('ApiBaseUrl',AuthUser?.Url);
        }
        sessionStorage.setItem("cameFromLoginSwitch", true)
        props.getUserToken(AuthUser?.language, AuthUser?.version, -99)
      }
      props.getAppSetting()

    }
  }, [isLoggedIn])
  useEffect(() => {
    if (!userPasswordStatus && !show && isLoggedIn && isAvailable) {
      getProxyUsersApiCall(AuthUser[0].extension[0].valueString, AuthUser[0].id)
    }

  }, [userPasswordStatus, show, isLoggedIn, isAvailable])
  const search = useLocation().search
  // useEffect(() => {
  //   const q = new URLSearchParams(search).get('q');
  //   if (q) {
  //     props.getUserToken("", "", q)

  //   }
  // }, [])
  useEffect(() => {
    props.resetAuthUserData()
    props.resetProxyData()
    props.resetAppSettings()
    props.resetIdentityData()
    props.resetTokenData()
    localStorage.clear()
  }, [])
  return (
    <>
    <div>
        {
          !showReset && userPasswordStatus && loadReset()
        }
    </div>
      <Modal show={show} onHide={handleClose} backdrop="static"
        keyboard={false}>
        <Modal.Header className="modal-header accountheader">
          <Modal.Title className="modal-title text-white">Select An Account to Continue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            typeof (Proxy) !== 'undefined' && Proxy.length ? (
              <FlatList
                data={Proxy}
                className="flex-column"
                renderItem={({ item }) => (
                  <TouchableOpacity onPress={() => _setDataState(item.resource.id, item.resource.name[0].text, item.resource.extension[0].valueString)}>
                    <ProxyAccountWebHandler
                      reInitails={getInitials(item.resource.name[0].text)}
                      reReceiverName={item.resource.name[0].text}
                      reType={item.resource.extension[1].valueString} />
                  </TouchableOpacity>
                )}
                keyExtractor={item => item.resource.id}
                horizontal={false}
                initialNumToRender={30}
                maxToRenderPerBatch={10}
                windowSize={7}
                onEndReachedThreshold={0.1}
                onEndReached={() => {

                }}
              />
            ) : null

          }

        </Modal.Body>

      </Modal>
      <Modal
        show={showReset}
        onHide={() => handleCloseReset()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="modal-header header-primary-bg">
          <Modal.Title className="modal-title text-white">
            Welcome to indici. Update your password to continue.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div
              className='password-update-modal-rules mb-4'
            >
              <p className='fw-bolder'>Password Rules</p>
              <p>Password must be at least 8 characters.</p>
              <p>
                Password must contain at least one alphabet and two digits.
              </p>
              <p>
                Password must contain at least one upper case letter.
              </p>
              <p>
                Password must not contain first, middle or family name.
              </p>
              <p>Example: Abc@1234</p>
            </div>
            <div className="form-group ">
              <div className="input-group">
                <input
                  autoComplete="off"
                  id="password"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                />

                <div className="input-group-append">
                  <div
                    onClick={() => {
                      resetPasswordShow("password");
                    }}
                    className="input-group-text"
                  >
                    <i className={showReseticon}></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="input-group">
                <input
                  autoComplete="off"
                  id="confirmPassword"
                  type="password"
                  className="form-control"
                  placeholder="Confirm password."
                />

                <div className="input-group-append">
                  <div
                    onClick={() => resetConfirmPasswordShow("confirmPassword")}
                    className="input-group-text"
                  >
                    <i className={showconfirmResticcon}></i>
                  </div>
                </div>
              </div>
              {/* 
                            <div className="form-group input-rounded">

                            </div> */}
            </div>
            <div className="form-group input-rounded">
              <p id="errorMessageReset" className='text-danger' >{passwordChangeErrMsg}</p>
            </div>
            {isResetPassword && (
              <div className='activity-indicator-login'>
                <ActivityIndicator size="small" color="#00A1DE" />
              </div>
            )}
            <div
              className='user-agreement'
            >

              <label className='user-agreement-label'>
                <input
                  autoComplete="off"
                  className='bg-danger margin-right-five'
                  onChange={handleCheckTermandCondition}
                  type="checkbox"
                  checked={userAgrement}
                />
                I have read and agree to the{" "}
                <a href="https://firebasestorage.googleapis.com/v0/b/project-h-de8a7.appspot.com/o/Documents%2FmyIndici%20Terms%20and%20Conditions.pdf?alt=media&token=9e95577a-dfab-4e98-b7fc-08f6eaf39356" target="_blank">
                  myindici Patient Portal User Agreement
                </a>
              </label>
            </div>

            <div className="form-group input-rounded">
              <p id="privacyMessage" className='text-danger'></p>
            </div>

            <div className='d-flex flex-row flex-one'>
              <div className='d-flex flex-one'></div>
              <div
                className='d-flex flex-two flex-row align-content-center align-items-center justify-content-center'

              >
                <button
                  onClick={() => resetPasswordFunc()}
                  className="btn btn-primary btn-block"
                >
                  Proceed
                </button>
                <button
                  className="btn btn-primary btn-block reset-modal-cancel-btn mt-0"

                  id="noButton"
                  onClick={() => {
                    closeResetModal();
                    // setShowReset(!showReset)
                    // setTermnConditonVisible(false)
                    // handleCloseReset()
                    // linkHistory.push("/")
                    window.open("https://patientportal.myindici.co.nz/login", "_self")
                  }}
                >
                  Cancel
                </button>

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
}



function mapStateToProps(state) {
  return {
    AuthUser: state.AuthUser,
    ApplicationSettings: state.ApplicationSettings,
    Proxy: state.Proxy,
    IdentityServerApiData: state.IdentityServerReducer,
  }
}

function mapDispatchToProps(disptach) {
  return {
    getUserToken: (email, password, q = "") => disptach(fetchUserInformation(email, password, q)),
    clearResetState: () => disptach(clearUserState()),
    endUserSession: () => disptach(LogoutUser()),
    getAppSetting: () => disptach(fetchApplicationSettingFromApi()),
    getIdentityUrl: (email, password) => disptach(fetchIdentityServerApiUrl(email, password)),
    validateToken: (history) => disptach(validatePatientTokenFromApi(history)),

    resetTokenData: () => disptach(refreshTokenValidatorData()),
    resetProxyData: () => disptach(refreshProxyListData()),
    resetAppSettings: () => disptach(RefreshApplicationSettings()),
    resetIdentityData: () => disptach(resetIdentityServerData()),
    resetAuthUserData: () => disptach(clearUserState()),
  }
}


//export default Login;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)