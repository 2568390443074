import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Settings from "../../../config/Settings";
import getUserId from "../../../core/GetToken";

export const GetProxyUsersAPI = createAsyncThunk(
    "proxyUsersData/user",
    async (values, { dispatch, getState }) => {
        dispatch(gettingProxyData())
        getUserId().then((userInfo) => {

            const item = userInfo !== "none" && JSON.parse(userInfo);


            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = {
                PatientID: item.parentid,

            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(raw)
            };
            fetch(Settings.apiUrl + "Patient/ProxyUsers?", requestOptions)
                .then(response => response.json())
                .then(json => {

                    json[0].hasOwnProperty('entry') ? dispatch(gettingProxyDataSuccess(json[0].entry)) : dispatch(gettingProxyDataFailed(""))
                })
                .catch(error => dispatch(gettingProxyDataFailed("")));






        })
    }

);

export const refreshProxyListData = createAsyncThunk(
    "resetProxyUsersData/user",
    async (values, { dispatch, getState }) => {
        dispatch(gettingProxyDataReset())
    }
)

const GetProxyUsersSlice = createSlice({
    name: "proxyUsersData",
    initialState: {
        Proxy: [],
        isGettlistPrpxy: false,
        proxySuccess: false,
        errorProxy: false,
        proxyErrorMsg: ""
    },
    reducers: {
        gettingProxyData: (state, action) => {
            state.Proxy = []
            state.isGettlistPrpxy = true
            state.proxySuccess = false
            state.errorProxy = false
            state.proxyErrorMsg = ""
        },
        gettingProxyDataSuccess: (state, action) => {
            state.Proxy = action.payload
            state.isGettlistPrpxy = false
            state.proxySuccess = true
            state.errorProxy = false
            state.proxyErrorMsg = ""
        },
        gettingProxyDataFailed: (state, action) => {
            state.Proxy = []
            state.isGettlistPrpxy = false
            state.proxySuccess = false
            state.errorProxy = true
            state.proxyErrorMsg = action.payload
        },
        gettingProxyDataReset: (state, action) => {
            state.Proxy = []
            state.isGettlistPrpxy = false
            state.proxySuccess = false
            state.errorProxy = false
            state.proxyErrorMsg = ""
        },
    },
});





export const {
    gettingProxyData, gettingProxyDataFailed, gettingProxyDataSuccess, gettingProxyDataReset
} = GetProxyUsersSlice.actions;
export default GetProxyUsersSlice.reducer;
