import store from "../../configureStore"

const state = store.getState().IdentityServerReducer.identityApiData;

const Settings = {
	//apiUrl: 'https://patientportalapi.indici.ie/api/'
	//

	apiUrl: localStorage.getItem("ApiBaseUrl") ? localStorage.getItem("ApiBaseUrl") : state && state.ACCAPIURL ? state.ACCAPIURL : process.env.REACT_APP_API_URL 		//"https://devdhbconnect.itsmyhealth.nz/api/",
	//apiUrl: 'https://uatmyindiciapi.itsmyhealth.nz/api/'
	// apiUrl: "https://wcdhbapi.myindici.co.nz/api/",
};



export default Settings;

// apiUrl : 'https://stagingindiciconnect.itsmyhealth.nz/api/' https://erxindiciconnect.indici.nz/api/

// 'https://nhldevconnect.indici.ie/api/'
