import React, { useEffect, useState } from 'react';

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

import { fetchProfileFromApi } from "../../shared/actions/ProfileActions"
import { getProxyListApi } from '../../shared/actions/ProxyActions';
import { connect, useDispatch, useSelector } from "react-redux";
import { View, Text, Switch, TouchableOpacity, ActivityIndicator } from 'react-native-web';
import HomeCard from '../components/HomeCard';
import { fetchfAppointmentsFromApi } from '../../shared/actions/FutureAppointActions';
import { fetchAllergyFromApi } from '../../shared/actions/AllergiesActions';
import { fetchAdministeredImmunefromApi } from '../../shared/actions/AdministeredImmuneActions';
import { fetchTreactOfChoiceFromApi } from '../../shared/actions/TreatmentOfChoneMedicationAction';
import { fetchPractisefromAPI } from "../../shared/actions/PractiseAction";
import { fetchLongTermFromApi } from '../../shared/actions/DiagnosisActions';
import { fetchCounterFromApi } from '../../shared/actions/CounterActions';
import { FlatList } from 'react-native';
import { capitalize, FormatAMPM, GetAppointmentDate, GetMonthName, GetYear } from '../../shared/core/datehelper';
import TreatmentHandler from '../components/TreatmentHandler';
import { fetchDueImmunefromApi } from '../../shared/actions/DueImmuneActions';
import { fetchApplicationSettingFromApi } from '../../shared/actions/GetApplicationSettingAction';
import { Link } from 'react-router-dom';
import getUserId from '../../shared/core/GetToken';
import Settings from '../../shared/config/Settings';
import app from '../index'
import ProfilePracticeDetail from '../components/ProfilePracticeDetail';
import { Chart } from "react-google-charts";
import { Modal } from 'antd';
import GetAppSettings from '../../shared/core/GetAppSettings';
import { actionAPI } from '../../shared/reduxToolkit/actionAPI';

//export default function Home() {
const Home = props => {
    //***********************************Multiple api's call ********************** */
    const { FutureAppointments, isGetting } = useSelector(state => state.FutureAppointments);
    const { Allergies } = useSelector(state => state.AllergyIntolerance);
    const { DueImmune, isGettingImmune } = useSelector(state => state.DueImmune);
    const { LongDiag } = useSelector(state => state.LongTermDiagnosis);
    const { Practise } = useSelector(state => state.PracticeOrganization);
    const { Counter } = useSelector(state => state.RefreshCounter);
    const { ProxyMenu } = useSelector(state => state.ProxyMenu);
    const { ApplicationSettings } = useSelector(state => state.AppSettings);

    const [isPortalGuideModalOpen, setIsPortalGuideModalOpen] = useState(false)



    //*************************************************** */

    const [frontState, setFrontState] = React.useState(false);

    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);           //state for the page number

    const [isloading, setIsLoading] = useState(false);
    const [appSettings, setAppSettings] = useState({});

    const apiDispatcher = useDispatch()
    useEffect(() => {

        GetAppSettings().then((info) => {
            if (info && info != 'none') {

                const set = JSON.parse(info)
                setAppSettings(set)
            }
        })
    }, [Practise])

    useEffect(() => {
     
     
        apiDispatcher(actionAPI.RefreshCounterAPI())

        apiDispatcher(actionAPI.GetPracticeOrganizationDataAPI())

    }, []);

    let video = true;



    const [isEnabled, setIsEnabled] = useState(false);
    // const toggleSwitch = () => setIsEnabled(previousState => !previousState);

    const toggleSwitch = (emailVal, pushVal, tokenVal) => {
        //alert(emailVal)
        setIsLoading(true);
        emailVal
            ? callApi(false, pushVal, tokenVal)
            : callApi(true, pushVal, tokenVal);
    };


    const callApi = (mail, push, tok) => {

        //alert()

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var raw = JSON.stringify({
                resourceType: "Patient",
                id: item.userid,
                extension: [
                    {
                        url: "PatientDeviceToken",
                        valueString: tok,
                    },
                    {
                        url: "EnableEmail",
                        valueBoolean: mail,
                    },
                    {
                        url: "EnableNotification",
                        valueBoolean: push,
                    },
                ],
            });

            // alert(isEnabled)

            var requestOptions = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: raw,
                redirect: "follow",
            };
            //alert(raw)

            //alert(raw)
            fetch(
                Settings.apiUrl + "Patient?Token=" + item.token + "",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    setIsLoading(false);
                    // setIsLoadingNot(false);
                    apiDispatcher(actionAPI.GetUserProfileAPI())
                })
                .catch((error) => {
                    setIsLoading(false);
                    //setIsLoadingNot(false);
                });

        });
    };

    const openMenu = () => {
        var element = document.getElementById("myphonemenu");

        setFrontState(frontState => !frontState);
        setFrontState((state) => {
            state ? element.classList.add("sidebar-collpased") : element.classList.remove("sidebar-collpased");
            return state;
        });
    }

    const [menuTilesActiveStates, setMenuTilesActiveStates] = useState({
        isAppoinmentsMenuExists: false,
        isMedicationMenuExist: false,
        isTestReportsMenuExist: false,
        isMessagesMenuExist: false,
        isTimelineMenuExist: false,
        isVitalsMenuExist: false,
        isRemindersMenuExist: false,
        isAccountsMenuExist: false,
    })
    useEffect(() => {
        if (ProxyMenu) {
            let obj = {
                isAppoinmentsMenuExists: false,
                isMedicationMenuExist: false,
                isTestReportsMenuExist: false,
                isMessagesMenuExist: false,
                isTimelineMenuExist: false,
                isVitalsMenuExist: false,
                isRemindersMenuExist: false,
                isAccountsMenuExist: false,
            }
            ProxyMenu.map(item => {
                if (item?.resource?.extension[0]?.valueString == "Test Results") {
                    obj.isTestReportsMenuExist = true
                }
                if (item?.resource?.extension[0]?.valueString == "Appointments") {
                    obj.isAppoinmentsMenuExists = true
                }
                if (item?.resource?.extension[0]?.valueString == "Messaging") {
                    obj.isMessagesMenuExist = true
                }
                if (item?.resource?.extension[0]?.valueString == "Medications") {
                    obj.isMedicationMenuExist = true
                }
                if (item?.resource?.extension[0]?.valueString == "Timeline") {
                    obj.isTimelineMenuExist = true
                }
                if (item?.resource?.extension[0]?.valueString == "Vitals") {
                    obj.isVitalsMenuExist = true
                }
                if (item?.resource?.extension[0]?.valueString == "Reminders") {
                    obj.isRemindersMenuExist = true
                }
                if (item?.resource?.extension[0]?.valueString == "Account") {
                    obj.isAccountsMenuExist = true
                }

            })
            setMenuTilesActiveStates(obj)
        }
    }, [ProxyMenu])

    const [readings, setReadings] = useState([])


    const [appointmentChartData, setAppointmentChartData] = useState(null)
    const [appointmentOverviewData, setAppointmentOverviewData] = useState(null)

    const appointmentChartOptions = {
        vAxis: {
            minValue: 0,
            maxValue: 50,
            format: 0,
            gridlines: { count: 5 }, minorGridlines: { count: 0 }
        },
        chartArea: { width: "70%", height: "70%" },
        pointSize: 5,
        legend: { 'position': 'none' },
        hAxis: {
            format: "MMM d", slantedText: false, maxAlternation: 3, textStyle: {
                bold: true,
                fontSize: 14,
                fontName: "Arial"

            }
        }

    };
    useEffect(() => {
        if (Counter) {
            setAppointmentChartData([
                ["Appointment Status", "", { role: "style" }],
                ["Completed", Counter.completedcount, "#8fe077"],
                ["Upcoming", Counter.futurecount, "sky"],
                ["Missed", Counter.missedcount, "#fabcbe"],
                ["Cancelled", Counter.totalpatientappointments, "#a5d0fd"],
              
            ])


        }


    }, [Counter])
    return (

        <div className="page-content-wrapper-inner">
            <div className="viewport-header">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb has-arrow">
                        <li className="breadcrumb-item">
                            <a href="#">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Home</li>
                    </ol>
                </nav>
            </div>


            <div className="HomeCardsContainer">
                <div className="HomeCardsinternalContainer">
                    <div className="row">
                        <Link className={"col-md-4 col-lg-3 col-xs-12 equel-grid " + (menuTilesActiveStates.isAppoinmentsMenuExists ? "" : "disabled")} to={menuTilesActiveStates.isAppoinmentsMenuExists ? {
                            pathname: '/appointments',
                            state: { "id": "2", }
                        } : ""}>
                            <div className="grid d-flex flex-column align-items-center justify-content-center card-home-padding">
                                <div className="grid-body text-center w-100">
                                    <div className="profile-img  no-avatar component-flat mx-auto card-img-bottom">
                                        <img src='assets/images/appointments-dashboard.svg' />
                                    </div>
                                    {
                                            <div className="home-card-inner-container">
                                                <span className="text-gray d-block mt-3">
                                                    <strong>  Appointments  </strong>
                                                    <img src='assets/images/chevron-right.svg' className='home-forward-icon' alt='forward' /> 
                                                </span> 
                                            {menuTilesActiveStates.isAppoinmentsMenuExists && <div className='home-counter' title='Upcoming Appointments'>{typeof (Counter) !== 'undefined' ? Counter.AppointmentsCounter : 0} </div>}
                                            </div>
                                    }
                               </div>

                            </div>
                        </Link>


                        <Link className={"col-md-4 col-xs-12 col-lg-3 equel-grid " + ((menuTilesActiveStates.isMessagesMenuExist ? "" : "disabled"))} to={menuTilesActiveStates.isMessagesMenuExist ? '/emails' : ""}>
                            <div className="grid d-flex flex-column align-items-center justify-content-center card-home-padding">
                                <div className="grid-body text-center w-100">
                                    <div className="profile-img   no-avatar component-flat mx-auto mb-4">
                                        <img src='assets/images/messages-dashboard.svg' />
                                    </div>
                                    {
                                            <div className="home-card-inner-container">
                                                <span className="text-gray d-block mt-3">
                                                    <strong>  Messaging  </strong>
                                                    <img src='assets/images/chevron-right.svg' className='home-forward-icon' alt='forward' /> 
                                                </span> 
                                            {menuTilesActiveStates.isMessagesMenuExist && <div className='home-counter' title='Unread Messages'>{typeof (Counter) !== 'undefined' ? Counter.InboxCounter : 0} </div>}
                                            </div>
                                    }

                                  
                                </div>
                            </div>
                        </Link>

                        <Link className={"col-md-4 col-lg-3 col-xs-12 equel-grid " + (menuTilesActiveStates.isMedicationMenuExist ? "" : "disabled")} to={menuTilesActiveStates.isMedicationMenuExist ? '/medications' : ""}>
                            <div className="grid d-flex flex-column align-items-center justify-content-center card-home-padding">
                                <div className="grid-body text-center w-100">
                                    <div className="profile-img  no-avatar component-flat mx-auto card-img-bottom ">
                                        <img src='assets/images/longtermmedictaion.svg' />
                                    </div>
                                    {
                                            <div className="home-card-inner-container">
                                                <span className="text-gray d-block mt-3">
                                                    <strong>  Medication  </strong>
                                                    <img src='assets/images/chevron-right.svg' className='home-forward-icon' alt='forward' /> 
                                                </span> 
                                            {menuTilesActiveStates.isMessagesMenuExist && <div className='home-counter' title='Long Term Medication'>{typeof (Counter) !== 'undefined' ? Counter.MedicationsCounter : 0} </div>}
                                            </div>
                                    }


                                </div>
                            </div>
                        </Link>

                        <Link className={"col-md-4 col-lg-3 col-xs-12 equel-grid " + (menuTilesActiveStates.isTestReportsMenuExist ? "" : "disabled")} to={menuTilesActiveStates.isTestReportsMenuExist ? '/reports' : ""}>
                            <div className="grid d-flex flex-column align-items-center justify-content-center card-home-padding">
                                <div className="grid-body text-center w-100">
                                    <div className="profile-img  no-avatar component-flat mx-auto card-img-bottom">
                                        <img src='assets/images/labresults-dashboard.svg' />
                                    </div>
                                    {
                                            <div className="home-card-inner-container">
                                                <span className="text-gray d-block mt-3">
                                                    <strong>  Test Results  </strong>
                                                    <img src='assets/images/chevron-right.svg' className='home-forward-icon' alt='forward' /> 
                                                </span> 
                                            {menuTilesActiveStates.isTestReportsMenuExist && <div className='home-counter' title='Test Result Count'>{typeof (Counter) !== 'undefined' ? Counter.TestResultsCounter : 0} </div>}
                                            </div>
                                    }
                                </div>
                            </div>
                        </Link>


          
                        <Link className={"col-md-4 col-lg-3 col-xs-12 equel-grid " + (menuTilesActiveStates.isTimelineMenuExist ? "" : "disabled")} to={menuTilesActiveStates.isTimelineMenuExist ? '/timeline' : ""}>
                            <div className="grid d-flex flex-column align-items-center justify-content-center card-home-padding">
                                <div className="grid-body text-center w-100">
                                    <div className="profile-img  no-avatar component-flat mx-auto card-img-bottom ">
                                        <img src='assets/images/Timeline-dashboard.svg' />
                                    </div>
                                  <div className="home-card-inner-container">
                                        <h2 className='home-card-h2'><span></span></h2>
                                                    <span className="text-gray d-block mt-3"> <strong> Timeline </strong>
                                                     <img src='assets/images/chevron-right.svg' className='home-forward-icon' alt='forward' /></span>
                                            </div>

                                </div>
                                
                            </div>
                        </Link>

                        <Link className={"col-md-4 col-lg-3 col-xs-12 equel-grid " + (menuTilesActiveStates.isVitalsMenuExist ? "" : "disabled")} to={menuTilesActiveStates.isVitalsMenuExist ? '/vitals' : ""}>
                            <div className="grid d-flex flex-column align-items-center justify-content-center card-home-padding">
                                <div className="grid-body text-center w-100">
                                    <div className="profile-img  no-avatar component-flat mx-auto card-img-bottom">
                                        <img src='assets/images/vitals-dashboard.svg' />
                                    </div>

                                    <div className="home-card-inner-container">
                                        <h2 className='home-card-h2'><span></span></h2>
                                        {<span className="text-gray d-block mt-3"> <strong> Vitals </strong> <img src='assets/images/chevron-right.svg' className='home-forward-icon' alt='forward' /></span>}

                                    </div>
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </Link>


                            <Link className={"col-md-4 col-lg-3 col-xs-12 equel-grid " + (menuTilesActiveStates.isAccountsMenuExist ? "" : "disabled")} to={menuTilesActiveStates.isAccountsMenuExist ? "/accounts" : ""}>
                            <div className="grid d-flex flex-column align-items-center justify-content-center card-home-padding">
                                <div className="grid-body text-center w-100">
                                        <div className="profile-img  no-avatar component-flat mx-auto card-img-bottom">
                                            <img src='assets/images/account-dashboard.svg' />
                                        </div>
                                    <div className="home-card-inner-container">
                                        <h2 className='home-card-h2'><span></span></h2>
                                            <span className="text-gray d-block mt-3"> <strong> Account </strong> <img src='assets/images/chevron-right.svg' className='home-forward-icon' alt='forward' /></span>
                                        </div>
                                </div>
                            </div>
                        </Link>

                            <Link className={"col-md-4 col-xs-12 col-lg-3 equel-grid "} to={menuTilesActiveStates.isRemindersMenuExist ? '' : ""} onClick={() => setIsPortalGuideModalOpen(true)}>
                                <div className="grid d-flex flex-column align-items-center justify-content-center card-home-padding">
                                    <div className="grid-body text-center w-100">
                                        <div className="profile-img   no-avatar component-flat mx-auto mb-4">
                                            <img src='assets/images/portal-guide-dashboard.svg' />
                                        </div>

                                        <div className="home-card-inner-container">
                                            <h2 className='home-card-h2'><span></span></h2>
                                            <span className="text-gray d-block mt-3"> <strong> Portal Guide </strong> <img src='assets/images/chevron-right.svg' className='home-forward-icon' alt='forward' /></span>
                                        </div>

                                    </div>
                                </div>
                            </Link>
                            <Modal
                                title="Portal Guide"
                                open={isPortalGuideModalOpen} onCancel={() => setIsPortalGuideModalOpen(false)} width={900}
                                closable={false}
                                cancelText="Close"
                                okButtonProps={{ style: { display: "none" } }}
                                maskClosable={false}>

                                <iframe title='Portal Guide' src="https://firebasestorage.googleapis.com/v0/b/myindici-nz.appspot.com/o/Portal%20Guide%2Fmyindici%202.0%20Mobile%20User%20Guide.pdf?alt=media&token=b9063cb4-3c14-42ca-bdd7-c20c69b5a58e%27" width={"100%"} height="500px" />

                            </Modal>
                    </div>
                </div>
            </div>

            <div className="HomeCardsContainer home-cards-container">
                <div className="HomeTableContainer">

                    <div className="grid mb-0">
                        <div className="d-flex justify-content-between">
                            <p className="letter-emergency-detail-card-heading mb-0" id="messageWel"> <span className='mb-0 fw-bolder'> My Practice Details </span></p>

                        </div>

                        <div className="table-responsive home-table-height" id="treatmentCenterTab">

                            {
                                typeof (Practise) !== 'undefined' && Practise.length ? (
                                    <table className="table info-table table-striped">


                                        <tbody>
                                            {typeof (Practise) !== 'undefined' && Practise.length > 0 ? <tr>
                                                <td>{Practise[0]?.resource?.extension?.find(o => o.url === "IsPortalPatientLocation")?.valueBoolean ? "Location " : ""}Name</td>
                                                <td>{Practise[0]?.resource?.extension?.find(o => o.url === "IsPortalPatientLocation")?.valueBoolean ? Practise[0]?.resource?.extension?.find(o => o.url === "LocationName")?.valueString : Practise[0].resource.name}</td>

                                            </tr> : null}
                                            <tr>
                                                <td>{"Start Time"}</td>
                                                <td>{!Practise[0]?.resource?.extension[6]?.valueBoolean
                                                    ? FormatAMPM(Practise[0].resource.extension[0].valueDateTime.split('+')[0]) : "-"}</td>

                                            </tr>
                                            {/* <tr>
                                                <td>{"End Time"}</td>
                                                <td>{FormatAMPM(Practise[0].resource.extension[1].valueDateTime)}</td>

                                            </tr> */}
                                            <tr>
                                                <td>{"Phone Number"}</td>
                                                <td>{Practise[0].resource.telecom[0].value}</td>

                                            </tr>
                                            <tr>
                                                <td>{"My GP"}</td>
                                                    <td>{Practise[0].resource.extension.find(o => o.url === 'My GP')?.valueString ? Practise[0].resource.extension.find(o => o.url === 'My GP')?.valueString : " - "}</td>

                                            </tr>
                                            <tr>
                                                <td>{"Address"}</td>
                                                <td>{Practise[0].resource.address[0].text}</td>

                                            </tr>
                                        </tbody>
                                    </table>

                                ) : null
                            }
                        </div>
                        {/* <div className="container videoContiner mt-3 mt-lg-0">
                            <video id="treatmentVideo" controls autoplay>
                                <source src="https://storage.googleapis.com/indici-prod.appspot.com/indici-videos/PORTAL%20WELCOME%20TAKE%201.mp4" type="video/mp4"></source>
                            </video>
                        </div> */}

                    </div>

                </div>

                <div className="HomeTableContainer h-100" >
                    <div className="grid h-100">
                        <div className="letter-emergency-detail-card-heading">
                            <h5 className="mb-0">Appointments Overview (1 year)</h5>
                        </div>
                        <div className="grid-body full-width-charts" >
                            <div className="item-wrapper">
                                {Counter && appointmentChartData && <Chart
                                    key="appoint1"
                                    chartType="ColumnChart"
                                    width="100%"
                                    height="300px"
                                    data={appointmentChartData}
                                    options={appointmentChartOptions}
                                />}


                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

function mapStateToProps(state) {
    return {
        // TokenValidator: state.TokenValidator,
        Profile: state.Profile,
        Proxy: state.Proxy,
        FutureAppointments: state.FutureAppointments,
        Allergies: state.Allergies,
        DueImmune: state.DueImmune,
        TreatmentOfChoices: state.TreatmentOfChoices,
        LongDiag: state.LongDiag,
        Practise: state.Practise,
        Counter: state.Counter,
        ApplicationSettings: state.ApplicationSettings,
        PatientMenu: state.ProxyMenu,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //validateToken: () => disptach(validatePatientTokenFromApi()),
        getProfile: () => dispatch(fetchProfileFromApi()),
        getProxy: () => dispatch(getProxyListApi()),
        getFutureAppoint: (pageSize, pageNumber) => dispatch(fetchfAppointmentsFromApi(pageSize, pageNumber)),
        getAlg: (pageNumber, pageSize) => dispatch(fetchAllergyFromApi(pageNumber, pageSize)),
        getDueImmune: (pageSize, pageNumber) => dispatch(fetchDueImmunefromApi(pageSize, pageNumber)),
        getTreatmentOfChoice: (pageSize, pageNumber) => dispatch(fetchTreactOfChoiceFromApi(pageSize, pageNumber)),
        getDiagnosisLong: (pageSize, pageNumber) => dispatch(fetchLongTermFromApi(pageSize, pageNumber, 'Not')),
        getPracticeCenterDetail: () => dispatch(fetchPractisefromAPI()),
        getCounter: () => dispatch(fetchCounterFromApi()),
        getAppSetting: () => dispatch(fetchApplicationSettingFromApi())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home)