import { USER_LOGIN, USER_LOGIN_SUCCESS, USER_LOGIN_FAILED, USER_LOGIN_END, USER_RESET_PASSWORD_STATE } from "../constants/Constant";
import { Button, message, Space } from 'antd';
import AsyncStorage from '@react-native-async-storage/async-storage';
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";


const error = () => {
    message.error('Some error occured, Please contact practice for further assistance.', 5);
};

const getBroswerData = () => {
    const userAgent = window.navigator.userAgent;
    let name = "Unknown Browser";
    let version = "";

    if (userAgent.includes("Chrome")) {
        name = "Google Chrome";
    } else if (userAgent.includes("Firefox")) {
        name = "Mozilla Firefox";
    } else if (userAgent.includes("Safari")) {
        name = "Apple Safari";
    } else if (userAgent.includes("Edge")) {
        name = "Microsoft Edge";
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
        name = "Opera";
    } else if (userAgent.includes("Trident")) {
        name = "Internet Explorer";
    }

    const versionIndex = userAgent.indexOf("Version");
    if (versionIndex !== -1) {
        version = userAgent.substring(versionIndex + 8, versionIndex + 12);
    } else {
        version = userAgent.substring(userAgent.indexOf(name) + name.length + 1);
    }

    return (`${name}-${version}`)
}
export function fetchUserInformation(username, password, q) {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
        {
            "UserName": username,
            "Password": password,
            "q": q,
            "Platform": `web browser (${getBroswerData()})`,
        }
    );

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };


    return (dispatch) => {
        dispatch(getuserToken())
        fetch(Settings.apiUrl + "Patient/Authentication", requestOptions)
            .then(data => data.json())
            .then(json => {
                let password_statu = null;
                if (json?.StatusCode && (json?.StatusCode === 102 || json?.StatusCode === 103 || json?.StatusCode === 104 || json?.StatusCode === 105)) {
                    dispatch(getUserTokenFailed(json?.ResponseMessage))
                }
                else if (json.Status == "unverified") {
                    dispatch(getUserTokenFailed("Token has expired"))
                }
                else if (json == null || json.hasOwnProperty('StatusCode')) {
                    dispatch(getUserTokenFailed(json.ResponseMessage));
                }
                else if (json?.Status == "token verified."
                ) {

                    dispatch(getUserTokenSuccess(json, false))
                }

                else if (json[0].hasOwnProperty('resourceType')) {

                    json[0].hasOwnProperty('resourceType') && json[0].extension[1].valueBoolean != undefined && json[0].extension[1].valueBoolean == true ? password_statu = true : password_statu = false

                    // if passwrod staus is true then it will be redirect the user to reset their passwrod.
                    if (password_statu) {
                        // print some thing
                        setPassordResetInfo(json[0].extension[0].valueString, json[0].id, json[0].id)
                        _storeData(json[0].extension[0].valueString, json[0].id, json[0]?.telecom?.map(item => {
                            if (item?.system == "email") {
                                return item?.value
                            }
                        }), 'No', json[0].name[0].text, json[0].extension?.find(o => o.url === "UserName").valueString)
                    } else {
                        //alert(json[0].name[0].text)

                        _storeData(json[0].extension[0].valueString, json[0].id, json[0].telecom.map(item => {
                            if (item.system == "email") {
                                return item.value
                            }
                        }), 'No', json[0].name[0].text, json[0].extension?.find(o => o.url === "UserName").valueString)

                    }
                    dispatch(getUserTokenSuccess(json, password_statu));


                    // sir code ...................
                    // dispatch(getUserTokenSuccess(json));
                }
                else {
                    dispatch(getUserTokenFailed("Login from Inside Api"));
                }
            })
            .catch(err => {

                // error()

                dispatch(getUserTokenFailed("Some error occured, Please contact practice for further assistance"))
            })
    }
}
export function fetchUserInformationError(errMsg) {
    return (dispatch) => {
        dispatch(getUserTokenFailed(errMsg))
    }
}
export const LogoutUser = () => {
    clearStorage();
    return (dispatch) => {
        getUserId().then((userInfo) => {
            if (userInfo == "none") {
                return
            }
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token



            var myHeaders = new Headers();
            myHeaders.append("token", item.token);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {

                method: 'POST',
                headers: myHeaders,

                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/Logout", requestOptions)
                .then(response => response.json())
                .then(result => {

                    alert(JSON.stringify(result))
                    if (result.StatusCode == 1) {
                        // aslert(result.ResponseMessage)
                    }
                })
                .catch(error => { });

        })
        dispatch(getUserLogout());
    }
}



//clear storage
const clearStorage = async () => {
    try {
        await AsyncStorage.clear()
        //alert('Storage successfully cleared!')
    } catch (e) {
        //alert('Failed to clear the async storage.')
    }
};

const _storeData = async (usertoken, userId, userEmail, isProxy, name, username) => {
    try {
        const jsonValue = JSON.stringify({ "token": usertoken, "userid": userId, "email": userEmail, "proxy": isProxy, "username": name, "parentid": userId, "proxyid": userId, "parentname": name, "username": username });
        await AsyncStorage.setItem('@userToken', jsonValue)

    } catch (error) {
        // Error saving data
    }
};

const setPassordResetInfo = async (usertoken, userId) => {
    try {
        const jsonValue = JSON.stringify({ "token": usertoken, "userid": userId });
        await AsyncStorage.setItem('@userPassordReset', jsonValue)

    } catch (error) {
        // Error saving data
    }
};


export const clearUserState = () => {
    return (dispatch) => {
        dispatch(clearUserResetState())
    }
}

const getuserToken = () => {
    return {
        type: USER_LOGIN
    }
}

const clearUserResetState = () => {
    return {
        type: USER_RESET_PASSWORD_STATE
    }
}

const getUserTokenSuccess = (data, status_Pass) => {
    return {
        type: USER_LOGIN_SUCCESS,
        passwordStatus: status_Pass,
        data,
    }
}

const getUserTokenFailed = (msg) => {
    return {
        type: USER_LOGIN_FAILED,
        msg,
    }
}

const getUserLogout = () => {
    return {
        type: USER_LOGIN_END
    }
}