import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text } from "react-native-web";

import { Modal, Spin, Table, Tabs, Tooltip } from "antd";
import "antd/dist/antd.css";
import DueTab from "./ImmuneTabs/DueTab";
import OverdueTab from "./ImmuneTabs/OverdueTab";
import AdministeredTab from "./ImmuneTabs/AdministeredTab";
import AllTabs from "./ImmuneTabs/AllTabs";
import { fetchProfileFromApi } from "../../shared/actions/ProfileActions";
import { connect, useSelector } from "react-redux";
import { ActivityIndicator } from "react-native";
import PendingInvoice from "./Accounts/PendingInvoice";
import AllInvoice from "./Accounts/AllInvoice";
import { useLayoutEffect } from "react";
import GetAppSettings from "../../shared/core/GetAppSettings";
import PendingInvoiceUpdated from "./Accounts/PendingInvoiceUpdated";
import getUserId from "../../shared/core/GetToken";
import Settings from "../../shared/config/Settings";
import moment from 'moment';

const { TabPane } = Tabs;

const Accounts = (props) => {

  const navigate = useNavigate()
  const { ProxyMenu, isLoadingMenu } = useSelector(state => state.ProxyMenu);
  const [removeDeviceID, setRemoveDeviceID] = useState("")
  const [trustedDeviceRemoveModalOpen, setTrustedDeviceRemoveModalOpen] = useState(false)
  const [isManagingTrustedDevice, setIsRemovingTrustedDevice] = useState(false)
  const [trustedDevicesList, setTrustedDevicesList] = useState(null)
  let reloadCount;


  // useLayoutEffect(() => {
  //   GetAppSettings().then((info) => {
  //     const set = JSON.parse(info);
  //     if (window.location.pathname.indexOf("account") > -1) { //stripe case
  //       reloadCount = sessionStorage.getItem('reloadCount');
  //       if (reloadCount < 1) {
  //         sessionStorage.setItem('reloadCount', String(reloadCount + 1))
  //         window.location.reload();
  //       }
  //       else {
  //         sessionStorage.removeItem('reloadCount');
  //       }
  //     }
  //   })
  // }, []);

  const manageTrustedDevicesList = (removeDevice = 0) => {
    getUserId().then((userInfo) => {

      setIsRemovingTrustedDevice(true)

      const item = JSON.parse(userInfo);
      var myHeaders = new Headers();
      myHeaders.append("Token", item.token);
      myHeaders.append("Content-Type", "application/json");
      //    public string PatientUUID { get; set; }

      //     public string DeviceID { get; set; }

      //     public string IsFromApp { get; set; }

      //     public bool OperationID { get; set; } = false;  //0 for get and 1 for delete

      var raw = JSON.stringify({
        PatientUUID: item.userid,
        DeviceID: removeDevice === 1 ? removeDeviceID : localStorage.getItem("DeviceID"),
        IsFromApp: false,
        OperationID: removeDevice,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: raw,

      };
      fetch(Settings.apiUrl + "MFA/ManageTrustedDevices", requestOptions)
        .then(response => response.json())
        .then(json => {
          setTrustedDeviceRemoveModalOpen(false)
          setIsRemovingTrustedDevice(false)
          setRemoveDeviceID("")
          if (json?.DeviceDetails && json?.DeviceDetails?.length > 0) {
            setTrustedDevicesList(json?.DeviceDetails)
          } else {
            setTrustedDevicesList([])
          }

        })
        .catch(err => console.log(err))
    })

  }
  useEffect(() => {
    manageTrustedDevicesList()
  }, [])
  useEffect(() => {
    if (ProxyMenu != null && ProxyMenu != undefined) {
      let itemExists = false
      ProxyMenu.map((item, index) => {
        if (item.resource.extension[0].valueString == 'Account' || true) {
          itemExists = true
        }

      })
      if (!itemExists) {
        navigate("/")
      }
    }
  }, [ProxyMenu])
  const gridColumns = [
    {
      title: 'NAME',
      dataIndex: "DeviceName",
      width: '200px',
      render: (item) => <p className='d-flex'><span className='w-100px'>{item}</span></p>
    },
    {
      title: 'LAST USED',
      dataIndex: "lastUsedAt",

      render: (item) => <p>{moment(item).format("DD-MM-YYYY hh:mm a")}</p>

    },
    {
      title: 'STATUS',
      dataIndex: "DeviceID",

      render: (item) => {
        return localStorage.getItem("DeviceID") === item ? <h4 className="card-title mb-2">
          <span className="text-success fw-bolder">Active</span>
        </h4> : <h4 className="card-title mb-2">
          <span className="text-danger fw-bolder">Inactive</span>
        </h4>
      }

    },
    {
      title: 'ACTION',
      dataIndex: "DeviceID",
      render: (item) => (
        <Tooltip title="Remove Device">

          <img className='trusted-delete-btn ml-2' src='assets/images/Delete.svg' onClick={() => {
            setTrustedDeviceRemoveModalOpen(true)
            setRemoveDeviceID(item)
          }} />
        </Tooltip>
      )

    },


  ];
  return (
    <div className="page-content-wrapper-inner">
      <div className="viewport-header">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb has-arrow">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Trusted Devices
            </li>
          </ol>
        </nav>
      </div>
      <div className="content-viewport">
        <div className="grid">
          <div className="grid-body">
            <div className="row row-margin-zero h-100">
              <div className="h-100 w-100 accounts-outer-container">


                {/* -----  */}


                {/* <h2 className="grid-title">Accounts</h2> */}
                <div className="item-wrapper trusted-devices-container">
                  <div className="col-lg-12 d-flex flex-wrap justify-content-around">
                    <Table
                      columns={gridColumns}
                      dataSource={trustedDevicesList}
                      pagination={false}
                      className='trusted-devices-table w-100'
                      loading={isManagingTrustedDevice}
                    />
                    {/* {isManagingTrustedDevice ? <div className="w-100 d-flex justify-content-center">
                      <Spin />
                    </div> : trustedDevicesList && trustedDevicesList.length > 0 ? trustedDevicesList.map(device => <div
                      className="trusted-devices-card card shadow p-0 m-3 rounded"
                    >
                      <div className="card-header text-white bg-primary">
                        <div className='trusted-devices-heading-text'>
                          <div className="trusted-devices-name">
                            <b>{device?.DeviceName}</b>
                          </div>

                          <img className='trusted-delete-btn' src='assets/images/Delete.svg' onClick={() => {
                            setTrustedDeviceRemoveModalOpen(true)
                            setRemoveDeviceID(device.DeviceID)
                          }} />

                        </div>
                      </div>
                      <div className="card-body mb-0">
                        <div className="row">
                          <div className=" col-12">
                            {localStorage.getItem("DeviceID") === device?.DeviceID ? <h4 className="card-title mb-2">
                              Status: <span className="text-success fw-bolder">Active</span>
                            </h4> : <h4 className="card-title mb-2">
                              Status: <span className="text-danger fw-bolder">Inactive</span>
                            </h4>}
                            <h4 className="card-title mb-2">
                              {moment(device?.lastUsedAt).format("DD-MM-YYYY hh:mm a")}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>) : <div className='alert alert-danger p-2 text-center'>No Records Found</div>} */}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
      <Modal
        open={trustedDeviceRemoveModalOpen}
        onCancel={() => setTrustedDeviceRemoveModalOpen(false)}
        onOk={() => {
          manageTrustedDevicesList(1)
        }}
        confirmLoading={isManagingTrustedDevice}
        afterClose={() => setRemoveDeviceID("")}
        title="Remove Device"
        okText="Yes"
        cancelText="No"
        maskClosable={false}
        cancelButtonProps={isManagingTrustedDevice ? { style: { pointerEvents: "none" } } : {}}
      >
        <p>Are you sure you want to remove this device?</p>
      </Modal>
    </div>


  );
};

//export default connect(mapStateToProps, mapDispatchToProps)(ImmuneHome);
function mapStateToProps(state) {
  return {
    ProxyMenu: state.ProxyMenu,
  }
}


function mapDispatchToProps(disptach) {
  return {
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Accounts)
