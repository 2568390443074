import React, { useCallback, useState, useEffect, useRef } from 'react';
import { fetchPractisefromAPI } from '../../shared/actions/PractiseAction';
import { fetchProfileFromApi } from '../../shared/actions/ProfileActions';
import { connect, useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { notification, Select, Input, Spin, Checkbox, Tooltip } from 'antd';

import PersonalDetail from '../components/PersonalDetail';
import ProfileAddress from '../components/ProfileAddress';
import ProfileContactDetails from '../components/ProfileContactDetails';
import ProfilePracticeDetail from '../components/ProfilePracticeDetail';
import Sidebar from "../components/Sidebar";
import { ActivityIndicator, View, Text, TouchableOpacity } from 'react-native';
import { passwordValidator } from '../../shared/core/utils';
import getUserId from '../../shared/core/GetToken';
import changePasswrodService from '../../shared/services/ChangePasswrod';
import { FlatList } from 'react-native';
import Svg, { Path, G } from "react-native-svg"
import { Modal, Button, DatePicker } from 'antd';
import 'antd/dist/antd.css';
import ProfileItem from '../components/ProfileItem';
import Settings from '../../shared/config/Settings';
import { fetchBalanceFromApi } from "../../shared/actions/GetBalanceAction";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { capitalize, GetAppointmentDate, GetMonthName, GetYear } from '../../shared/core/datehelper';
import moment from 'moment';
import { flushSync } from 'react-dom';
import AvatarEditor from 'react-avatar-editor'
import GetAppSettings from '../../shared/core/GetAppSettings';
import { isMobile } from 'react-device-detect';
import { actionAPI } from '../../shared/reduxToolkit/actionAPI';
import { debounce } from 'lodash';
import 'react-phone-number-input/style.css'
import PhoneInput, {
    isPossiblePhoneNumber,
    isValidPhoneNumber
} from 'react-phone-number-input'

const { Option } = Select
const ProfileScreen = props => {
    const { Profile, isFetching } = useSelector(state => state.UserProfile);
    const { Practise } = useSelector(state => state.PracticeOrganization);
    const { Balance, isLoadoing } = useSelector(state => state.UserAccountBalance);
    const [isloading, setIsLoading] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false)

    const [isAddingEmergency, setIsAddingEmergency] = useState(false)

    const [titleArray, setTitleArray] = useState([])
    const [relationShipArray, setRelationShipArray] = useState([])
    const [cityArray, setCityArray] = useState([])
    const [townArray, setTownArray] = useState([])
    const [genderlist, setGenderlist] = useState([])

    const [addContactVisible, setAddContactVisible] = useState(false);
    const [isEmergencyContactUpdating, setIsEmergencyContactUpdating] = useState(false);
    const [nokAddressID, setNokAddressID] = useState(0);
    const [updateEmergencyContactVisible, setUpdateEmergencyContactVisible] = useState(false);
    /////********************************Profile Modal   */
    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [contactid, setContactId] = React.useState('');

    const [editProfileModalVisible, setEditProfileModalVisible] = useState(false);

    const [editContactModalVisible, setContactModalVisible] = useState(false);
    const [editAddressesModalVisible, setAddressesModalVisible] = useState(false);
    const [editPostalAddressModalVisible, setPostalAddressModalVisible] = useState(false);

    const [emContactTitleID, setEmContactTitleID] = useState("")
    const [emContactTitle, setEmContactTitle] = useState("")

    const [emContactFirstName, setEmContactFirstName] = useState("")
    const [emContactFamilyName, setEmContactFamilyName] = useState("")

    const [emContactRelationshipID, setEmContactRelationshipID] = useState("")
    const [emContactRelationship, setEmContactRelationship] = useState("")

    const [emContactHomePhone, setEmContactHomePhone] = useState("")
    const [emContactWorkPhone, setEmContactWorkPhone] = useState("")
    const [emContactHomeNumber, setEmContactHomeNumber] = useState("")
    const [emContactFloorBuilding, setEmContactFloorBuilding] = useState("")
    const [emContactStreet, setEmContactStreet] = useState("")

    const [emContactSuburbTownID, setEmContactSuburbTownID] = useState("")
    const [emContactSuburbTown, setEmContactSuburbTown] = useState("Select")

    const [emContactCityID, setEmContactCityID] = useState("")
    const [emContactCity, setEmContactCity] = useState("Select")

    const [emContactResidence, setEmContactResidence] = useState("")
    const [emContactPostalCode, setEmContactPostalCode] = useState("")
    /////******************************** */

    const [profileTitle, setProfileTitle] = useState("");
    const [profileTitleID, setProfileTitleID] = useState("");
    const [profileFirstName, setProfileFirstName] = useState("");
    const [profileFamilyName, setProfileFamilyName] = useState("");
    const [profilePreferredName, setProfilePreferredName] = useState("");
    const [profileGender, setProfileGender] = useState("");
    const [profileEthnicity, setProfileEthnicity] = useState("");
    const [profileDOB, setProfileDOB] = useState("");
    const [profileEmail, setProfileEmail] = useState("");
    const [profileBloodGroup, setProfileBloodGroup] = useState("");
    const [profileBloodGroupID, setProfileBloodGroupID] = useState("0");



    /////******************************** */
    const [cmCellPhoneChecked, setCmCellPhoneChecked] = useState(false)
    const [cmNightPhoneChecked, setCmNightPhoneChecked] = useState(false)
    const [cmDayPhoneChecked, setCmDayPhoneChecked] = useState(false)
    const [cmTextChecked, setCmTextChecked] = useState(false)
    const [cmEmailChecked, setCmEmailChecked] = useState(false)
    const [cmPostChecked, setCmPostChecked] = useState(false)
    /////******************************** */


    /////******************************** */
    const [selectedContactMethods, setSelectedContactMethods] = useState([])
    /////******************************** */

    const [preferredCmID, setPreferredCmID] = useState(0)
    const [preferredCmName, setPreferredCmName] = useState("Select")
    /////******* Validations States */
    const [emContactTitleinValidMsg, setEmContactTitleinValidMsg] = useState("")
    const [emContactFirstNameinValidMsg, setEmContactFirstNameinValidMsg] = useState("")
    const [emContactFamilyNameinValidMsg, setEmContactFamilyNameinValidMsg] = useState("")
    const [emContactRelationshipinValidMsg, setEmContactRelationshipinValidMsg] = useState("")
    const [emContactHomePhoneinValidMsg, setEmContactHomePhoneinValidMsg] = useState("")
    const [emContactHomePhoneinValidState, setEmContactHomePhoneinValidState] = useState("")
    const [emContactWorkPhoneinValidMsg, setEmContactWorkPhoneinValidMsg] = useState("")
    const [emContactWorkPhoneinValidState, setEmContactWorkPhoneinValidState] = useState("")
    /////******************************** */

    const [changePassErrMsg, setChangePassErrMsg] = useState("")
    const [changeConfirmPass, setChangeConfirmPass] = useState("")
    const [changePass, setChangePass] = useState("")
    const showEmeregencyContact = () => {
        setAddContactVisible(true);
    }
    const [passwordStartMessage, setPasswordStartMessage] = useState({
        error: false,
        message: ''
    })
    const [isUpdateEmailModalOpen, setIsUpdateEmailModalOpen] = useState(false)
    const [changeEmail, setChangeEmail] = useState("")
    const [changeEmailErrMsg, setChangeEmailErrMsg] = useState("")
    const [confirmChangeEmail, setConfirmChangeEmail] = useState("")
    const [confirmChangeEmailErrMsg, setConfirmChangeEmailErrMsg] = useState("")

    // -----------------------Address iNfo Edit States

    const [cityEditAddressID, setCityEditAddressID] = useState("")
    const [cityEditAddressName, setCityEditAddressName] = useState("")
    const [cityEditAddressErrMsg, setCityEditAddressErrMsg] = useState("")
    const [findAddressText, setFindAddressText] = useState(null)
    const [findAddressTextLoading, setFindAddressTextLoading] = useState(false)
    const [houseNumEditAddress, setHouseNumEditAddress] = useState("")
    const [floorEditAddress, setFloorEditAddress] = useState("")
    const [streetEditAddress, setStreetEditAddress] = useState("")
    const [countryEditAddressID, setCountryEditAddressID] = useState("")
    const [countryEditAddressName, setCountryEditAddressName] = useState("")
    const [postCodeEditAddress, setPostCodeEditAddress] = useState("")

    // Postal Address Edit States
    const [cityEditPostalAddressID, setCityEditPostalAddressID] = useState("")
    const [cityEditPostalAddressName, setCityEditPostalAddressName] = useState("")
    const [cityEditPostalAddressErrMsg, setCityEditPostalAddressErrMsg] = useState("")
    const [houseNumEditPostalAddress, setHouseNumEditPostalAddress] = useState("")
    const [floorEditPostalAddress, setFloorEditPostalAddress] = useState("")
    const [streetEditPostalAddress, setStreetEditPostalAddress] = useState("")
    const [countryEditPostalAddressID, setCountryEditPostalAddressID] = useState("")
    const [countryEditPostalAddressName, setCountryEditPostalAddressName] = useState("")
    const [postCodeEditPostalAddress, setPostCodeEditPostalAddress] = useState("")
    const [isSamePostalAddressEditAddress, setIsSamePostalAddressEditAddress] = useState(true)

    const [foundAddressList, setFoundAddressList] = useState([])
    const [noAddressFoundFromSearch, setNoAddressFoundFromSearch] = useState(false)
    const [patientImageData, setPatientImageData] = useState("")
    const [avatarImageDimensions, setAvatarImageDimensions] = useState({
        width: 380,
        mobWidth: 200,
        height: 250,
        scale: 1.0,
        border: 50,
        rotate: 0
    })
    const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] = useState(false);
    const [isPortalGuideModalOpen, setIsPortalGuideModalOpen] = useState(false);
    const [trustedDevicesList, setTrustedDevicesList] = useState(null);
    const [trustedDevicesListModalOpen, setTrustedDevicesListModalOpen] = useState(false);
    const [trustedDeviceRemoveModalOpen, setTrustedDeviceRemoveModalOpen] = useState(false);
    const [removeDeviceID, setRemoveDeviceID] = useState("");
    const [isRemovingTrustedDevice, setIsRemovingTrustedDevice] = useState(false);
    const [appSettings, setAppSettings] = useState(null);

    const apiDispatcher = useDispatch()
    const handleIsSamePostalAddressEditAddressChange = () => {

        setCityEditPostalAddressID(cityEditAddressID)
        setCityEditPostalAddressName(cityEditAddressName)
        setHouseNumEditPostalAddress(houseNumEditAddress)
        setFloorEditPostalAddress(floorEditAddress)
        setStreetEditPostalAddress(streetEditAddress)
        setStreetEditPostalAddress(streetEditAddress)
        setCountryEditPostalAddressID(countryEditAddressID)
        setCountryEditPostalAddressName(countryEditAddressName)
        setPostCodeEditPostalAddress(postCodeEditAddress)

        setIsSamePostalAddressEditAddress(!isSamePostalAddressEditAddress)
    }
    const calculateAddress = (addressText = "") => {
        let hNumber = ""
        let floorBuildNum = ""
        let stNum = ""
        let subTownName = ""
        let cityName = ""
        let postCode = ""
        const splitArr = addressText?.split(",")
        if (splitArr?.length > 0) {
            hNumber = splitArr[0]?.split(" ")[0]
            stNum = splitArr[0]?.split(" ")?.filter((item, index) => index != 0).toString()?.replace(/,/g, " ");
            subTownName = splitArr[1]
            cityName = splitArr[2]?.split(" ")?.filter((item, index) => index != (splitArr[2]?.split(" ")?.length - 1))?.toString().replace(",", " ")
            postCode = splitArr[2]?.split(" ")[splitArr[2]?.split(" ")?.length - 1]

            return {
                houseNumber: hNumber,
                floorBuildNumber: floorBuildNum,
                streetNumber: stNum,
                subTownName: subTownName,
                cityName: cityName,
                postCode: postCode
            }
        }
    }
    useEffect(() => {
        if (findAddressText?.trim()?.length > 0 && foundAddressList?.length > 0) {
            if (addContactVisible) {

                foundAddressList.map(item => {
                    if (item?.resource?.id == findAddressText) {
                        console.log("itemmm", item)
                        item?.resource?.extension?.map((address) => {
                            if (address?.valueAddress) {
                                let addressObj = calculateAddress(address?.valueAddress?.text)
                                debugger
                                if (address?.valueAddress?.district) {

                                    setEmContactSuburbTown(address?.valueAddress?.district)

                                    townArray?.length && townArray.map(item => {

                                        if (item?.resource?.title == address?.valueAddress?.district?.trim()) {
                                            setEmContactSuburbTownID(item?.resource.id)
                                        }
                                    })

                                }
                                if (address?.valueAddress?.city) {

                                    setEmContactCity(address?.valueAddress?.city)
                                    cityArray?.length && cityArray.map(item => {

                                        if (item?.resource?.extension[1]?.valueString == address?.valueAddress?.city?.trim()) {
                                            setEmContactCityID(item?.resource?.extension[0]?.valueString)
                                        }
                                    })
                                }
                                setEmContactFloorBuilding(addressObj?.floorBuildNumber)

                                setEmContactPostalCode(address?.valueAddress?.postalCode)

                            }
                            if (address.url === "StreetNumber") {
                                setEmContactStreet(address.valueString)

                            }
                            if (address.url === "HouseNumber") {
                                setEmContactHomeNumber(address?.valueString)

                            }
                        })
                    }
                })
            } else {
            foundAddressList.map(item => {
                if (item?.resource?.id == findAddressText) {
                    console.log("itemmm", item)
                    item?.resource?.extension?.map((address) => {
                        if (address?.valueAddress) {
                            let addressObj = calculateAddress(address?.valueAddress?.text)
                            setCountryEditAddressName(addressObj?.subTownName)

                            setCountryEditAddressID(townArray?.length ? townArray.map(item => {

                                if (item?.resource?.extension[1]?.valueString == addressObj?.subTownName?.trim()) {
                                    return item?.resource.extension[0].valueString
                                }
                            }) : null)
                            townArray?.length && townArray.map(item => {

                                if (item?.resource?.extension[1]?.valueString == addressObj?.subTownName?.trim()) {
                                    setCountryEditAddressID(item?.resource?.extension[0]?.valueString)
                                    setCountryEditAddressName(item?.resource?.extension[1]?.valueString)
                                }
                            })
                            setHouseNumEditAddress(addressObj?.houseNumber)
                            setFloorEditAddress(addressObj?.floorBuildNumber)

                            cityArray?.length && cityArray.map(item => {
                                if (item?.resource?.extension[1]?.valueString == addressObj?.cityName?.trim()) {
                                    setCityEditAddressName(item?.resource?.extension[1]?.valueString)
                                    setCityEditAddressID(item?.resource?.extension[0]?.valueString)

                                }
                            })
                            setPostCodeEditAddress(addressObj?.postCode)
                            setStreetEditAddress(addressObj?.streetNumber)

                        }
                    })
                }
            })

            }
        }
    }, [findAddressText, foundAddressList])

    const handlefindAddressTextSearch = debounce(((e) => {
        setFindAddressTextLoading(true)
        if (e?.length == 0) {
            setFindAddressText(null)
        } else {
            setFindAddressText(e)

        }
        flushSync(() => {
            setFoundAddressList([])
        })
        if (e?.trim()?.length >= 3) {
            getUserId().then((userInfo) => {
                const item = JSON.parse(userInfo);

                var myHeaders = new Headers();
                myHeaders.append("Token", item.token)
                myHeaders.append("Content-Type", "application/json")
                var raw = JSON.stringify({
                    SearchAddress: e?.trim()
                })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: raw
                };

                fetch(Settings.apiUrl + "eSAMAddressSearch", requestOptions)
                    .then(res => res.json())
                    .then(json => {
                        if (json.length && json[0]?.total > 0) {
                            setFoundAddressList(json[0]?.entry)
                            setNoAddressFoundFromSearch(false)
                        } else {

                            setFoundAddressList([])
                            if (typeof (json) === "string" && json.toLowerCase().includes("no address")) {
                                setNoAddressFoundFromSearch(true)
                            } else {
                                setNoAddressFoundFromSearch(false)

                            }
                        }
                        setFindAddressTextLoading(false)

                    })
            })
        } else {
            setFindAddressTextLoading(false)

        }

    }
    ), 2000)
    const handlefindAddressTextChange = (e) => {
        setFindAddressText(e)
    }
    const validateChangeEmailFields = () => {
        let errCaught = false
        if (changeEmail?.trim() == "") {
            setChangeEmailErrMsg("Email is required")
            errCaught = true
        }

        if (confirmChangeEmail?.trim() == "") {
            setConfirmChangeEmailErrMsg("Please repeat confirm the new email address")
            errCaught = true
        } else {
            if (confirmChangeEmail !== changeEmail) {
                setConfirmChangeEmailErrMsg("The email addresses do not match")
                errCaught = true
            }
        }
        return errCaught
    }
    const handleChangeEmailSubmit = () => {
        let isNotValid = validateChangeEmailFields()
        if (isNotValid) {
            return
        }

        setIsUpdateEmailModalOpen(false)

    }
    useEffect(() => {
        console.log("findAddressTextLoading", findAddressTextLoading)
    }, [findAddressTextLoading])
    useEffect(() => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientID: item.userid,
                TypeID: 2
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "IndiciLookup", requestOptions)
                .then(response => response.json())
                .then(result => {
                    let response = []
                    result[0].entry.map((item, index) => {
                        let tmp = {}
                        tmp.id = item.resource.extension[0].valueString
                        tmp.name = item.resource.extension[1].valueString
                        response.push(tmp)

                    })
                    // SetCountryList(response)
                    setGenderlist(response)
                })
                .catch(error => {});
        })
    }, [])
    const [gender, setGender] = useState({ id: 0, name: '' })
    

   
    const manageTrustedDevicesList = (removeDevice = 0) => {
        getUserId().then((userInfo) => {
            if (removeDevice) {
                setIsRemovingTrustedDevice(true)
            }
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");
            //    public string PatientUUID { get; set; }

            //     public string DeviceID { get; set; }

            //     public string IsFromApp { get; set; }

            //     public bool OperationID { get; set; } = false;  //0 for get and 1 for delete

            var raw = JSON.stringify({
                PatientUUID: item.userid,
                DeviceID: removeDevice === 1 ? removeDeviceID : localStorage.getItem("DeviceID"),
                IsFromApp: false,
                OperationID: removeDevice,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw,

            };
            fetch(Settings.apiUrl + "MFA/ManageTrustedDevices", requestOptions)
                .then(response => response.json())
                .then(json => {
                    setTrustedDeviceRemoveModalOpen(false)
                    setIsRemovingTrustedDevice(false)
                    setRemoveDeviceID("")
                    if (json?.DeviceDetails && json?.DeviceDetails?.length > 0) {
                        setTrustedDevicesList(json?.DeviceDetails)
                    } else {
                        setTrustedDevicesList([])
                    }

                })
                .catch(err => console.log(err))
        })

    }

    useEffect(() => {
        apiDispatcher(actionAPI.GetAccountBalanceAPI())

        apiDispatcher(actionAPI.GetPracticeOrganizationDataAPI())

        // 1 = Title
        // 2 = Gender
        // 3 = Martial Status
        // 4 = Ethnicity
        // 5 = Country
        // 6 = City
        // 7 = Suburb
        // 8 = Contact Method
        // 9 = Relationship
        // 10 = Blood Group
        // 11 = Role / Pods

        getLookUpdata(1)
        getLookUpdata(9)
        getLookUpdata(6)
        getLookUpdata(7)

        manageTrustedDevicesList()

    }, []);


    const getLookUpdata = async (type) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token;

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Cookie", ".Stackify.Rum=4415f100-6b95-4aa2-b4a1-28361d2b345c");

            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientID: item.userid,
                TypeID: type,
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "IndiciLookup", requestOptions)
                .then(response => response.json())
                .then(result => {
                    switch (type) {
                        case 1:
                            setTitleArray(result[0].entry)
                            // setEmContactTitleID(result[0].entry ? result[0].entry[0].resource.extension[0].valueString : "")
                            // setEmContactTitle(result[0].entry ? result[0].entry[0].resource.extension[1].valueString : "")
                            break;
                        case 9:
                            setRelationShipArray(result[0].entry)
                            // setEmContactRelationshipID(result[0].entry ? result[0].entry[0].resource.extension[0].valueString : "")
                            // setEmContactRelationship(result[0].entry ? result[0].entry[0].resource.extension[1].valueString : "")
                            break;
                        case 7:
                            setTownArray(result[0].entry)
                            // setEmContactSuburbTownID(result[0].entry ? result[0].entry[0].resource.extension[0].valueString : "")
                            // setEmContactSuburbTown(result[0].entry ? result[0].entry[0].resource.extension[1].valueString : "")
                            break;
                        case 6:
                            setCityArray(result[0].entry)
                            // setEmContactCityID(result[0].entry ? result[0].entry[0].resource.extension[0].valueString : "")
                            // setEmContactCity(result[0].entry ? result[0].entry[0].resource.extension[1].valueString : "")

                            break;


                        default:
                            break;
                    }
                })
                .catch(error => {});

        })
    }
    const [streetEditAddressErrMsg, setStreetEditAddressErrMsg] = useState("")
    const [countryEditAddressErrMsg, setCountryEditAddressErrMsg] = useState("")
    const [cityEditAddressFieldErrMsg, setCityEditAddressFieldErrMsg] = useState("")
    const [postCodeEditAddressErrMsg, setPostCodeEditAddressErrMsg] = useState("")
    const [countryEditPostalAddressNameErrMsg, setCountryEditPostalAddressNameErrMsg] = useState("")
    const [streetEditPostalAddressErrMsg, setStreetEditPostalAddressErrMsg] = useState("")
    const [cityEditPostalAddressNameErrMsg, setCityEditPostalAddressNameErrMsg] = useState("")
    const [postCodeEditPostalAddressErrMsg, setPostCodeEditPostalAddressErrMsg] = useState("")
    const [houseNumEditAddressErrMsg, setHouseNumEditAddressErrMsg] = useState("")
    const [houseNumEditPostalAddressErrMsg, setHouseNumEditPostalAddressErrMsg] = useState("")
    const isValidEditAddressData = () => {
        let isValid = true
        if (houseNumEditAddress == "") {
            setHouseNumEditAddressErrMsg("House no is required")
            isValid = false
        }
        if (streetEditAddress == "") {
            setStreetEditAddressErrMsg("Street is required")
            isValid = false
        }
        if (countryEditAddressID == "" && cityEditAddressName == "") {
            setCountryEditAddressErrMsg("Suburb/Town is required")
            isValid = false
        }
        if (cityEditAddressID == "" && cityEditAddressName == "") {
            setCityEditAddressFieldErrMsg("City is required")
            isValid = false
        }
        if (postCodeEditAddress == "") {
            setPostCodeEditAddressErrMsg("Postcode is required")
            isValid = false
        }
        if (!isSamePostalAddressEditAddress) {

            if (houseNumEditPostalAddress.trim() == "") {
                setHouseNumEditPostalAddressErrMsg("House no is required")
                isValid = false
            }
            if (cityEditPostalAddressName.trim() == "") {
                setCountryEditPostalAddressNameErrMsg("Suburb/Town is required")
                isValid = false
            }
            if (streetEditPostalAddress.trim() == "") {
                setStreetEditPostalAddressErrMsg("Street is required")
                isValid = false
            }
            if (cityEditPostalAddressName.trim() == "") {
                setCityEditPostalAddressNameErrMsg("City is required")
                isValid = false
            }
            if (postCodeEditPostalAddress.trim() == "") {
                setPostCodeEditPostalAddressErrMsg("Postal Code is required")
                isValid = false
            }
        }
        return isValid
    }
    const updateProfile = (callType = "profileUpdate") => {
        setLoading(true)
        

        getUserId().then((userInfo) => {
            const item = userInfo !== "none" && JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");
            var raw
            // "TitleID": profileTitleID,
            // "Title": profileTitle,
            // "FirstName": profileFirstName,
            // "FamilyName": profileFamilyName,
            // "PreferredName": profilePreferredName,
            // "Ethnicity": profileEthnicity,
            // "DOB": profileDOB,


            if (callType == "profileUpdate") {
                raw = JSON.stringify({
                    "PatientID": item.userid,
                    "ChartNumber": "string",
                    "GenderID": profileGender,
                    "GenderName": profileGender,
                    "BloodGroupID": profileBloodGroupID,
                    "BloodGroupName": profileBloodGroup,
                    "NHINumber": "N/A",
                    "UserEmail": profileEmail,
                    "PracticeID": 0,
                    "Balance": 0,
                    "RelationshipID": 0,
                    "RelationshipName": "string",
                    "InCaseOfEmergency": true
                });
            }
            else {
                if (!isValidEditAddressData()) {
                    return
                }
                raw = JSON.stringify({
                    PatientID: item.userid,
                    DataType: "",
                    CallType: 10,
                    profileAddress: {

                        PermanentAddress: {
                            AddressID: 0,
                            PatientID: item.userid,
                            AddressTypeID: 0,
                            HouseNumber: houseNumEditAddress,
                            BuildingNumber: "",
                            StreetNumber: streetEditAddress,
                            SuburbTownID: countryEditAddressID,
                            SuburbTownTitle: countryEditAddressName,
                            CityAreaID: cityEditAddressID,
                            CityAreaTitle: cityEditAddressName,
                            PostCodeID: postCodeEditAddress,
                            PostCode: postCodeEditAddress,
                            CountryID: 0,
                            CountryName: "",
                            FullAddress: "",
                            PostalAddress: "",
                            IseSAMValidated: false,
                            IsActive: true,
                            IsDeleted: false
                        },
                        PostalAddress: {
                            AddressID: isSamePostalAddressEditAddress ? -1 : 0,
                            PatientID: item.userid,
                            AddressTypeID: 0,
                            HouseNumber: houseNumEditPostalAddress,
                            BuildingNumber: "",
                            StreetNumber: streetEditPostalAddress,
                            SuburbTownID: countryEditPostalAddressID,
                            SuburbTownTitle: countryEditPostalAddressName,
                            CityAreaID: cityEditPostalAddressID,
                            CityAreaTitle: cityEditPostalAddressName,
                            PostCodeID: postCodeEditPostalAddress,
                            PostCode: postCodeEditPostalAddress,
                            CountryID: 0,
                            CountryName: "",
                            FullAddress: "",
                            PostalAddress: "",
                            IseSAMValidated: false,
                            IsActive: true,
                            IsDeleted: false
                        },
                    }
                })
            }


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };


            fetch(Settings.apiUrl + "Patient/UpdatePatientDemographics", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoading(false)
                    openNotificationWithIcon('success', "Profile update successfully.")
                    setAddressesModalVisible(false)

                })
                .catch(error => {
                    setAddressesModalVisible(false)

                    setLoading(false)
                    openNotificationWithIcon('error', "Profile issue update soon.")
                    //{}
                }).finally(() => {
                    apiDispatcher(actionAPI.GetUserProfileAPI())
                    setEditProfileModalVisible(false)
                })


        })
    }

    const deleteEmergencyContact = (id) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");

            const raw =  {
                PatientID: item.userid ,
                EmergencyContactID:id
            };
           
            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow',
                body:JSON.stringify(raw)
            };
            fetch(Settings.apiUrl + "Patient/EmergencyContact", requestOptions)
                .then(response => response.json())
                .then(result => {
                
                    setVisible(false)
                    setConfirmLoading(false);

                    apiDispatcher(actionAPI.GetUserProfileAPI())
                })
                .catch(error => { })



        })
    }

    const validateAddEmContactForm = () => {
        let isValid = true
        // if (emContactTitleID === "") {
        //     isValid = false
        //     setEmContactTitleinValidMsg("Title Cannot Be Empty")
        // } else {
        //     setEmContactTitleinValidMsg("")
        // }

        if (emContactFirstName === "") {
            isValid = false
            setEmContactFirstNameinValidMsg("Name cannot be Empty")
        } else {
            setEmContactFirstNameinValidMsg("")
        }

        if (emContactFamilyName === "") {
            isValid = false
            setEmContactFamilyNameinValidMsg("Family Name Cannot Be Empty")
        } else {
            setEmContactFamilyNameinValidMsg("")
        }

        if (emContactRelationship === "") {
            isValid = false
            setEmContactRelationshipinValidMsg("Relationship cannot be Empty")
        } else {
            setEmContactRelationshipinValidMsg("")
        }
        if (emContactHomePhone === "") {
            isValid = false
            setEmContactHomePhoneinValidState("Home Phone Cannot Be Empty")
        } else {
            if (!isValidPhoneNumber(emContactHomePhone)) {
                isValid = false
                setEmContactHomePhoneinValidState("Please enter a valid Phone Number")
            } else {
                setEmContactHomePhoneinValidState("")
            }
        }
        if (emContactWorkPhone !== "" && !isValidPhoneNumber(emContactWorkPhone)) {
            isValid = false
            setEmContactWorkPhoneinValidState("Please enter a valid Phone Number")
        } else {
            setEmContactWorkPhoneinValidState("")
        }


        return isValid
    }
    const selectedContactMethodsListing = () => {
        let tempArr = []
        let tempObj = {
            "ContactMethod": "",
            "ContactMethodID": 0,
            "InsertedAt": {
                "Ticks": 0,
                "Year": 0,
                "Month": 0,
                "Day": 0,
                "Hour": 0,
                "Minute": 0,
                "Second": 0,
            },
            "InsertedBY": 0,
            "IsActive": true,
            "IsDeleted": true,
            "ProfileContactMethodID": 0,
            "ProfileID": 0,
            "UpdatedAt": {
                "Ticks": 0,
                "Year": 0,
                "Month": 0,
                "Day": 0,
                "Hour": 0,
                "Minute": 0,
                "Second": 0,
            },
            "UpdatedBY": 0,
        }
        if (cmCellPhoneChecked) {
            tempArr.push({
                "ContactMethod": "Cell Phone",
                "ContactMethodID": 1,
                "InsertedAt": {
                    "Ticks": 0,
                    "Year": 0,
                    "Month": 0,
                    "Day": 0,
                    "Hour": 0,
                    "Minute": 0,
                    "Second": 0,
                },
                "InsertedBY": 0,
                "IsActive": true,
                "IsDeleted": true,
                "ProfileContactMethodID": 0,
                "ProfileID": 0,
                "UpdatedAt": {
                    "Ticks": 0,
                    "Year": 0,
                    "Month": 0,
                    "Day": 0,
                    "Hour": 0,
                    "Minute": 0,
                    "Second": 0,
                },
                "UpdatedBY": 0,
            })
        }
        if (cmDayPhoneChecked) {
            tempArr.push({
                "ContactMethod": "Day Phone",
                "ContactMethodID": 2,
                "InsertedAt": {
                    "Ticks": 0,
                    "Year": 0,
                    "Month": 0,
                    "Day": 0,
                    "Hour": 0,
                    "Minute": 0,
                    "Second": 0,
                },
                "InsertedBY": 0,
                "IsActive": true,
                "IsDeleted": true,
                "ProfileContactMethodID": 0,
                "ProfileID": 0,
                "UpdatedAt": {
                    "Ticks": 0,
                    "Year": 0,
                    "Month": 0,
                    "Day": 0,
                    "Hour": 0,
                    "Minute": 0,
                    "Second": 0,
                },
                "UpdatedBY": 0,
            })
        }
        if (cmNightPhoneChecked) {
            tempArr.push({
                "ContactMethod": "Night Phone",
                "ContactMethodID": 3,
                "InsertedAt": {
                    "Ticks": 0,
                    "Year": 0,
                    "Month": 0,
                    "Day": 0,
                    "Hour": 0,
                    "Minute": 0,
                    "Second": 0,
                },
                "InsertedBY": 0,
                "IsActive": true,
                "IsDeleted": true,
                "ProfileContactMethodID": 0,
                "ProfileID": 0,
                "UpdatedAt": {
                    "Ticks": 0,
                    "Year": 0,
                    "Month": 0,
                    "Day": 0,
                    "Hour": 0,
                    "Minute": 0,
                    "Second": 0,
                },
                "UpdatedBY": 0,
            })
        }

        if (cmEmailChecked) {
            tempArr.push({
                "ContactMethod": "Email",
                "ContactMethodID": 4,
                "InsertedAt": {
                    "Ticks": 0,
                    "Year": 0,
                    "Month": 0,
                    "Day": 0,
                    "Hour": 0,
                    "Minute": 0,
                    "Second": 0,
                },
                "InsertedBY": 0,
                "IsActive": true,
                "IsDeleted": true,
                "ProfileContactMethodID": 0,
                "ProfileID": 0,
                "UpdatedAt": {
                    "Ticks": 0,
                    "Year": 0,
                    "Month": 0,
                    "Day": 0,
                    "Hour": 0,
                    "Minute": 0,
                    "Second": 0,
                },
                "UpdatedBY": 0,
            })
        }
        if (cmTextChecked) {
            tempArr.push({
                "ContactMethod": "Text",
                "ContactMethodID": 5,
                "InsertedAt": {
                    "Ticks": 0,
                    "Year": 0,
                    "Month": 0,
                    "Day": 0,
                    "Hour": 0,
                    "Minute": 0,
                    "Second": 0,
                },
                "InsertedBY": 0,
                "IsActive": true,
                "IsDeleted": true,
                "ProfileContactMethodID": 0,
                "ProfileID": 0,
                "UpdatedAt": {
                    "Ticks": 0,
                    "Year": 0,
                    "Month": 0,
                    "Day": 0,
                    "Hour": 0,
                    "Minute": 0,
                    "Second": 0,
                },
                "UpdatedBY": 0,
            })
        }
        if (cmPostChecked) {
            tempArr.push({
                "ContactMethod": "Post",
                "ContactMethodID": 6,
                "InsertedAt": {
                    "Ticks": 0,
                    "Year": 0,
                    "Month": 0,
                    "Day": 0,
                    "Hour": 0,
                    "Minute": 0,
                    "Second": 0,
                },
                "InsertedBY": 0,
                "IsActive": true,
                "IsDeleted": true,
                "ProfileContactMethodID": 0,
                "ProfileID": 0,
                "UpdatedAt": {
                    "Ticks": 0,
                    "Year": 0,
                    "Month": 0,
                    "Day": 0,
                    "Hour": 0,
                    "Minute": 0,
                    "Second": 0,
                },
                "UpdatedBY": 0,
            })
        }
        setSelectedContactMethods(tempArr)
        return tempArr
    }
    const saveApi = (nokID = 0) => {
       
        const isValid = validateAddEmContactForm()
        let selectedCM = selectedContactMethodsListing()
       
        if (!isValid) {
            return
        }
        setIsAddingEmergency(true)
        let titleName = ""
        if (titleArray?.length > 0) {
            titleName = titleArray.filter(title => title.resource.id === emContactTitleID)[0].resource.title
        }
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo)

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Cookie", ".Stackify.Rum=6b3115ba-187b-4481-94fa-38d0c9542fff");

            var raw = JSON.stringify({
                "PatientID": item.userid,
                "NokProfileID": isEmergencyContactUpdating ? parseInt(nokID) : 0,
                "ChartNumber": "SSW3",
                "TitleID": emContactTitleID,
                "Title": emContactTitle?.trim() !== "" ? emContactTitle : titleName,
                "FirstName": emContactFirstName,
                "FamilyName": emContactFamilyName,
                "PreferredName": "NickName",
                "GenderID": 2,
                "GenderName": "Female",
                "Ethnicity": "x ethnicity",
                "DOB": "2021-07-14T19:32:42.274Z",
                "BloodGroupID": 2,
                "BloodGroupName": "b+",
                "NHINumber": "nhi441",
                "UserEmail": "myemail@address.com",
                "PracticeID": 128,
                "Balance": 200,
                "RelationshipID": emContactRelationshipID,
                "RelationshipName": emContactRelationship,
                "InCaseOfEmergency": true,
                "PatientAddress": [
                    {
                        "AddressID": isEmergencyContactUpdating ? nokAddressID : 0,
                        "PaientID": item.userid,
                        "AddressTypeID": 1,
                        "HouseNumber": emContactHomeNumber,
                        "BuildingNumber": emContactFloorBuilding,
                        "StreetNumber": emContactStreet,
                        "SuburbTownName": emContactSuburbTown,
                        "SuburbTownID": emContactSuburbTownID,
                        "CityAreaName": emContactCity,
                        "CityAreaID": emContactCityID,
                        "PostCodeID": emContactPostalCode,
                    }
                ],
                "PatientContact": {
                    "CellPhoneNumber": emContactHomePhone,
                    "HomePhoneNumber": emContactResidence,
                    "WorkPhoneNumber": emContactWorkPhone,
                    "EmailAddress": "email@eee.com",
                    "PrefferedContactMethodID": 2,
                    "PrefferedContactMethodName": "string"
                },
                "lstContactMethods": selectedCM,
                "PreferredContactMethodID": preferredCmID,
                "OperationID": isEmergencyContactUpdating ? true : false,
                "AddressID": isEmergencyContactUpdating ? nokAddressID : null
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(Settings.apiUrl + "patient/AddEmergencyContact?PatientID=" + item.userid, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setIsAddingEmergency(false)

                    apiDispatcher(actionAPI.GetUserProfileAPI())
                    setAddContactVisible(false)
                })
                .catch(error => {

                    setIsAddingEmergency(false)
                    setAddContactVisible(false)
                    //{}
                });
        })

    }

    const handleCloseReset = () => {
        setChangePass("")
        setChangeConfirmPass("")
        setShowReset(false)
        // testFunction(AuthUser[0].extension[0].valueString, AuthUser[0].id)

    };

    const [showincon, setshowicon] = useState('mdi mdi-eye')
    const showPassword = () => {
        var x = document.getElementById("password");
        if (x.type === "password") {
            setshowicon('mdi mdi-eye-off')
            x.type = "text";
        } else {
            setshowicon('mdi mdi-eye')
            x.type = "password";
        }
    }

    const [showinconConf, setshowConficon] = useState('mdi mdi-eye')

    const showPasswordConfirm = () => {
        var x = document.getElementById("confirmPassword");
        if (x.type === "password") {
            setshowConficon('mdi mdi-eye-off')
            x.type = "text";
        } else {
            setshowConficon('mdi mdi-eye')
            x.type = "password";
        }
    }
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: 'Notification',
            description:
                message,
        });
    };

    const resetPasswordFunc = async () => {


        const passwordError = passwordValidator(changePass);
        const cnfrmPaswrd = passwordValidator(changeConfirmPass);
        if (passwordError) {
            setChangePassErrMsg("Password cannot be empty ")
            return;
        }
        if (cnfrmPaswrd) {
            setChangePassErrMsg("Confirm Password cannot be empty ")
            return;
        }
        if (changePass !== changeConfirmPass) {
            setChangePassErrMsg("Passwords did not match. Please try again")
            return
        }
        //check for lower case
        if (!changePass.match(/[a-z]/)) {
            setChangePassErrMsg("Password must contain at least one lower case letter.");
            // passForm.passInput.focus();
            return;
        }

        //Validating length
        if (changePass.length < 8) {
            setChangePassErrMsg("Your password has less than 8 characters.");
            return;
        }

        //check for upper ase
        if (!changePass.match(/[A-Z]/)) {
            setChangePassErrMsg("Password must contain at least one upper case letter.");
            // passForm.passInput.focus();
            return;
        }
        var patt = new RegExp("^(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){2,}).{8,}$");
        //check for number
        if (!patt.test(changePass)) {
            setChangePassErrMsg("Password must contain at least two number.");
            // passForm.passInput.focus();
            return;
        }
        setIsResetPassword(true)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '';
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");
            var jsonPa = JSON.stringify({ "PatientID": item.parentid, "Password": changePass })
            var requestOptions = {
                method: 'Post',
                headers: myHeaders,
                body: jsonPa,
                redirect: 'follow'
            };
            //changePasswrodService(queryString, jsonPa,myHeaders)

            fetch(Settings.apiUrl + "/Patient/ChangePassword", requestOptions)
                .then(data => data.json())
                .then(resJson => {
                    // {"StatusCode":1,"StatusMessage":"Password updated successfully."}
                    if (resJson.StatusCode == "1") {
                        // for activity indicatorr
                        setIsResetPassword(false)
                        setPasswordStartMessage(prevState => ({ ...prevState, error: false, message: resJson.StatusMessage }))
                        // alert(resJson.StatusMessage)
                        openNotificationWithIcon('success', resJson.StatusMessage)
                        handleCloseReset()

                        // handleCloseReset()
                    }
                    // {"StatusCode":3,"StatusMessage":"three time password error try to some differect passsword"}
                    if (resJson.StatusCode == "3") {
                        setPasswordStartMessage(prevState => ({ ...prevState, error: true, message: resJson.StatusMessage }))
                        setIsResetPassword(false)
                        openNotificationWithIcon('error', resJson.StatusMessage)

                    }
                    if (resJson.StatusCode == "4") {
                        setPasswordStartMessage(prevState => ({ ...prevState, error: true, message: resJson.StatusMessage }))
                        setIsResetPassword(false)
                        openNotificationWithIcon('error', resJson.StatusMessage)

                    }
                })
                .catch(err => {

                    setPasswordStartMessage(prevState => ({ ...prevState, error: true, message: "Some thing went wrong" }))

                })
                .finally(() => {
                    setIsResetPassword(false)

                })
        })
        // check remining confirm and passweordk

    }

    /////////////////////

    const [workphone, setWorkPhone] = useState("");
    const [homephone, setHomePhone] = useState("");
    const [workPhoneInvalidState, setWorkPhoneInvalidState] = useState("");
    const [homePhoneInvalidState, setHomePhoneInvalidState] = useState("");
    const [workPhoneInvalidMsg, setWorkPhoneInvalidMsg] = useState("");
    const [homePhoneInvalidMsg, setHomePhoneInvalidMsg] = useState("");
    const [cellPhone, setCellPhone] = useState("");
    const [cellPhoneInvalidState, setCellPhoneInvalidState] = useState("");
    const [cellPhoneInvalidMsg, setCellPhoneInvalidMsg] = useState("");
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState();//email
    const [workupdated, setWorkUpdateed] = useState(false);
    const [cellUpdeated, setCellUpdeated] = useState(false);
    const [homeUpdated, setHomeUpdated] = useState(false);

    const Contact = () => {
        let cell;
        let home;
        let work;
        let cell1;
        let home1;
        let work1;
        let email;

        Profile.forEach(person => {
            person.resource.telecom.forEach(element => {
                if (element.system == 'phone') {
                    if (element.use == 'home') {
                       
                        home1 = element.value
                        setHomePhone(element.value)
                       
                    }
                    if (element.use == 'mobile') {
                       
                        cell1 = element.value
                        setCellPhone(element.value)
                        //  alert(cell)
                    }
                    if (element.use == 'work') {
                     
                        work1 = element.value
                        setWorkPhone(element.value)
                        //alert(work)
                    }
                }
                else if (element.system == 'email') {
                    if (element.use == 'home') {
                        email = element.value
                        setEmail(element.value)
                    }
                }


            });
        })
        setContactModalVisible(true)
    }
    const validateContactFormFields = () => {
        let errCaught = false
        if (cellPhone === "") {
            setCellPhoneInvalidState("Cell Phone Cannot Be Empty")
            errCaught = true
        }
        else if (cellPhoneInvalidMsg?.toLowerCase().includes("invalid")) {
            setCellPhoneInvalidState("Invalid Cell Phone Number")
            errCaught = true
        }

        if (workphone !== "" && workPhoneInvalidMsg?.toLowerCase().includes("invalid")) {
            setWorkPhoneInvalidState("Invalid Work Phone Number")
            errCaught = true
        }
        if (homephone !== "" && homePhoneInvalidMsg?.toLowerCase().includes("invalid")) {
            homePhoneInvalidState("Invalid Work Phone Number")
            errCaught = true
        }

        return errCaught
    }

    const ContactUpdateHandler = () => {
        if (validateContactFormFields()) {
            return
        }

        setLoading(true)
        selectedContactMethodsListing()
        getUserId().then((userInfo) => {
            const item = userInfo !== "none" && JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "PatientID": item.userid,
                "CellPhoneNumber": cellPhone,
                "HomePhoneNumber": homephone,
                "WorkPhoneNumber": workphone,
                "EmailAddress": document.getElementById("mEmail").value,
                "PrefferedContactMethodID": preferredCmID,
                "PrefferedContactMethodName": preferredCmName,
                "SelectedContacttMethods": [
                    {
                        "ProfileContactMethodID": 1,
                        "ProfileID": 0,
                        "ContactMethodID": 1,
                        "IsActive": cmCellPhoneChecked,
                        "IsDeleted": false,
                        "InsertedBy": 0,
                        "UpdatedBy": 0,
                        "InsertedAt": "2021-07-29T07:50:52.694Z",
                        "UpdatedAt": "2021-07-29T07:50:52.694Z"
                    },
                    {
                        "ProfileContactMethodID": 2,
                        "ProfileID": 0,
                        "ContactMethodID": 2,
                        "IsActive": cmDayPhoneChecked,
                        "IsDeleted": false,
                        "InsertedBy": 0,
                        "UpdatedBy": 0,
                        "InsertedAt": "2021-07-29T07:50:52.694Z",
                        "UpdatedAt": "2021-07-29T07:50:52.694Z"
                    },
                    {
                        "ProfileContactMethodID": 3,
                        "ProfileID": 0,
                        "ContactMethodID": 3,
                        "IsActive": cmNightPhoneChecked,
                        "IsDeleted": false,
                        "InsertedBy": 0,
                        "UpdatedBy": 0,
                        "InsertedAt": "2021-07-29T07:50:52.694Z",
                        "UpdatedAt": "2021-07-29T07:50:52.694Z"
                    },
                    {
                        "ProfileContactMethodID": 4,
                        "ProfileID": 0,
                        "ContactMethodID": 4,
                        "IsActive": cmEmailChecked,
                        "IsDeleted": false,
                        "InsertedBy": 0,
                        "UpdatedBy": 0,
                        "InsertedAt": "2021-07-29T07:50:52.694Z",
                        "UpdatedAt": "2021-07-29T07:50:52.694Z"
                    },
                    {
                        "ProfileContactMethodID": 5,
                        "ProfileID": 0,
                        "ContactMethodID": 5,
                        "IsActive": cmTextChecked,
                        "IsDeleted": false,
                        "InsertedBy": 0,
                        "UpdatedBy": 0,
                        "InsertedAt": "2021-07-29T07:50:52.694Z",
                        "UpdatedAt": "2021-07-29T07:50:52.694Z"
                    },
                    {
                        "ProfileContactMethodID": 6,
                        "ProfileID": 0,
                        "ContactMethodID": 6,
                        "IsActive": cmPostChecked,
                        "IsDeleted": false,
                        "InsertedBy": 0,
                        "UpdatedBy": 0,
                        "InsertedAt": "2021-07-29T07:50:52.694Z",
                        "UpdatedAt": "2021-07-29T07:50:52.694Z"
                    }
                ].filter(item => item.IsActive),
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/UpdatePatientContactMethods", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoading(false)
                    apiDispatcher(actionAPI.GetUserProfileAPI())
                    setContactModalVisible(false)
                    // window.location.reload()
                })
                .catch(error => {
                    setLoading(false)
                    //{}
                });
        })


    }

    const handleOk = () => {
        // setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        deleteEmergencyContact(contactid)
        // setTimeout(() => {

        //   setConfirmLoading(false);
        // }, 2000);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    const openModalPorfileUpdate = () => {
        genderlist.map((item, index) => {
            if (Profile[0].resource.gender.toLowerCase() == item.name.toLowerCase()) {
                setGender(item)
            }
        })

        setEditProfileModalVisible(true)
    }


    const resetEmContactFormFields = () => {
        setEmContactFirstName("")
        setEmContactFamilyName("")
        setEmContactFloorBuilding("")
        setEmContactHomePhone("")
        setEmContactHomePhoneinValidMsg("")
        setEmContactHomePhoneinValidState("")
        setEmContactHomeNumber("")
        setEmContactWorkPhone("")
        setEmContactWorkPhoneinValidMsg("")
        setEmContactWorkPhoneinValidState("")
        setEmContactPostalCode("")
        setEmContactResidence("")
        setEmContactStreet("")
        setContactId("")
        // setEmContactRelationshipID(relationShipArray ? relationShipArray[0].resource.extension[0].valueString : "")
        // setEmContactRelationship(relationShipArray ? relationShipArray[0].resource.extension[1].valueString : "")
        setEmContactRelationshipID("")
        setEmContactRelationship("")

        // setEmContactTitleID(titleArray ? titleArray[0].resource.extension[0].valueString : "")
        // setEmContactTitle(titleArray ? titleArray[0].resource.extension[1].valueString : "")

        setEmContactTitleID("")
        setEmContactTitle("")

        // setEmContactCityID(cityArray ? cityArray[0].resource.extension[0].valueString : "")
        // setEmContactCity(cityArray ? cityArray[0].resource.extension[1].valueString : "")
        setEmContactCityID("")
        setEmContactCity("Select")

        // setEmContactSuburbTownID(townArray ? townArray[0].resource.extension[0].valueString : "")
        // setEmContactSuburbTown(townArray ? townArray[0].resource.extension[1].valueString : "")
        setEmContactSuburbTownID("")
        setEmContactSuburbTown("Select")
    }
    const resetEmContactValidationFields = () => {
        setEmContactFirstNameinValidMsg("")
        setEmContactFamilyNameinValidMsg("")
        setEmContactWorkPhoneinValidMsg("")
        setEmContactHomePhoneinValidMsg("")
    }

    const initializePersonalData = () => {
        if (titleArray.length > 0) {
            setProfileTitleID(titleArray[0]?.resource?.id)
        }
        setProfileTitle(Profile[0]?.resource?.name[0]?.prefix ? Profile[0]?.resource?.name[0]?.prefix[0] : "")
        setProfileFirstName(Profile[0].resource.name[0].given[0])
        setProfileFamilyName(Profile[0].resource.name[0].family)
        setProfilePreferredName(Profile[0].resource.name[0].text)
        setProfileGender(capitalize(Profile[0].resource.gender))
        setProfileEthnicity(Profile[0].resource.extension[1].valueString)
        // setProfileDOB(Profile[0].resource.birthDate)
        setProfileDOB(GetAppointmentDate(Profile[0].resource.birthDate) + "-" + GetMonthName(Profile[0].resource.birthDate) + "-" + GetYear(Profile[0].resource.birthDate))
        Profile[0]?.resource?.telecom?.map((item, index) => {
            item.system === "email" ? setProfileEmail(item.value) : setProfileEmail("")
        })
        setProfileBloodGroup(Profile[0].resource.extension[4].valueString)
        setProfileBloodGroupID(Profile[0].resource.extension[5].valueString)

    }
    const initializeEditAddressData = () => {
        if (Profile.length > 0) {
            Profile[0]?.resource?.address?.map(address => {
                if (address?.type === "physical") {

                    let calculated = calculateAddress(address?.text)
                    setPostCodeEditAddress(calculated.postCode)
                    // setCountryEditAddressName(calculated.subTownName)
                    setStreetEditAddress(calculated.streetNumber)
                    setHouseNumEditAddress(calculated.houseNumber)
                    console.log(calculated, townArray.resource?.extension[1]?.valueString)
                    townArray?.length && townArray.map(item => {

                        if (item?.resource?.extension[1]?.valueString == calculated?.subTownName?.trim()) {
                            setCountryEditAddressID(item?.resource?.extension[0]?.valueString)
                            setCountryEditAddressName(item?.resource?.extension[1]?.valueString)
                        }
                    })
                    cityArray?.length && cityArray.map(item => {
                        if (item?.resource?.extension[1]?.valueString == calculated?.cityName?.trim()) {
                            setCityEditAddressName(item?.resource?.extension[1]?.valueString)
                            setCityEditAddressID(item?.resource?.extension[0]?.valueString)

                        }
                    })
                }
            })
        }
    }
    const getEmergencyContactDetailsFromAPI = (contactID) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);


            var myHeaders = new Headers();
            myHeaders.append("token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify({
                    PatientID: item.userid,
                    NOKProfileId: contactID
                })
            };

            fetch(Settings.apiUrl + "Patient/GetEmergencyContactDetail", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result && !addContactVisible) {
                        const titleID = result?.lstNexttoKin[0]?.TitleID
                        if (titleID) {
                            setEmContactTitleID(titleID.toString())
                            const titleData = titleArray.filter(item => parseInt(item.resource?.id) === titleID)
                            if (titleData && titleData.length > 0) {
                                setEmContactTitle(titleData[0]?.resource?.title)
                            }

                        }
                        const cityID = result?.lstNexttoKin[0]?.CityAreaID
                        if (cityID) {
                            setEmContactCityID(cityID.toString())
                            const cityData = cityArray.filter(item => item?.resource?.id === cityID.toString())
                            if (cityData && cityData.length > 0) {
                                setEmContactCity(cityData[0]?.resource?.title)
                            }
                        }

                        const suburbID = result?.lstNexttoKin[0]?.SuburbTownID
                        if (suburbID) {
                            setEmContactSuburbTownID(suburbID.toString())
                            const suburbData = townArray.filter(item => item?.resource?.id === suburbID.toString())
                            if (suburbData && suburbData.length > 0) {
                                setEmContactSuburbTown(suburbData[0]?.resource?.title)
                            }
                        }
                        setNokAddressID(result?.lstNexttoKin[0]?.AddressID)
                        setEmContactRelationshipID(result?.lstNexttoKin[0]?.RelationshipTypeID?.toString())
                        setEmContactRelationship(result?.lstNexttoKin[0]?.RelationshipType)

                        setEmContactFirstName(result?.lstNexttoKin[0]?.FirstName)
                        setEmContactFamilyName(result?.lstNexttoKin[0]?.FamilyName)
                        setEmContactHomePhone((result?.lstNexttoKin[0]?.CellNumber ? result?.lstNexttoKin[0]?.CellNumber : ""))
                        setEmContactHomeNumber(result?.lstNexttoKin[0]?.HouseNumber)
                        setEmContactWorkPhone(result?.lstNexttoKin[0]?.DayPhone ? result?.lstNexttoKin[0]?.DayPhone : "")
                        setEmContactResidence(result?.lstNexttoKin[0]?.ResidencePhoneNo ? result?.lstNexttoKin[0]?.ResidencePhoneNo : "")
                        setEmContactStreet(result?.lstNexttoKin[0]?.StreetNumber)
                        setEmContactFloorBuilding(result?.lstNexttoKin[0]?.BuildingNumber)
                        setEmContactPostalCode(result?.lstNexttoKin[0]?.PostCodeID)
                        setIsEmergencyContactUpdating(true)
                        setAddContactVisible(true)
                    }
                    console.log("resulttttttt", JSON.parse(result))
                })
                .catch(error => {

                    // alert("Check your network connection.")
                });
        })
    }
    useEffect(() => {
        if (Profile.length > 0) {
            let contactMethods = JSON.parse(Profile[0].resource.extension[6].valueString)

            const prefCMID = parseInt(Profile[0]?.resource?.extension[13]?.valueString)

            if (contactMethods.length >= 1) {
                contactMethods.map(item => {
                    if (item.ContactMethodID === 1) {
                        setCmCellPhoneChecked(true);
                        if (prefCMID && prefCMID == item.ContactMethodID) {
                            setPreferredCmID(prefCMID)
                        }

                    }
                    else if (item.ContactMethodID === 2) {
                        setCmDayPhoneChecked(true)
                        if (prefCMID && prefCMID == item.ContactMethodID) {
                            setPreferredCmID(prefCMID)

                        }

                    }
                    else if (item.ContactMethodID === 3) {
                        setCmNightPhoneChecked(true)
                        if (prefCMID && prefCMID == item.ContactMethodID) {
                            setPreferredCmID(prefCMID)

                        }

                    }
                    else if (item.ContactMethodID === 4) {
                        setCmEmailChecked(true)
                        if (prefCMID && prefCMID == item.ContactMethodID) {
                            setPreferredCmID(prefCMID)

                        }

                    }
                    else if (item.ContactMethodID === 5) {
                        setCmTextChecked(true)
                        if (prefCMID && prefCMID == item.ContactMethodID) {
                            setPreferredCmID(prefCMID)

                        }
                    }
                    else if (item.ContactMethodID === 6) {
                        setCmPostChecked(true)
                        if (prefCMID && prefCMID == item.ContactMethodID) {
                            setPreferredCmID(prefCMID)

                        }

                    }
                })

            }

            initializePersonalData()
            initializeEditAddressData()
        }
    }, [Profile, titleArray, townArray])
    //////////
    const [imageFiles, setImageFiles] = useState([])
    const [avatarEditorModalOpen, setAvatarEditorModalOpen] = useState(false)

    const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;
    const selectFilesHandler = (e) => {
        const { files } = e.target;
        const validImageFiles = [];
        let sizeLimitExceed = false
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size > 4000000) {
                sizeLimitExceed = true
            }
            if (file.type.match(imageTypeRegex)) {
                validImageFiles.push(file);
            }
        }
        if (sizeLimitExceed) {
            openNotificationWithIcon("error", "File size cannot be greater than 4MB")
            return
        }
        if (validImageFiles.length) {
            setImageFiles(validImageFiles);
            setAvatarEditorModalOpen(true)
            e.target.value = null;
            return;
        }
        alert("Selected images are not of valid type!");
    };
    const editor = useRef(null);
    const loadImageAPI = () => {
        let PatientPictureUID = Profile[0]?.resource?.extension.find(o => o.url === "PatientPictureUID")?.valueString

        if (PatientPictureUID && PatientPictureUID !== "") {

            getUserId().then((userInfo) => {
                const item = JSON.parse(userInfo);
                const queryString = '?Token=' + item.token


                var myHeaders = new Headers();
                myHeaders.append("token", item.token);
                myHeaders.append("Content-Type", "application/json");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify({
                        PatientID: item.userid,
                        DocumentKey: PatientPictureUID, requestOptions
                    })
                };

                fetch(Settings.apiUrl + "Patient/LoadImage", requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result && result != "") {

                            setPatientImageData(result)
                        }
                    })
                    .catch(error => {

                        // alert("Check your network connection.")
                    });
            })
        }
    }
    useEffect(() => {
        if (Profile && Profile.length > 0) {
            loadImageAPI()
        }

    }, [Profile])
    const inputFile = useRef(null)
    const handleProfilePictureClick = () => {
        if (appSettings && appSettings.PatientcanUpdatePic) {
            inputFile.current.click()
        }
    }
    useEffect(() => {

        GetAppSettings().then((info) => {

            const set = JSON.parse(info)
            setAppSettings(set)
        })
    }, [])
    return (

        <div className="page-content-wrapper-inner">
            <div className="viewport-header">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb has-arrow">
                        <li className="breadcrumb-item">
                            <Link to="/">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">User Profile</li>
                    </ol>
                </nav>
            </div>
            <div className="content-viewport">

                <div className="letter-and-documentation-container">
                    <div className="grid mb-0">
                        <div className="grid-body">
                            <div className="row no-gutters">
                                <div className="col col-12 col-md-4 mb-3 pr-2">
                                    <div className="letter-profile-container my-2">

                                        <div className="letter-image-container" onClick={() => handleProfilePictureClick()}>
                                            <div className='profile-screen-picon' id="profileImage"  >
                                                {typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                                    patientImageData != "" ? <img onClick={() => handleProfilePictureClick()} src={"data:application/jpg;base64," + patientImageData} /> :
                                                    <span>{Profile[0]?.resource?.name[0]?.text?.charAt(0)}</span>
                                                ) : null}
                                                {appSettings && appSettings.PatientcanUpdatePic &&
                                                <div className='uploadProfileSVG' onClick={() => handleProfilePictureClick()}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={20} height={20}><path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg>
                                                    </div>}
                                                <input
                                                    autoComplete="off"
                                                    type="file"
                                                    id="getfile2"
                                                    ref={inputFile}
                                                    onChange={selectFilesHandler}
                                                    accept="image/png, image/jpg, image/jpeg"

                                                />
                                            </div>


                                            {/* {
                                                typeof Profile !== "undefined" && Profile.length && Profile[0].resource.gender.toLowerCase() !== "female" ? (

                                                    <Svg
                                                        height={50}
                                                        viewBox="0 0 512 512"
                                                        width={50}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        {...props}
                                                    >
                                                        <Path
                                                            d="M256.025 483.334l101.429-25.614c57.895-48.074 94.771-120.586 94.771-201.719 0-125.144-87.711-229.801-205.012-255.852C109.897 4.78 0 117.556 0 256c0 71.112 29 135.446 75.812 181.836z"
                                                            fill="#cbe2ff"
                                                        />
                                                        <Path
                                                            d="M446.914 256c0 83.915-40.381 158.391-102.765 205.079l92.031-23.241C482.995 391.448 512 327.114 512 256 512 114.615 397.385 0 256 0c-11.024 0-21.886.698-32.543 2.05C349.476 18.038 446.914 125.64 446.914 256z"
                                                            fill="#bed8fb"
                                                        />
                                                        <Path
                                                            d="M319.621 96.952c0-13.075-10.599-23.674-23.674-23.674h-81.582c-30.091 0-54.485 24.394-54.485 54.485v60.493h192.209v-59.635c0-13.075-10.599-23.674-23.674-23.674h-.798a7.995 7.995 0 01-7.996-7.995z"
                                                            fill="#365e7d"
                                                        />
                                                        <Path
                                                            d="M328.415 104.947h-.798a7.996 7.996 0 01-7.996-7.996c0-13.075-10.599-23.674-23.674-23.674h-8.945v114.978h65.086V128.62c.001-13.073-10.599-23.673-23.673-23.673z"
                                                            fill="#2b4d66"
                                                        />
                                                        <Path
                                                            d="M425.045 372.355a56.757 56.757 0 00-22.79-13.745l-69.891-22.128-76.348-2.683-76.38 2.683-69.891 22.128c-23.644 7.486-39.713 29.428-39.713 54.229v19.094c44.789 47.328 107.451 77.568 177.183 79.92 78.128-17.353 143.129-69.576 177.83-139.498z"
                                                            fill="#4a80aa"
                                                        />
                                                        <Path
                                                            d="M441.968 431.932v-19.094c0-17.536-8.04-33.635-21.105-44.213-37.111 75.626-110.422 130.268-197.346 141.317a258.334 258.334 0 0032.026 2.057 273.717 273.717 0 0030.824-1.782c61.031-7.212 115.485-35.894 155.601-78.285z"
                                                            fill="#407093"
                                                        />
                                                        <Path
                                                            d="M261.796 508.168c15.489-30.751 55.822-118.067 44.321-172.609l-50.101-19.499-50.148 19.5c-11.856 56.225 31.37 147.277 45.681 175.29a259.242 259.242 0 0010.247-2.682z"
                                                            fill="#e4f6ff"
                                                        />
                                                        <Path
                                                            d="M288.197 483.789l-20.314-79.917h-23.767l-20.264 79.699 25.058 27.897a260.054 260.054 0 0018.81-5.057z"
                                                            fill="#e28086"
                                                        />
                                                        <Path
                                                            d="M249.302 511.905c2.075.054 4.154.091 6.241.095 2.415-.004 4.822-.046 7.222-.113l12.907-14.259a254.021 254.021 0 01-31.309 8.779z"
                                                            fill="#dd636e"
                                                        />
                                                        <Path
                                                            d="M298.774 328.183v-45.066h-85.58v45.066c0 23.632 42.79 49.446 42.79 49.446s42.79-25.814 42.79-49.446z"
                                                            fill="#ffddce"
                                                        />
                                                        <Path
                                                            d="M352.089 180.318H335.73c-9.098 0-16.473-7.375-16.473-16.473v-9.015c0-11.851-11.595-20.23-22.847-16.511a128.797 128.797 0 01-80.823.006l-.031-.01c-11.252-3.717-22.845 4.662-22.845 16.512v9.019c0 9.098-7.375 16.473-16.473 16.473H159.88v26.938c0 6.883 5.58 12.464 12.464 12.464 2.172 0 3.939 1.701 4.076 3.869 2.628 41.668 37.235 74.654 79.565 74.654s76.937-32.986 79.565-74.654c.137-2.167 1.904-3.869 4.076-3.869 6.883 0 12.464-5.58 12.464-12.464v-26.939z"
                                                            fill="#ffddce"
                                                        />
                                                        <Path
                                                            d="M335.73 180.318c-9.098 0-16.473-7.375-16.473-16.473v-9.015c0-11.851-11.595-20.23-22.847-16.511a128.886 128.886 0 01-9.407 2.707v88.972c-.438 28.948-16.3 54.142-39.725 67.758a80.49 80.49 0 008.706.486c42.33 0 76.937-32.986 79.565-74.654.137-2.167 1.904-3.869 4.076-3.869 6.883 0 12.464-5.58 12.464-12.464v-26.938H335.73z"
                                                            fill="#ffcbbe"
                                                        />
                                                        <G fill="#f4fbff">
                                                            <Path d="M213.194 316.06l-33.558 27.267 35.192 43.513a11.657 11.657 0 0015.605.594l26.465-22.107zM298.79 316.06l-41.892 49.267 24.874 21.268a11.658 11.658 0 0015.651-.453l34.94-42.815z" />
                                                        </G>
                                                        <Path
                                                            d="M213.194 316.06l-49.256 24.199a7.461 7.461 0 00-3.341 10.117l9.65 18.71a12.528 12.528 0 01-2.282 14.61 43639.5 43639.5 0 00-6.004 5.993c-3.338 3.332-4.537 8.255-3.067 12.737 11.651 35.517 67.725 89.828 88.946 109.478 1.427.038 2.857.064 4.29.08-15.389-29.933-69.922-143.655-38.936-195.924zM344.019 383.695a12.527 12.527 0 01-2.282-14.61l9.65-18.71a7.461 7.461 0 00-3.341-10.117L298.79 316.06c30.978 52.255-23.517 165.929-38.923 195.9 1.448-.025 2.893-.061 4.335-.109 21.265-19.695 77.248-73.94 88.888-109.424 1.47-4.482.271-9.405-3.067-12.737l-6.004-5.995z"
                                                            fill="#365e7d"
                                                        />
                                                        <Path
                                                            d="M256.898 365.327l-26.06 21.764 13.278 16.781h23.767l13.279-17.771z"
                                                            fill="#dd636e"
                                                        />
                                                    </Svg>
                                                )
                                                    :
                                                    <Svg
                                                        height={50}
                                                        viewBox="0 0 512 512"
                                                        width={50}
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <Path
                                                            d="M256.522 496.333l96.354-27.793c61.613-48.365 101.202-123.51 101.202-207.915 0-129.719-93.489-237.588-216.761-259.943C104.655 10.253 0 120.9 0 256c0 71.244 28.569 135.165 75.536 181.573l10.563 9.899z"
                                                            fill="#cbe2ff"
                                                        />
                                                        <Path
                                                            d="M256 0c-11.024 0-21.886.698-32.543 2.05C349.476 18.038 446.914 125.64 446.914 256c0 95.61-52.415 178.974-130.076 222.935l109.058-31.458C478.709 400.582 512 332.184 512 256 512 114.615 397.385 0 256 0z"
                                                            fill="#bed8fb"
                                                        />
                                                        <Path
                                                            d="M334.886 306.987a55.659 55.659 0 0027.497-48.027l-.028-87.988c0-58.738-47.616-106.354-106.354-106.354s-106.354 47.616-106.354 106.354l-.028 87.988a55.657 55.657 0 0027.497 48.027l11.997 7.036a132.221 132.221 0 00133.778 0z"
                                                            fill="#efc984"
                                                        />
                                                        <Path
                                                            d="M362.354 170.972c0-44.092-26.832-81.916-65.058-98.038v252.623a132.162 132.162 0 0025.592-11.534l11.997-7.036a55.659 55.659 0 0027.497-48.027l-.028-87.988z"
                                                            fill="#eabc6b"
                                                        />
                                                        <Path
                                                            d="M256 418.104l67.48-47.486-27.239-32.992v-75.207h-80.482v75.207l-27.239 32.992z"
                                                            fill="#ffddce"
                                                        />
                                                        <Path
                                                            d="M387.573 355.203l-74.522-14.342c-11.766 19.162-32.916 31.943-57.051 31.943s-45.285-12.781-57.051-31.943l-74.522 14.342c-28.38 5.462-48.89 30.296-48.89 59.196v23.174c39.91 39.667 92.827 66.253 151.788 72.824 4.422.493 8.897.222 13.242-.736l18.955-4.18a29.12 29.12 0 0113.624.26 29.176 29.176 0 0018.624-1.318c58.042-24.285 105.575-68.671 133.875-124.479-8.737-12.533-22.168-21.68-38.072-24.741z"
                                                            fill="#4a80aa"
                                                        />
                                                        <Path
                                                            d="M436.463 414.399c0-16.318-6.541-31.337-17.343-42.307-37.636 73.834-110.035 126.985-195.638 137.854a258.447 258.447 0 0031.666 2.052h.026c.333.001 1.179.002 1.65 0h.026c70.073-.228 133.516-28.61 179.612-74.425v-23.174z"
                                                            fill="#407093"
                                                        />
                                                        <Path
                                                            d="M261.178 505.117c9.536-27.607 29.332-90.05 34.041-145.019-11.02 7.989-24.567 12.706-39.219 12.706s-28.199-4.717-39.219-12.706c4.833 56.421 25.564 120.725 34.777 147.14z"
                                                            fill="#e28086"
                                                        />
                                                        <Path
                                                            d="M250.768 504.961a730.16 730.16 0 002.458 7.007c5.249.055 3.424.022 5.548 0a737.884 737.884 0 003.502-10.051 252.509 252.509 0 01-11.508 3.044z"
                                                            fill="#dd636e"
                                                        />
                                                        <Path
                                                            d="M327.324 163.855c-28.418-30.607-33.694-58.413-33.694-58.413-14.116 37.04-49.967 63.355-91.964 63.355-1.285 0-2.564-.025-3.837-.073-9.664-.371-17.69 7.375-17.69 17.046v31.07c.625 41.373 34.338 74.725 75.861 74.725 41.522 0 75.235-33.351 75.861-74.725v-41.405c0-4.292-1.617-8.435-4.537-11.58z"
                                                            fill="#ffddce"
                                                        />
                                                        <Path
                                                            d="M327.324 163.855c-17.072-18.387-25.786-35.754-30.028-46.698v96.257c-.575 38.004-29.074 69.218-65.892 74.053A75.713 75.713 0 00256 291.565c41.522 0 75.235-33.352 75.861-74.725v-41.405c0-4.292-1.617-8.435-4.537-11.58z"
                                                            fill="#ffcbbe"
                                                        />
                                                        <G fill="#365e7d">
                                                            <Path d="M171.879 343.452c-3.239 1.985-3.883 6.423-1.341 9.246l15.779 17.527a6.076 6.076 0 01-.48 8.608l-10.404 9.244c-2.185 1.941-3.265 4.873-2.75 7.749 5.813 32.476 57.771 94.503 76.568 116.09 1.325.032 2.652.055 3.982.069-9.093-25.471-37.475-110.116-37.475-174.36v-21.064zM340.121 343.452l-43.878-26.89v21.064c0 64.244-28.382 148.889-37.475 174.36 1.33-.013 2.657-.036 3.982-.069 18.797-21.587 70.755-83.614 76.568-116.09.515-2.877-.566-5.808-2.75-7.749l-10.404-9.244a6.076 6.076 0 01-.48-8.608l15.779-17.527c2.541-2.824 1.897-7.262-1.342-9.247z" />
                                                        </G>
                                                        <Path
                                                            d="M343.362 175.56H331.86v38h11.502c10.496 0 19.003-8.51 19-19.006-.003-10.49-8.509-18.994-19-18.994z"
                                                            fill="#ffddce"
                                                        />
                                                    </Svg>

                                            } */}
                                        </div>
                                        <div className="letter-profile-name">
                                            <h4 className="letter-profile-name-heading">

                                                {/* {isFetching && <ActivityIndicator color='blue' size='small' />} */}
                                                {
                                                    typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                                        <span>{Profile[0].resource.name[0].text}</span>
                                                    ) : null
                                                }

                                            </h4>
                                        </div>
                                    </div>
                                    {/* <label htmlFor="getfile" className="p-2 text-primary fw-bold mt-3 px-3 choose-file w-100 text-center">Update Picture
                                    </label>
                                    <input
                                        type="file"
                                        id="getfile"
                                        onChange={selectFilesHandler}
                                        accept="image/png, image/jpg, image/jpeg"

                                    /> */}
                                </div>
                                <div className="col col-12 col-md-8 mb-3">
                                    <div className="row no-gutters">
                                        <div className="col-12 col-md-5">
                                            <div className="personal-info-container mr-lg-4">
                                                <div className="personal-info d-flex mb-3">
                                                    <div className="personal-balance">
                                                        <Tooltip title={`Balance: ${Balance}`} ><p className="personal-balance-para">Balance: <span>{Balance}</span>
                                                        </p>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="personal-nhi">
                                                        <p className="personal-nhi-para">NHI: <span>{Profile ? Profile[0]?.resource?.extension[3]?.valueString : "N/A"}</span></p>
                                                    </div>
                                                </div>
                                                <div className="personal-paypal-payment  mb-3 mb-md-0">
                                                    <Link to="/accounts"><button className="btn paypal-payment-btn">Invoices</button> </Link>
                                                    <button onClick={() => setTrustedDevicesListModalOpen(true)} className="btn paypal-payment-btn ml-2">Trusted Devices</button>
                                                </div>



                                            </div>
                                        </div>
                                        <div className="col-12 col-md-7">
                                            <div className="letter-action-container h-100">
                                                <button onClick={() => setIsTermsAndConditionsModalOpen(true)} className="btn terms-and-conditions-btn">
                                                    <i className="mdi mdi-file-document d-none d-md-flex" />
                                                    <span>Terms and Conditions</span>
                                                </button>
                                                <Modal
                                                    title="Terms And Conditions"
                                                    open={isTermsAndConditionsModalOpen} onCancel={() => setIsTermsAndConditionsModalOpen(false)} width={900}
                                                    closable={false}
                                                    cancelText="Close"
                                                    okButtonProps={{ style: { display: "none" } }}
                                                    maskClosable={false}>

                                                    <iframe title='Terms and Conditions' src=" https://firebasestorage.googleapis.com/v0/b/project-h-de8a7.appspot.com/o/Documents%2FmyIndici%20Terms%20and%20Conditions.pdf?alt=media&token=9e95577a-dfab-4e98-b7fc-08f6eaf39356" width={"100%"} height="500px" />

                                                </Modal>

                                                {/* <button onClick={() => setIsPortalGuideModalOpen(true)} className="btn portal-guide-btn mr-3">
                                                    <i className="mdi mdi-file-document d-none d-md-flex" />
                                                    <span>Portal Guide</span>
                                                </button>
                                                <Modal
                                                    title="Portal Guide"
                                                    open={isPortalGuideModalOpen} onCancel={() => setIsPortalGuideModalOpen(false)} width={900}
                                                    closable={false}
                                                    cancelText="Close"
                                                    okButtonProps={{ style: { display: "none" } }}
                                                    maskClosable={false}>

                                                    <iframe title='Portal Guide' src="https://firebasestorage.googleapis.com/v0/b/myindici-nz.appspot.com/o/Portal%20Guide%2Fmyindici%202.0%20Mobile%20User%20Guide.pdf?alt=media&token=b9063cb4-3c14-42ca-bdd7-c20c69b5a58e%27" width={"100%"} height="500px" />

                                                </Modal> */}
                                                <button onClick={() => { setShowReset(true) }} className="btn change-password-btn">
                                                    <i className="mdi mdi-lock d-none d-md-flex" />
                                                    <span>Change Password</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {isFetching &&
                                        <div className='activity-indicator-profile-screen'>
                                            <ActivityIndicator color='blue' size='small' />
                                        </div>}
                                </div>

                                <div className="col  col-12 col-md-4 mb-3">
                                    <div className="mb-4">
                                        <div className="row row-margin-zero justify-content-between" >
                                            <div className="letter-emergency-detail-card-heading">
                                                <h5 className="mb-0">Personal Details</h5>
                                                <div className="profile-right-icons">
                                                    {appSettings && appSettings.AllowPatientToUpdateProfile && <TouchableOpacity onPress={() =>
                                                        openModalPorfileUpdate()
                                                    }>
                                                        <img src='assets/images/Edit.svg' alt='edit' className='profile-screen-edit-svg' />
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill={"#000"} height="12pt" viewBox="0 -1 401.52289 401" width="12pt"><path d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0" /><path d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0" /></svg> */}

                                                    </TouchableOpacity>}
                                                </div>
                                            </div>
                                        </div>
                                       
                                        {typeof Profile !== "undefined" && Profile.length ?
                                            <PersonalDetail
                                                nameArray={typeof Profile !== "undefined" && Profile.length ? Profile : []} />
                                            : <span></span>}

                                    </div>
                                    <div>
                                        <div className="contact-detail-card-heading mb-0">
                                          
                                            <div className="row row-margin-zero justify-content-between">
                                                <div className="letter-emergency-detail-card-heading">
                                                    <h5 className='mb-0'>Contact Details</h5>
                                                    <div className="profile-right-icons">
                                                        {typeof Profile !== "undefined" && Profile.length && Profile[0].resource.telecom && appSettings && appSettings.AllowPatientToUpdateProfile ?
                                                            <TouchableOpacity onPress={() => Contact()}>
                                                                <img src='assets/images/Edit.svg' alt='edit' className='profile-screen-edit-svg' />
                                                             
                                                            </TouchableOpacity>
                                                            : null}

                                                    </div>
                                                </div>
                                              
                                            </div>
                                        </div>
                                        {typeof Profile !== "undefined" && Profile.length ?
                                            <ProfileContactDetails
                                                Profile={Profile} /> :
                                            <span></span>
                                        }
                                    </div>
                                </div>
                                <div className="col col-12 col-md-8 mb-3">
                                   
                                    <div className="mb-4">
                                        <div className="practice-detail-card">
                                            <div className="row row-margin-zero justify-content-between">
                                                <div className="letter-emergency-detail-card-heading">
                                                    <h5 className="mb-0">Practice Details</h5>
                                                </div>
                                               
                                            </div>

                                          

                                            {typeof Practise !== "undefined" && Practise.length &&
                                                <ProfilePracticeDetail Practise={Practise} />
                                            }
                                        </div>

                                    </div>
                                    <div>
                                        <div className="address-detail-card-heading mb-0">
                                            <div className="row row-margin-zero justify-content-between" >
                                                <div className="letter-emergency-detail-card-heading">
                                                    <h5 className="mb-0">Address</h5>
                                                
                                                    <div className="profile-right-icons">
                                                        {/* <TouchableOpacity
                                                        onPress={() => setAddressesModalVisible(true)}
                                                        >
                                                            <img src='assets/images/Edit.svg' alt='edit' className='profile-screen-edit-svg' />

                                                        </TouchableOpacity> */}
                                                    </div>
                                                </div>
                                            </div>
                                         
                                        </div>
                                        {typeof Profile !== "undefined" && Profile.length &&
                                            <ProfileAddress
                                                Profile={Profile}
                                            />
                                        }
                                    </div>
                                    <div className=" mb-4">
                                        <div className="row row-margin-zero justify-content-between">
                                            <div className="letter-emergency-detail-card-heading">
                                                <h5 className='mb-0'>Emergency Contact</h5>

                                                <div className="profile-right-icons" >
                                                    <TouchableOpacity onPress={() => setAddContactVisible(true)}>
                                                        <img src='assets/images/plus-circle.svg' alt='emergency-contant' className='profile-screen-edit-svg' />
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" height="20pt" viewBox="0 0 512 512" width="20">
                                                            <path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="green" />
                                                            <path d="m368 277.332031h-90.667969v90.667969c0 11.777344-9.554687 21.332031-21.332031 21.332031s-21.332031-9.554687-21.332031-21.332031v-90.667969h-90.667969c-11.777344 0-21.332031-9.554687-21.332031-21.332031s9.554687-21.332031 21.332031-21.332031h90.667969v-90.667969c0-11.777344 9.554687-21.332031 21.332031-21.332031s21.332031 9.554687 21.332031 21.332031v90.667969h90.667969c11.777344 0 21.332031 9.554687 21.332031 21.332031s-9.554687 21.332031-21.332031 21.332031zm0 0" fill="#fff" /></svg> */}
                                                    </TouchableOpacity>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="letter-emergency-detail-card-body mr-n2 ml-n2">
                                            <div className="row no-gutters">
                                                {/* <div className="col-12 col-md-4"> */}
                                                {typeof Profile !== "undefined" && Profile.length ?
                                                    Profile.map((person, index) => {
                                                        return (
                                                            <FlatList
                                                                horizontal={true}
                                                                showsHorizontalScrollIndicator={true}
                                                                data={person.resource.contact}
                                                                renderItem={({ item, index }) => (

                                                                    <div className="emergency-card">

                                                                        <div onClick={() => {
                                                                            setContactId(item.id)
                                                                            setVisible(true)
                                                                        }
                                                                        }
                                                                            className="emergency-card-cross-icon">
                                                                            <Tooltip title="Delete Contact">
                                                                            <span className='text-light'>X</span>
                                                                            </Tooltip>    
                                                                        </div>
                                                                        {(titleArray?.length > 0 && townArray?.length > 0 && cityArray?.length > 0) || true ? <div onClick={() => {
                                                                            getEmergencyContactDetailsFromAPI(item.id)

                                                                            setContactId(item.id)
                                                                            // setEmContactTitle(item?.name?.prefix[0])
                                                                            // setIsEmergencyContactUpdating(true)
                                                                            // setAddContactVisible(true)
                                                                        }
                                                                        }
                                                                            className="emergency-card-update-icon"                                                                    >
                                                                            <Tooltip title='Update Contact'> <img src='assets/images/Edit.svg' alt='edit' className='profile-screen-edit-svg' /></Tooltip>
                                                                        </div> : null}
                                                                        <div className="w-100 d-flex">
                                                                            <div className="contact-image-container">
                                                                                <div className='emergency-contact-picon'>
                                                                                    {(
                                                                                        <span>{item.name.given[0].charAt(0)}</span>
                                                                                    )}
                                                                                </div>
                                                                                {/* <Svg
                                                                                    height={40}
                                                                                    viewBox="0 0 512 512"
                                                                                    width={40}
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    {...props}
                                                                                >
                                                                                    <Path
                                                                                        d="M256.522 496.333l96.354-27.793c61.613-48.365 101.202-123.51 101.202-207.915 0-129.719-93.489-237.588-216.761-259.943C104.655 10.253 0 120.9 0 256c0 71.244 28.569 135.165 75.536 181.573l10.563 9.899z"
                                                                                        fill="#cbe2ff"
                                                                                    />
                                                                                    <Path
                                                                                        d="M256 0c-11.024 0-21.886.698-32.543 2.05C349.476 18.038 446.914 125.64 446.914 256c0 95.61-52.415 178.974-130.076 222.935l109.058-31.458C478.709 400.582 512 332.184 512 256 512 114.615 397.385 0 256 0z"
                                                                                        fill="#bed8fb"
                                                                                    />
                                                                                    <Path
                                                                                        d="M334.886 306.987a55.659 55.659 0 0027.497-48.027l-.028-87.988c0-58.738-47.616-106.354-106.354-106.354s-106.354 47.616-106.354 106.354l-.028 87.988a55.657 55.657 0 0027.497 48.027l11.997 7.036a132.221 132.221 0 00133.778 0z"
                                                                                        fill="#efc984"
                                                                                    />
                                                                                    <Path
                                                                                        d="M362.354 170.972c0-44.092-26.832-81.916-65.058-98.038v252.623a132.162 132.162 0 0025.592-11.534l11.997-7.036a55.659 55.659 0 0027.497-48.027l-.028-87.988z"
                                                                                        fill="#eabc6b"
                                                                                    />
                                                                                    <Path
                                                                                        d="M256 418.104l67.48-47.486-27.239-32.992v-75.207h-80.482v75.207l-27.239 32.992z"
                                                                                        fill="#ffddce"
                                                                                    />
                                                                                    <Path
                                                                                        d="M387.573 355.203l-74.522-14.342c-11.766 19.162-32.916 31.943-57.051 31.943s-45.285-12.781-57.051-31.943l-74.522 14.342c-28.38 5.462-48.89 30.296-48.89 59.196v23.174c39.91 39.667 92.827 66.253 151.788 72.824 4.422.493 8.897.222 13.242-.736l18.955-4.18a29.12 29.12 0 0113.624.26 29.176 29.176 0 0018.624-1.318c58.042-24.285 105.575-68.671 133.875-124.479-8.737-12.533-22.168-21.68-38.072-24.741z"
                                                                                        fill="#4a80aa"
                                                                                    />
                                                                                    <Path
                                                                                        d="M436.463 414.399c0-16.318-6.541-31.337-17.343-42.307-37.636 73.834-110.035 126.985-195.638 137.854a258.447 258.447 0 0031.666 2.052h.026c.333.001 1.179.002 1.65 0h.026c70.073-.228 133.516-28.61 179.612-74.425v-23.174z"
                                                                                        fill="#407093"
                                                                                    />
                                                                                    <Path
                                                                                        d="M261.178 505.117c9.536-27.607 29.332-90.05 34.041-145.019-11.02 7.989-24.567 12.706-39.219 12.706s-28.199-4.717-39.219-12.706c4.833 56.421 25.564 120.725 34.777 147.14z"
                                                                                        fill="#e28086"
                                                                                    />
                                                                                    <Path
                                                                                        d="M250.768 504.961a730.16 730.16 0 002.458 7.007c5.249.055 3.424.022 5.548 0a737.884 737.884 0 003.502-10.051 252.509 252.509 0 01-11.508 3.044z"
                                                                                        fill="#dd636e"
                                                                                    />
                                                                                    <Path
                                                                                        d="M327.324 163.855c-28.418-30.607-33.694-58.413-33.694-58.413-14.116 37.04-49.967 63.355-91.964 63.355-1.285 0-2.564-.025-3.837-.073-9.664-.371-17.69 7.375-17.69 17.046v31.07c.625 41.373 34.338 74.725 75.861 74.725 41.522 0 75.235-33.351 75.861-74.725v-41.405c0-4.292-1.617-8.435-4.537-11.58z"
                                                                                        fill="#ffddce"
                                                                                    />
                                                                                    <Path
                                                                                        d="M327.324 163.855c-17.072-18.387-25.786-35.754-30.028-46.698v96.257c-.575 38.004-29.074 69.218-65.892 74.053A75.713 75.713 0 00256 291.565c41.522 0 75.235-33.352 75.861-74.725v-41.405c0-4.292-1.617-8.435-4.537-11.58z"
                                                                                        fill="#ffcbbe"
                                                                                    />
                                                                                    <G fill="#365e7d">
                                                                                        <Path d="M171.879 343.452c-3.239 1.985-3.883 6.423-1.341 9.246l15.779 17.527a6.076 6.076 0 01-.48 8.608l-10.404 9.244c-2.185 1.941-3.265 4.873-2.75 7.749 5.813 32.476 57.771 94.503 76.568 116.09 1.325.032 2.652.055 3.982.069-9.093-25.471-37.475-110.116-37.475-174.36v-21.064zM340.121 343.452l-43.878-26.89v21.064c0 64.244-28.382 148.889-37.475 174.36 1.33-.013 2.657-.036 3.982-.069 18.797-21.587 70.755-83.614 76.568-116.09.515-2.877-.566-5.808-2.75-7.749l-10.404-9.244a6.076 6.076 0 01-.48-8.608l15.779-17.527c2.541-2.824 1.897-7.262-1.342-9.247z" />
                                                                                    </G>
                                                                                    <Path
                                                                                        d="M343.362 175.56H331.86v38h11.502c10.496 0 19.003-8.51 19-19.006-.003-10.49-8.509-18.994-19-18.994z"
                                                                                        fill="#ffddce"
                                                                                    />
                                                                                </Svg> */}
                                                                            </div>
                                                                            <div className="card-content">
                                                                                {/* added minWidth for card data handling */}
                                                                                <div className="card-heading profile-screen-card-heading">
                                                                                    <h5>{item.name.given[0]} {item.name.family}</h5>
                                                                                </div>
                                                                                <div className="card-partner">
                                                                                    <span>{item.relationship[0].coding[0].display}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                )}
                                                                keyExtractor={(item, index) => index + ""}

                                                            />
                                                        )
                                                    })
                                                    : null

                                                }
                                                

                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="Edit Profile Image"

                closable={false}
                maskClosable={false}

                cancelText="Close"
                okText="Upload"
                open={avatarEditorModalOpen}
                onOk={() => {

                    if (editor) {
                        // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
                        // drawn on another canvas, or added to the DOM.
                        const canvas = editor.current.getImage()

                        getUserId().then((userInfo) => {
                            const item = JSON.parse(userInfo);
                            const queryString = '?Token=' + item.token
                            let resStatus = 200


                            var myHeaders = new Headers();
                            myHeaders.append("token", item.token);
                            myHeaders.append("Content-Type", "application/json");

                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                redirect: 'follow',
                                body: JSON.stringify({
                                    FileName: imageFiles[0].name,
                                    Base64file: canvas.toDataURL()?.split("base64,")[1]
                                })
                            };

                            fetch(Settings.apiUrl + "Patient/Uploadfile?" + "PatientID=" + item.userid + "&ImageTypeID=1", requestOptions)
                                .then(response => {
                                    resStatus = response.status
                                    return response.json()
                                })
                                .then(result => {
                                    if (resStatus === 200) {

                                        openNotificationWithIcon("success", "Picture uploaded successfully")
                                        apiDispatcher(actionAPI.GetUserProfileAPI())
                                    }
                                    else {
                                        openNotificationWithIcon("error", result)

                                    }
                                })
                                .catch(error => {

                                });
                        })
                        // If you want the image resized to the canvas size (also a HTMLCanvasElement)
                        const canvasScaled = editor.current.getImageScaledToCanvas()
                    }

                    setAvatarEditorModalOpen(false)
                }}
                onCancel={() => setAvatarEditorModalOpen(false)}
            >

                <AvatarEditor
                    ref={editor}

                    image={imageFiles[0]}
                    width={isMobile ? avatarImageDimensions.mobWidth : avatarImageDimensions.width}
                    height={avatarImageDimensions.height}
                    border={avatarImageDimensions.border}
                    color={[0, 0, 0, 0.4]} // RGBA
                    scale={avatarImageDimensions.scale}
                    rotate={avatarImageDimensions.rotate}

                />

            </Modal>
            {/* {Add Emergency contact Modal} */}
            <Modal
                title={isEmergencyContactUpdating ? "Update Emergency Contact" : "Add Emergency Contact"}
                centered
                open={addContactVisible}
                okText={isEmergencyContactUpdating ? "Update" : "Save"}
                onOk={() => {
                    saveApi(contactid)
                    // setAddContactVisible(false)
                }}
                onCancel={() => setAddContactVisible(false)}
                afterClose={() => {
                    resetEmContactFormFields()
                    resetEmContactValidationFields()
                    setIsEmergencyContactUpdating(false)
                    setFindAddressText(null)
                    setFoundAddressList([])
                }}
                className="profile-screen-modal"
                width={1000}
            >
                <div className="row" >
                    <div className="col-md-12 mx-auto">

                        {/* Name Section */}
                        <div className="form-group row mb-0">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Title</label>
                                <Select className="titleEmContact" value={emContactTitleID} onChange={(e, option) => { setEmContactTitleID(e); setEmContactTitle(option.children); setEmContactTitleinValidMsg("") }}
                                    filterOption={(input, option) => (option?.children?.toLowerCase() ?? '').includes(input?.toLowerCase())}
                                    showSearch
                                >
                                    <Option value="">Select</Option>
                                    {
                                        titleArray.length > 0 && titleArray.map((value, key) => {
                                            return (
                                                <Option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                {/* <select id="mTitlte" className="form-control" name="country">
                                        {
                                            titleArray.length > 0 && titleArray.map((value, key) => {
                                                return (
                                                    <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                )
                                            })
                                        }
                                        {/* <option value="1234">Hassan Jan</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Laos">Laos</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Falkland Islands">Falkland Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Belize">Belize</option>
                                    </select> */}
                                <label htmlFor="validate-internet-ip-field" className='text-danger' id="mTitleerror">{emContactTitleinValidMsg}</label>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">First Name<span className='text-danger'> *</span></label>
                                <div className="input-group">
                                    {/* <input id="mFirstName" type="text" className="form-control" placeholder="First Name" /> */}
                                    <Input autoComplete="off" id="mFirstName" value={emContactFirstName} onChange={(e) => { setEmContactFirstName(e.target.value); setEmContactFirstNameinValidMsg("") }} placeholder="First Name" />
                                </div>
                                <label htmlFor="validate-internet-ip-field" className='text-danger' id="mfirstnameerror">{emContactFirstNameinValidMsg}</label>
                            </div>
                        </div>

                        {/* Family Section */}
                        <div className="form-group row mb-0">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Family Name<span className='text-danger'> *</span></label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mFamilyName" value={emContactFamilyName} onChange={(e) => { setEmContactFamilyName(e.target.value); setEmContactFamilyNameinValidMsg("") }} placeholder="Family Name" />
                                    {/* <input id="mFamilyName" type="text" className="form-control" placeholder="Family Name" /> */}

                                </div>
                                <label htmlFor="validate-internet-ip-field" className='text-danger' id="mfaimlynameerror">{emContactFamilyNameinValidMsg}</label>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Relationship<span className='text-danger'> *</span></label>
                                <div className="input-group">
                                    <Select className="relationshipEmContact" value={emContactRelationshipID} onChange={(e, option) => { setEmContactRelationshipID(e); setEmContactRelationship(option.children); setEmContactRelationshipinValidMsg("") }}
                                        filterOption={(input, option) => (option?.children?.toLowerCase() ?? '').includes(input?.toLowerCase())}
                                        showSearch
                                    >
                                        <Option value="">Select</Option>
                                        {
                                            relationShipArray.length > 0 && relationShipArray.map((value, key) => {
                                                return (
                                                    <Option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                    <p className='text-danger'>{emContactRelationshipinValidMsg}</p>
                                    {/* <select id="mRelationship" className="form-control" name="country">
                                            {
                                                relationShipArray.length > 0 && relationShipArray.map((value, key) => {
                                                    return (
                                                        <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                    )
                                                })
                                            }
                                            {/* <option value="Togo">Togo</option>
                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                            <option value="Laos">Laos</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Falkland Islands">Falkland Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Belize">Belize</option>
                                        </select> */}
                                </div>
                            </div>
                        </div>

                        {/* Contact Section */}
                        <div className="form-group row mb-4">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Cell Phone Number<span className='text-danger'> *</span></label>
                                <div className="input-group">
                                    <PhoneInput
                                        defaultCountry="NZ"
                                        value={emContactHomePhone}
                                        onChange={(e) => {
                                            setEmContactHomePhoneinValidState("")

                                            if (e) {

                                                setEmContactHomePhone(e)
                                                if (e && isValidPhoneNumber(e)) {
                                                    setEmContactHomePhoneinValidMsg("Valid")
                                                    setEmContactHomePhoneinValidState("")
                                                } else {
                                                    setEmContactHomePhoneinValidMsg("Invalid Number")
                                                    setEmContactHomePhoneinValidState("Invalid Phone Number")
                                                }
                                            } else {

                                                setEmContactHomePhoneinValidMsg("")
                                                setEmContactHomePhoneinValidState("")
                                                setEmContactHomePhone("")
                                            }
                                        }
                                        }
                                        placeholder="Enter phone number"
                                        // international={false}
                                        className={"pl-2 phone-input w-100 " + (emContactHomePhoneinValidMsg === "" ? " empty-phone " : emContactHomePhoneinValidMsg === "Valid" ? " valid-phone " : " invalid-phone ")}
                                        onBlur={() => {
                                            if (emContactHomePhoneinValidMsg !== "Invalid Number") {
                                                setEmContactHomePhoneinValidMsg('')
                                            }
                                        }}
                                    />
                                    {/* <ReactPhoneInput
                                        inputExtraProps={{
                                            name: "Home Phone Number:",
                                            required: true,
                                            autoFocus: true
                                        }}
                                        areaCodes={{ nz: ['+64'] }}
                                        placeholder="Home Phone Number"
                                        //onlyCountries={['nz']}
                                        defaultCountry={"NZ"}
                                        country={'nz'}
                                        value={emContactHomePhone}
                                        inputStyle={{
                                            width: 465,
                                            backgroundColor: '#f6f7f9',
                                            height: 34
                                        }}
                                        // containerStyle={{
                                        //     width: '100%',
                                        // }}
                                        onChange={(value, data, index, formattedValue) => { setEmContactHomePhone(formattedValue); setEmContactHomePhoneinValidMsg("") }}

                                    /> */}
                                    {/* <Input id="mHomePhone" value={emContactHomePhone} onChange={(e) => { setEmContactHomePhone(e.target.value); setEmContactHomePhoneinValidMsg("") }} placeholder="Home Phone" /> */}

                                    {/* <input id="mHomePhone" type="text" className="form-control" placeholder="Home Phone" /> */}

                                </div>
                                <label htmlFor="validate-internet-ip-field" className='text-danger' id="mhomephone">{emContactHomePhoneinValidState}</label>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Work Phone Number</label>
                                <div className="input-group">
                                    <PhoneInput
                                        defaultCountry="NZ"
                                        value={emContactWorkPhone}
                                        onChange={(e) => {
                                            setEmContactWorkPhoneinValidState("")
                                            if (e) {

                                                setEmContactWorkPhone(e)
                                                if (e && isValidPhoneNumber(e)) {
                                                    setEmContactWorkPhoneinValidMsg("Valid")
                                                    setEmContactWorkPhoneinValidState("")
                                                } else {
                                                    setEmContactWorkPhoneinValidMsg("Invalid Number")
                                                    setEmContactWorkPhoneinValidState("Invalid Phone Number")

                                                }
                                            } else {

                                                setEmContactWorkPhoneinValidMsg("")
                                                setEmContactWorkPhoneinValidState("")
                                                setEmContactWorkPhone("")
                                            }
                                        }
                                        }
                                        placeholder="Enter phone number"
                                        // international={false}
                                        className={"pl-2 phone-input w-100 " + (emContactWorkPhoneinValidMsg === "" ? " empty-phone " : emContactWorkPhoneinValidMsg === "Valid" ? " valid-phone " : " invalid-phone ")}
                                        onBlur={() => {
                                            if (emContactWorkPhoneinValidMsg !== "Invalid Number") {
                                                setEmContactWorkPhoneinValidMsg('')
                                            }
                                        }}
                                    />

                                    {/* <ReactPhoneInput
                                        inputExtraProps={{
                                            name: "Work Phone Number:",
                                            required: true,
                                            autoFocus: true
                                        }}
                                        areaCodes={{ nz: ['+64'] }}
                                        placeholder="Work Phone Number"
                                        //onlyCountries={['nz']}
                                        defaultCountry={"NZ"}
                                        country={'nz'}
                                        value={emContactWorkPhone}
                                        inputStyle={{
                                            width: 465,
                                            backgroundColor: '#f6f7f9',
                                            height: 34
                                        }}
                                        // containerStyle={{
                                        //     width: '100%',
                                        // }}
                                        onChange={(value, data, index, formattedValue) => { setEmContactWorkPhone(formattedValue); setEmContactWorkPhoneinValidMsg("") }}
                                    /> */}
                                    {/* <Input id="mWorkPhone" value={emContactWorkPhone} onChange={(e) => { setEmContactWorkPhone(e.target.value); setEmContactWorkPhoneinValidMsg("") }} placeholder="Work Phone" /> */}

                                    {/* <input id="mWorkPhone" type="text" className="form-control" placeholder="Work Phone" /> */}

                                </div>
                                <label htmlFor="validate-internet-ip-field" className='text-danger' id="mworkphone">{emContactWorkPhoneinValidState}</label>
                            </div>
                        </div>

                        <div className="form-group row mb-4">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Find Address</label>
                                <div className="input-group">
                                    <Select
                                        // loading={findAddressTextLoading}
                                        value={findAddressText}
                                        onSearch={handlefindAddressTextSearch}
                                        showSearch
                                        onChange={(e) => {
                                            handlefindAddressTextChange(e)
                                        }}
                                        allowClear
                                        placeholder="Find Address in eSAM"
                                        // notFoundContent={findAddressTextLoading ? "Loading" : null}
                                        filterOption={false}
                                        notFoundContent={findAddressTextLoading ? <div className='w-100 d-flex justify-content-center'><Spin /></div> : noAddressFoundFromSearch ? <p className='text-danger'>No Record Found</p> : null}
                                    >
                                        {foundAddressList.length > 0 && foundAddressList.map((item) => <Option value={item?.resource?.extension[0]?.valueString}>{item?.resource?.extension[1]?.valueAddress?.text}

                                        </Option>)}
                                    </Select>

                                </div>
                            </div>
                        </div>
                        {/* House infomraiton */}
                        <div className="form-group row mb-4">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">House Number</label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mHouse" value={emContactHomeNumber} onChange={(e) => setEmContactHomeNumber(e.target.value)} placeholder="House Number" />

                                    {/* <input id="mHouse" type="text" className="form-control" placeholder="House Number" /> */}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Floor/Building</label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mFloor" value={emContactFloorBuilding} onChange={(e) => setEmContactFloorBuilding(e.target.value)} placeholder="Floor or Building" />

                                    {/* <input id="mFloor" type="text" className="form-control" placeholder="Floor" /> */}

                                </div>
                            </div>

                        </div>

                        {/* Street information */}
                        <div className="form-group row mb-4">

                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Suburb/Town</label>
                                <Select className="suburbEmContact" value={emContactSuburbTownID} onChange={(e, option) => { setEmContactSuburbTownID(e); setEmContactSuburbTown(option.children) }}
                                    filterOption={(input, option) => (option?.children?.toLowerCase() ?? '').includes(input?.toLowerCase())}
                                    showSearch

                                >
                                    <Option value="">Select</Option>
                                    {
                                        townArray.length > 0 && townArray.map((value, key) => {
                                            return (
                                                <Option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                {/* <select id="mTown" className="form-control" name="country">
                                        {
                                            townArray.length > 0 && townArray.map((value, key) => {
                                                return (
                                                    <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                )
                                            })
                                        }
                                        {/* <option value="Togo">Togo</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Laos">Laos</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Falkland Islands">Falkland Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Belize">Belize</option>
                                    </select> */}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">City</label>
                                <Select className="cityEmContact" value={emContactCityID} onChange={(e, option) => { setEmContactCityID(e); setEmContactCity(option.children) }}
                                    showSearch

                                    filterOption={(input, option) => (option?.children?.toLowerCase() ?? '').includes(input?.toLowerCase())}>
                                    <Option value="">Select</Option>
                                    {
                                        cityArray.length > 0 && cityArray.map((value, key) => {
                                            return (
                                                <Option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                {/* <select id="mCity" className="form-control" name="country">
                                        {
                                            cityArray.length > 0 && cityArray.map((value, key) => {
                                                return (
                                                    <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                )
                                            })
                                        }
                                        {/* <option value="Togo">Togo</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Laos">Laos</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Falkland Islands">Falkland Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Belize">Belize</option>
                                    </select> */}
                            </div>
                        </div>
                        <div className="form-group row mb-4">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Residence</label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mResidence" value={emContactResidence} onChange={(e) => setEmContactResidence(e.target.value)} placeholder="Residence" />


                                    {/* <input id="mResidence" type="text" className="form-control" placeholder="Residence" /> */}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Street</label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mFloor" value={emContactStreet} onChange={(e) => setEmContactStreet(e.target.value)} placeholder="Street" />

                                    {/* <input id="mStreet" type="text" className="form-control" placeholder="Street" /> */}

                                </div>
                            </div>
                        </div>
                        {/* City information */}
                        <div className="form-group row mb-4">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Postal Code</label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mPostcode" value={emContactPostalCode} onChange={(e) => setEmContactPostalCode(e.target.value)} placeholder="Postal Code" />

                                    {/* <input id="mPostcode" type="text" className="form-control" placeholder="Postal Code" /> */}

                                </div>
                            </div>
                        </div>

                        {/* Postal Code INfromation */}

                        {/* <input id="mHouse" type="text" className="form-control" placeholder="Cell Phone Number" /> */}
                        {/* <div className="form-group ">
                            <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Contact Methods:</label>
                                    <div className="input-group row row-cols-2">
                                        <div className="col d-flex flex-column">
                                            <Checkbox checked={cmCellPhoneChecked} onClick={() => setCmCellPhoneChecked(!cmCellPhoneChecked)} className="m-0 my-1" >Cell Phone</Checkbox>
                                            <Checkbox checked={cmNightPhoneChecked} className="m-0 my-1" onClick={() => setCmNightPhoneChecked(!cmNightPhoneChecked)} >Night Phone</Checkbox>
                                            <Checkbox checked={cmTextChecked} onClick={() => setCmTextChecked(!cmTextChecked)} className="m-0 my-1" >Text</Checkbox>
                                        </div>
                                        <div className="col d-flex flex-column">
                                            <Checkbox checked={cmDayPhoneChecked} onClick={() => setCmDayPhoneChecked(!cmDayPhoneChecked)} className="m-0 my-1" >Day Phone</Checkbox>
                                            <Checkbox checked={cmEmailChecked} onClick={() => setCmEmailChecked(!cmEmailChecked)} className="m-0 my-1" >Email</Checkbox>
                                            <Checkbox checked={cmPostChecked} onClick={() => setCmPostChecked(!cmPostChecked)} className="m-0 my-1" >Post</Checkbox>
                                        </div>
                                    </div>
                            </div>
                            <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Preffered Contact:</label>
                                    <div className="input-group">
                                        <Select placeholder="Select a Preferred Contact" defaultValue={preferredCmID} onChange={(value) => setPreferredCmID(value)}>
                                            <Option value={1}>Cell Phone</Option>
                                            <Option value={2}>Night Phone</Option>
                                            <Option value={3}>Text</Option>
                                            <Option value={4}>Day Phone</Option>
                                            <Option value={5}>Email</Option>
                                            <Option value={6}>Post</Option>
                                        </Select>

                                    </div>
                            </div>

                        </div> */}
                        {
                            isAddingEmergency && <div className='m-top-20'><ActivityIndicator size="small" /></div>
                        }
                    </div>
                </div>

            </Modal>


            <Modal
                // show={showReset}
                open={showReset}
                onHide={() => handleCloseReset()}
                backdrop="static"

                title="Update your password to continue."
                centered
                // open={showReset}
                onOk={() => {
                    // saveApi()
                    resetPasswordFunc()
                    // setAddContactVisible(false)
                }}
                onCancel={() => handleCloseReset()}
                className="profile-screen-modal"
                width={1000}



                keyboard={false}>

                <div>
                    <div className='reset-password-rules mb-4'>
                        <p className='fw-bolder'>Password Rules</p>
                        <Text>Password must be at least 8 characters.</Text>
                        <br />
                        <Text>Password must contain at least one alphabet and two digits.</Text>
                        <br />
                        <Text>Password must contain at least one upper case letter.</Text>
                        <br />
                        <Text>Password must not contain first, middle or family name.</Text>
                        <br />
                        <Text>Example: Abc@1234</Text>
                    </div>
                    {/* <div className="form-group input-rounded">
                                <input id="password" type="text" className="form-control" placeholder="Enter password." />
                            </div>
                            <div className="form-group input-rounded">
                                <input id="confirmPassword" type="text" className="form-control" placeholder="Confirm password." />
                            </div>
                            <div className="form-group input-rounded">
                                <p id="errorMessageReset" className='text-danger'></p>
                            </div> */}

                    <div className="form-group">
                        <div className="input-group">
                            <input autoComplete="off" id="password" type="password" value={changePass} onChange={(e) => {
                                const regex = /^\S*$/;
                                if (!regex.test(e.target.value)) {
                                    return
                                }
                                setChangePass(e.target.value)
                            }
                            } className="form-control" placeholder="Enter New Password" />
                            <div className="input-group-append">
                                <div onClick={() => showPassword()} className="input-group-text"><i className={showincon}></i></div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="input-group">
                            <input autoComplete="off" id="confirmPassword" value={changeConfirmPass} onChange={(e) => {
                                const regex = /^\S*$/;
                                if (!regex.test(e.target.value)) {
                                    return
                                }
                                setChangeConfirmPass(e.target.value)
                            }} type="password" className="form-control" placeholder="Confirm New Password" />
                            <div className="input-group-append">
                                <div onClick={() => showPasswordConfirm()} className="input-group-text"><i className={showinconConf}></i></div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group input-rounded">
                        <p id="errorMessageReset" className='text-danger'>{changePassErrMsg}</p>
                    </div>
                    {
                        isResetPassword && <div className='text-center mb-5'><ActivityIndicator size="small" color="#00A1DE" /></div>
                    }
                    {
                        passwordStartMessage.error && <div className='mb-5'><span className='text-danger'>{passwordStartMessage.message}</span></div>
                    }
                    {/* <button onClick={() => resetPasswordFunc()} className="btn btn-primary btn-block">Set Password </button> */}

                </div>


            </Modal>

            {/* Edit Address Modal start */}
            <Modal
                title="Edit Address Information"
                centered
                open={editAddressesModalVisible}
                onOk={() => {
                    updateProfile("editAddress")

                    // saveApi()
                    // setAddContactVisible(false)
                }}
                onCancel={() => setAddressesModalVisible(false)}
                className="profile-screen-modal"
                width={1000}
            >
                <div className="row" >
                    <div className="col-md-12 mx-auto">
                        {/* <div className="letter-emergency-detail-card-heading mb-4">
                            <h5 className='m-0 p-2'>Address Information</h5>
                        </div> */}

                        {/* House infomraiton */}
                        <div className="form-group row mb-0 m-top-20">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Find Address</label>
                                <div className="input-group">
                                    <Select
                                        loading={findAddressTextLoading}
                                        value={findAddressText}
                                        onSearch={handlefindAddressTextSearch}
                                        showSearch
                                        onChange={(e) => {
                                            handlefindAddressTextChange(e)
                                        }}
                                        placeholder="Find Address in eSAM"
                                        notFoundContent={findAddressTextLoading ? <div className='w-100 d-flex justify-content-center'><Spin /></div> : <p>No Record Found </p>}
                                        filterOption={false}
                                    >
                                        {foundAddressList.length > 0 && foundAddressList.map((item) => <Option value={item?.resource?.extension[0]?.valueString}>{item?.resource?.extension[1]?.valueAddress?.text}

                                        </Option>)}
                                    </Select>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">House Number</label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mHouse" className="form-control" placeholder="House Number" value={houseNumEditAddress} onChange={(e) => {
                                        setHouseNumEditAddress(e.target.value);
                                        setHouseNumEditAddressErrMsg("")
                                    }} />

                                </div>
                                <span className='text-danger'>{houseNumEditAddressErrMsg}</span>

                            </div>

                        </div>

                        {/* Street information */}
                        <div className="form-group row mb-0 m-top-20">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Floor/Building</label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mFloor" className="form-control" placeholder="Floor" value={floorEditAddress} onChange={(e) => setFloorEditAddress(e.target.value)} />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Street</label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mStreet" className="form-control" placeholder="Street" value={streetEditAddress} onChange={(e) => {
                                        setStreetEditAddress(e.target.value);
                                        setStreetEditAddressErrMsg("")
                                    }} />

                                </div>
                                <span className='text-danger'>{streetEditAddressErrMsg}</span>
                            </div>
                        </div>

                        {/* City information */}
                        <div className="form-group row mb-0 m-top-20">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Suburb/Town</label>
                                <Select id="mTown" className="form-control" name="country" value={countryEditAddressID} onChange={(e, option) => {
                                    setCountryEditAddressID(e);
                                    setCountryEditAddressName(option.children)
                                    setCountryEditAddressErrMsg("")
                                }}>
                                    <Option value="">Select</Option>
                                    {
                                        townArray.length > 0 && townArray.map((value, key) => {
                                            return (
                                                <Option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</Option>
                                            )
                                        })
                                    }

                                </Select>
                                <span className='text-danger'>{countryEditAddressErrMsg}</span>

                            </div>

                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">City</label>
                                <Select id="mCity" className="form-control" value={cityEditAddressID} onChange={
                                    (e, option) => {
                                        setCityEditAddressID(e); setCityEditAddressName(option.children);
                                        setCityEditAddressFieldErrMsg("")
                                        setCityEditAddressErrMsg("")
                                    }}>
                                    <Option value={""}>Select</Option>

                                    {
                                        cityArray.length > 0 && cityArray.map((value, key) => {
                                            return (
                                                <Option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</Option>
                                            )
                                        })
                                    }

                                </Select>
                                <span className='text-danger'>{cityEditAddressFieldErrMsg}</span>

                            </div>

                        </div>

                        {/* Postal Code INfromation */}
                        <div className="form-group row mb-0 m-top-20">
                            {/* <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Residence</label>
                                    <div className="input-group">
                                        <input autoComplete="off" id="mResidence" type="text" className="form-control" placeholder="Residence" />

                                    </div>
                                </div> */}
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Postal Code</label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mPostcode" type="text" className="form-control" placeholder="Postal Code" value={postCodeEditAddress} onChange={(e) => {
                                        setPostCodeEditAddress(e.target.value)
                                        setPostCodeEditAddressErrMsg("")
                                    }} />

                                </div>
                                <span className='text-danger'>{postCodeEditAddressErrMsg}</span>

                            </div>

                        </div>
                        {
                            isAddingEmergency && <div className='m-top-20'><ActivityIndicator size="small" /></div>
                        }


                    </div>


                </div>
                <hr className='w-100' />
                <h5 className='m-0 '>Postal Address</h5>
                <Checkbox checked={isSamePostalAddressEditAddress} onChange={() =>
                    handleIsSamePostalAddressEditAddressChange()} >Same as Permanent Address</Checkbox>
                {!isSamePostalAddressEditAddress && <div className="row" >
                    <div className="col-md-12 mx-auto">
                        {/* <div className="letter-emergency-detail-card-heading mb-4">
                            <h5 className='m-0 p-2'>Address Information</h5>
                        </div> */}

                        {/* House infomraiton */}
                        <div className="form-group row mb-0 m-top-20">

                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">House Number</label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mHouse" className="form-control" placeholder="House Number" value={houseNumEditPostalAddress} onChange={(e) => {
                                        setHouseNumEditPostalAddress(e.target.value);
                                        setHouseNumEditPostalAddressErrMsg("")
                                    }} />

                                </div>
                                <span className='text-danger'>{houseNumEditPostalAddressErrMsg}</span>

                            </div>

                        </div>

                        {/* Street information */}
                        <div className="form-group row mb-0 m-top-20">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Floor/Building</label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mFloor" className="form-control" placeholder="Floor" value={floorEditPostalAddress} onChange={(e) => setFloorEditPostalAddress(e.target.value)} />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Street</label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mStreet" className="form-control" placeholder="Street" value={streetEditPostalAddress} onChange={(e) => {
                                        setStreetEditPostalAddress(e.target.value)
                                        setStreetEditPostalAddressErrMsg("")
                                    }} />

                                </div>
                                <span className='text-danger'>{streetEditPostalAddressErrMsg}</span>

                            </div>
                        </div>

                        {/* City information */}
                        <div className="form-group row mb-0 m-top-20">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Suburb/Town</label>
                                <Select id="mTown" className="form-control" name="country" value={countryEditPostalAddressID} onChange={(e, option) => {
                                    setCountryEditPostalAddressID(e);
                                    setCountryEditPostalAddressName(option.children);
                                    setCountryEditPostalAddressNameErrMsg("")
                                }}>
                                    <Option value="">Select</Option>
                                    {
                                        townArray.length > 0 && townArray.map((value, key) => {
                                            return (
                                                <Option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</Option>
                                            )
                                        })
                                    }

                                </Select>
                                <span className='text-danger'>{countryEditPostalAddressNameErrMsg}</span>

                            </div>

                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">City</label>
                                <Select id="mCity" className="form-control" value={cityEditPostalAddressID} onChange={
                                    (e, option) => {
                                        setCityEditPostalAddressID(e); setCityEditPostalAddressName(option.children);
                                        setCityEditPostalAddressErrMsg("")
                                    }}>
                                    <Option value={""}>Select</Option>

                                    {
                                        cityArray.length > 0 && cityArray.map((value, key) => {
                                            return (
                                                <Option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</Option>
                                            )
                                        })
                                    }

                                </Select>
                                <span className='text-danger'>{cityEditPostalAddressNameErrMsg}</span>

                            </div>

                        </div>

                        {/* Postal Code INfromation */}
                        <div className="form-group row mb-0 m-top-20">
                            {/* <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Residence</label>
                                    <div className="input-group">
                                        <input autoComplete="off" id="mResidence" type="text" className="form-control" placeholder="Residence" />

                                    </div>
                                </div> */}
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Postal Code</label>
                                <div className="input-group">
                                    <Input autoComplete="off" id="mPostcode" type="text" className="form-control" placeholder="Postal Code" value={postCodeEditPostalAddress} onChange={(e) => {
                                        setPostCodeEditPostalAddress(e.target.value);
                                        setPostCodeEditPostalAddressErrMsg("")
                                    }} />

                                </div>
                                <span className='text-danger'>{postCodeEditPostalAddressErrMsg}</span>

                            </div>

                        </div>
                        {
                            isAddingEmergency && <div className='m-top-20'><ActivityIndicator size="small" /></div>
                        }


                    </div>


                </div>}

            </Modal>

            {/* End Edit Address Modal*/}

            {/* Edit Contact Modal start */}
            <Modal
                title="Edit Contact Details"
                centered
                open={editContactModalVisible}
                onOk={() => {
                    ContactUpdateHandler()
                    // saveApi()
                    // setAddContactVisible(false)
                }}
                onCancel={() => setContactModalVisible(false)}
                className="profile-screen-modal"
                width={1000}
            >
                <div className="row" >
                    {loading ?
                        <div className="w-100 my-2 text-center">

                            <Spin />
                        </div>
                        : null}
                    <div className="col-md-12 mx-auto">

                       
                        <div className="form-group row mb-0 m-top-20">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Cell Phone Number <span className='text-danger'> * </span> :</label>
                                <div className="input-group">
                                    {/* <input id="mHouse" type="text" className="form-control" placeholder="Cell Phone Number" /> */}

                                    <PhoneInput
                                        defaultCountry="NZ"
                                        value={cellPhone}
                                        onChange={(e) => {
                                            setCellPhoneInvalidState("")
                                            setCellUpdeated(true)

                                            if (e) {
                                                setCellPhone(e);
                                                if (e && isValidPhoneNumber(e)) {
                                                    setCellPhoneInvalidMsg("Valid")
                                                    setCellPhoneInvalidState("")
                                                } else {
                                                    setCellPhoneInvalidMsg("Invalid Number")
                                                    setCellPhoneInvalidState("Invalid Phone Number")
                                                }
                                            } else {

                                                setCellPhoneInvalidMsg("")
                                                setCellPhoneInvalidState("")
                                                setCellPhone("")
                                            }
                                        }
                                        }
                                        placeholder="Enter phone number"
                                        // international={false}
                                        className={"pl-2 phone-input w-100 " + (cellPhoneInvalidMsg === "" ? " empty-phone " : cellPhoneInvalidMsg === "Valid" ? " valid-phone " : " invalid-phone ")}
                                        onBlur={() => {
                                            if (cellPhoneInvalidMsg !== "Invalid Number") {
                                                setCellPhoneInvalidMsg('')
                                            }
                                        }}
                                    />

                                </div>
                                <p className="text-danger">{cellPhoneInvalidState}</p>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Work Phone Number:</label>
                                <div className="input-group">

                                    <PhoneInput
                                        defaultCountry="NZ"
                                        value={workphone}
                                        onChange={(e) => {
                                            setWorkPhoneInvalidState("")
                                            setWorkUpdateed(true)

                                            if (e) {
                                                setWorkPhone(e);
                                                if (e && isValidPhoneNumber(e)) {
                                                    setWorkPhoneInvalidMsg("Valid")
                                                    setWorkPhoneInvalidState("")
                                                } else {
                                                    setWorkPhoneInvalidMsg("Invalid Number")
                                                    setWorkPhoneInvalidState("Invalid Phone Number")
                                                }
                                            } else {

                                                setWorkPhoneInvalidMsg("")
                                                setWorkPhoneInvalidState("")
                                                setWorkPhone("")
                                            }
                                        }
                                        }
                                        placeholder="Enter phone number"
                                        // international={false}
                                        className={"pl-2 phone-input w-100 " + (workPhoneInvalidMsg === "" ? " empty-phone " : workPhoneInvalidMsg === "Valid" ? " valid-phone " : " invalid-phone ")}
                                        onBlur={() => {
                                            if (workPhoneInvalidMsg !== "Invalid Number") {
                                                setWorkPhoneInvalidMsg('')
                                            }
                                        }}
                                        />


                                    {/* <input id="mHouse" type="text" className="form-control" placeholder="Work phone number" /> */}

                                </div>
                                <p className="text-danger">{workPhoneInvalidState}</p>

                            </div>


                        </div>


                        {/* Cell Phone Number infomraiton */}
                        <div className="form-group row mb-0 m-top-20">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Home Phone Number:</label>
                                <div className="input-group">
                                    {/* <input id="mHouse" type="text" className="form-control" placeholder="Home Phone Number" />
                                         */}

                                    <PhoneInput
                                        defaultCountry="NZ"
                                        value={homephone}
                                        onChange={(e) => {
                                            setHomePhoneInvalidState("")
                                            setHomeUpdated(true)

                                            if (e) {
                                                setHomePhone(e);
                                                if (e && isValidPhoneNumber(e)) {
                                                    setHomePhoneInvalidMsg("Valid")
                                                    setHomePhoneInvalidState("")
                                                } else {
                                                    setHomePhoneInvalidMsg("Invalid Number")
                                                    setHomePhoneInvalidState("Invalid Phone Number")
                                                }
                                            } else {

                                                setHomePhoneInvalidMsg("")
                                                setHomePhoneInvalidState("")
                                                setHomePhone("")
                                            }
                                        }
                                        }
                                        placeholder="Enter phone number"
                                        // international={false}
                                        className={"pl-2 phone-input w-100 " + (homePhoneInvalidMsg === "" ? " empty-phone " : homePhoneInvalidMsg === "Valid" ? " valid-phone " : " invalid-phone ")}
                                        onBlur={() => {
                                            if (homePhoneInvalidMsg !== "Invalid Number") {
                                                setHomePhoneInvalidMsg('')
                                            }
                                        }}
                                        />

                                </div>
                                <p className="text-danger">{homePhoneInvalidState}</p>

                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Email:</label>
                                <div className="input-group">

                                    <input autoComplete="off" id="mEmail" value={email} type="text" className="form-control" placeholder="Email" />

                                </div>
                            </div>

                        </div>




                        {/* House infomraiton */}
                        <div className="form-group row mb-0 m-top-20">
                       
                            <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Contact Methods:</label>
                               <div className="row ml-2">     
                                    <div className="col-md-6 col d-flex flex-contents">
                                            <Checkbox checked={cmCellPhoneChecked} onClick={() => setCmCellPhoneChecked(!cmCellPhoneChecked)} >Cell Phone</Checkbox>
                                            <Checkbox checked={cmNightPhoneChecked} onClick={() => setCmNightPhoneChecked(!cmNightPhoneChecked)} >Night Phone</Checkbox>
                                            <Checkbox checked={cmTextChecked} onClick={() => setCmTextChecked(!cmTextChecked)} >Text</Checkbox>
                                            <Checkbox checked={cmDayPhoneChecked} onClick={() => setCmDayPhoneChecked(!cmDayPhoneChecked)} >Day Phone</Checkbox>
                                          
                                        </div>
                                       
                                 </div>   
                                 <div className="row ml-2">   
                                 <div className="col-md-6 col d-flex flex-contents">
                                             <Checkbox checked={cmEmailChecked} onClick={() => setCmEmailChecked(!cmEmailChecked)} >Email</Checkbox>
                                            <Checkbox checked={cmPostChecked} onClick={() => setCmPostChecked(!cmPostChecked)} >Post</Checkbox>
                                        </div> 
                                        </div> 
                            </div>
                            <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Preferred Contact:</label>
                                    <div className="input-group">
                                    <Select placeholder="Select a Preferred Contact" value={preferredCmID} onChange={(value, option) => {
                                        setPreferredCmID(value);
                                        setPreferredCmName(option.children)
                                    }}>
                                        <Option value={0}>Select</Option>
                                        {cmCellPhoneChecked && <Option value={1}>Cell Phone</Option>}
                                        {cmNightPhoneChecked && <Option value={2}>Night Phone</Option>}
                                        {cmTextChecked && <Option value={3}>Text</Option>}
                                        {cmDayPhoneChecked && <Option value={4}>Day Phone</Option>}
                                        {cmEmailChecked && <Option value={5}>Email</Option>}
                                        {cmPostChecked && <Option value={6}>Post</Option>}
                                        </Select>

                                    </div>
                          

                        </div>

                            </div>

                    </div>


                </div>

            </Modal>

            {/* End Edit Contact Modal*/}
            {/* Edit Profile Modal start */}
            <Modal
                title="Update Personal Details"
                centered
                open={editProfileModalVisible}
                onOk={() => {

                    updateProfile()
                    //   saveApi()
                    // setAddContactVisible(false)
                }}
                onCancel={() => setEditProfileModalVisible(false)}
                className="profile-screen-modal"
                width={1000}
            >
                <div className="row" >
                    {loading ?
                        <div className="w-100 my-2 text-center">
                            <Spin />
                        </div> : null}
                    <div className="col-md-12 mx-auto">

                        {/* Name Section */}
                        <div className="form-group row mb-0">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Title</label>

                                <Select
                                    disabled
                                    value={profileTitleID}
                                    onChange={(e, options) => {
                                        setProfileTitleID(e);
                                        setProfileTitle(options.children)
                                    }} >
                                    {
                                        titleArray.length > 0 && titleArray.map((value, key) => {
                                            return (
                                                <Option value={value?.resource?.id}>{value?.resource?.title}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                {/* <select id="mTitlte" className="form-control" name="country">
                                        {
                                            titleArray.length > 0 && titleArray.map((value, key) => {
                                                return (
                                                    <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                )
                                            })
                                        }

                                    </select> */}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">First Name</label>
                                <div className="input-group">

                                    {
                                        typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                            <Input autoComplete="off" disabled id="mFirstName" className="form-control" placeholder="First Name" value={profileFirstName} onChange={(e) => setProfileFirstName(e.target.value)} />
                                            // <input autoComplete="off" id="mFirstName" value={Profile[0].resource.name[0].given[0]} type="text" className="form-control" placeholder="First Name" />

                                        ) : null
                                    }
                                    {/*  */}

                                </div>
                            </div>
                        </div>

                        {/* Family Section */}
                        <div className="form-group row mb-0 m-top-20">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Family Name</label>
                                <div className="input-group">


                                    {
                                        typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                            <Input autoComplete="off" disabled value={profileFamilyName} onChange={(e) => setProfileFamilyName(e.target.value)} className="form-control" placeholder="Family Name" />
                                            // <input autoComplete="off" id="mFamilyName" value={Profile[0].resource.name[0].family} type="text" className="form-control" placeholder="Family Name" />


                                        ) : null
                                    }

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Preferred Name</label>
                                <div className="input-group">
                                    {
                                        typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                            <Input autoComplete="off" disabled value={profilePreferredName} onChange={(e) => setProfilePreferredName(e.target.value)} className="form-control" placeholder="Family Name" />
                                            // <input autoComplete="off" id="mPreferedName" value={Profile[0].resource.name[0].text} type="text" className="form-control" placeholder="Family Name" />


                                        ) : null
                                    }

                                </div>
                            </div>

                        </div>

                        {/* Gender  & Ethencity Section */}
                        <div className="form-group row mb-0 m-top-20">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Gender</label>
                                <Select disabled placeholder="Gender" value={profileGender} onChange={(e) => setProfileGender(e)} >
                                    <Option value="1">Female</Option>
                                    <Option value="11">Indeterminate</Option>
                                    <Option value="2">Male</Option>
                                    <Option value="14">Non-Binary</Option>
                                    <Option value="3">Other Gender</Option>
                                    <Option value="13">Unknown</Option>
                                    <Option value="4">Unspecified or Unknown</Option>
                                </Select>
                                {/* <select id="mGender" className="form-control" name="mGender">
                                        {Profile && Profile.length ?
                                            <option value={capitalize(Profile[0].resource.gender)}>{capitalize(Profile[0].resource.gender)}</option>
                                            : null
                                        }


                                    </select> */}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Ethnicity</label>
                                <div className="input-group">

                                    {
                                        typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                            <Input autoComplete="off" value={profileEthnicity} onChange={(e) => setProfileEthnicity(e.target.value)} placeholder="Ethnicity" disabled />
                                            // <input autoComplete="off" id="mEthnicity" value={Profile[0].resource.extension[1].valueString} type="text" className="form-control" placeholder="Ethnicity" />


                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>

                        {/* DOB  & Blood Group Section */}
                        <div className="form-group row mb-0 m-top-20">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">DOB</label>

                                {
                                    typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                        <DatePicker
                                            autoComplete="off"
                                            disabled
                                            className=''
                                            value={moment(profileDOB)} onChange={(date, dateString) => setProfileDOB(dateString)}
                                            format={"DD-MMM-YYYY"}
                                            placeholder="Date of Birth" />

                                        // <DatePicker format={"DD-MMM-YYYY"} value={profileDOB} placeholder="DOB" />


                                    ) : null
                                }

                                {/* <select id="mTitlte" className="form-control" name="country">
                                        {
                                            titleArray.length > 0 && titleArray.map((value, key) => {
                                                return (
                                                    <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                )
                                            })
                                        }

                                    </select> */}
                            </div>

                            <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Blood Group</label>
                                    <div className="input-group">
                                    <Select value={profileBloodGroupID} className='form-control' onChange={(e, option) => {
                                        setProfileBloodGroupID(e); setProfileBloodGroup(option.children);
                                    }}>
                                        <Option value="0">Select</Option>
                                        <Option value="3">A-</Option>
                                        <Option value="4">A+</Option>
                                        <Option value="7">AB-</Option>
                                        <Option value="8">AB+</Option>
                                        <Option value="5">B-</Option>
                                        <Option value="6">B+</Option>
                                        <Option value="1">O-</Option>
                                        <Option value="2">O+</Option>
                                    </Select>
                                    {/* <select id="mBloodGroup" className="form-control" name="BloodGroup">


                                            {
                                                typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                                    <option value={Profile[0].resource.extension[4].valueString ? Profile[0].resource.extension[4].valueString : "N/A"}>{Profile[0].resource.extension[5].valueString ? Profile[0].resource.extension[5].valueString : "N/A"}</option>
                                                ) : null
                                            }


                                        </select> */}
                                    </div>
                            </div>

                        </div>

                        {/* Email Section */}
                        <div className="form-group row mb-0 m-top-20">
                            <div className="col-md-6">
                                <label htmlFor="validate-internet-ip-field">Email</label>

                                <Input autoComplete="off" value={profileEmail} onChange={(e) => setProfileEmail(e.target.value)} placeholder="Email" disabled />
                                {/* {typeof Profile !== "undefined" && Profile.length && Profile[0].resource.hasOwnProperty('telecom') ? Profile[0].resource.telecom.map((item, index, arr) => {
                                    if (item.system === 'email') {
                                        return (
                                            <>
                                                {
                                                    <Input value={item.value} onChange={(e) => setProfileEmail(e.target.value)} placeholder="Email" disabled />
                                                    // <input id="mEmail" value={item.value} type="text" className="form-control" placeholder="Email" />
                                                }

                                            </>)


                                    }
                                }
                                    )
                                    //setEmail({ value: item.value, error: '' })
                                    // document.getElementById('mEmail').value = item.value;

                                    : null} */}
                                {/* <input id="mEmail" type="text" className="form-control" placeholder="Email" /> */}

                            </div>
                            <div className="col-md-6">
                                {/* <button className='btn btn-primary mt-4' onClick={() => setIsUpdateEmailModalOpen(!isUpdateEmailModalOpen)}>Change Email</button> */}
                            </div>
                        </div>





                    </div>


                </div>

            </Modal>
            {/* End Edit Profile Modal*/}

            <Modal
                title="Update Email Address"
                okText="Send Verification Code"
                open={isUpdateEmailModalOpen}
                onOk={() => { handleChangeEmailSubmit() }}
                onCancel={() => { setIsUpdateEmailModalOpen(false) }}
            >
                <Input autoComplete="off" value={profileEmail} placeholder="Current email" disabled />
                <p className=''></p>
                <Input autoComplete="off" value={changeEmail} onChange={(e) => { setChangeEmail(e.target.value); setChangeEmailErrMsg("") }} placeholder="Enter new email" />
                <p className='text-danger'>{changeEmailErrMsg}</p>
                <Input autoComplete="off" value={confirmChangeEmail} onChange={(e) => { setConfirmChangeEmail(e.target.value); setConfirmChangeEmailErrMsg("") }} placeholder="Confirm  email" />
                <p className='text-danger'>{confirmChangeEmailErrMsg}</p>
            </Modal>
            <Modal
                title="Delete Emergency Contact"
                open={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <span>{"Are you sure you want to delete this emergency contact?"}</span>
            </Modal>
            <Modal
                open={trustedDevicesListModalOpen}
                onCancel={() => setTrustedDevicesListModalOpen(false)}
                title="Trusted Devices"
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}

            >
                <div className='trusted-devices-box'>
                    {
                        trustedDevicesList && trustedDevicesList.length > 0 ? trustedDevicesList.map(device => <div className='trusted-device-item'>
                            <div className='device-content'>

                                <p><span className='device-title'>Device Name:</span> <span className='device-value'>{device?.DeviceName}</span></p>
                                <p><span className='device-title'>Last Used:</span> <span className='device-value'>{moment(device?.lastUsedAt).format("DD-MM-YYYY hh:mm a")}</span></p>
                                <div className='device-options'>

                                    <Button className='remove-trusted-device-btn' onClick={() => {
                                        setTrustedDeviceRemoveModalOpen(true)
                                        setRemoveDeviceID(device.DeviceID)
                                    }} type='primary'>Remove Device</Button>
                                </div>
                            </div>
                            {/* <img className='delete-btn' src='assets/images/Delete.svg' /> */}
                        </div>) :
                            <div className='alert alert-danger p-2 text-center'>No Records Found</div>

                    }
                </div>
            </Modal>
            <Modal
                open={trustedDeviceRemoveModalOpen}
                onCancel={() => setTrustedDeviceRemoveModalOpen(false)}
                onOk={() => {
                    manageTrustedDevicesList(1)
                }}
                confirmLoading={isRemovingTrustedDevice}
                afterClose={() => setRemoveDeviceID("")}
                title="Remove Device"
                okText="Yes"
                cancelText="No"
                maskClosable={false}
                cancelButtonProps={isRemovingTrustedDevice ? { style: { pointerEvents: "none" } } : {}}
            >
                <p>Are you sure you want to remove this device?</p>
            </Modal>
        </div>

    )
}
function mapStateToProps(state) {
    return {
        Profile: state.Profile,
        Practise: state.Practise,

        Balance: state.Balance,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // getProfile:
        getProfile: () => dispatch(fetchProfileFromApi()),
        getPracticeCenterDetail: () => dispatch(fetchPractisefromAPI()),
        getBalance: () => dispatch(fetchBalanceFromApi()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);